import { DashboardTypes } from "../types";

const initialState = {
  information: {
    daily_check: {}
  },
  redirect: false,
  error: null
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case DashboardTypes.DASHBOARD_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case DashboardTypes.DASHBOARD_GET_SUCCESS:
      return {
        ...state,
        information: action.payload.information,
        redirect: true,
        error: null
      };

    case DashboardTypes.DASHBOARD_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        information: {}
      };
    default:
      return state;
  }
}
