import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const Table = props => {
	return (
		<div className='p-col-8 p-col-align-center tables'>
			<DataTable
				value={props.logs}
				paginator={true}
				rows={10}
				rowsPerPageOptions={[5, 10, 20]}>
				<Column field='created_at' header='Date' />
				<Column field='action' header='Action' />
				<Column field='auditable_type' header='Model' />
				<Column field='user_id' header='User id' />
			</DataTable>
		</div>
	);
};

export default Table;
