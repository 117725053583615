import { AuthTypes } from "../types";

const initialState = {
  user: null,
  token: null,
  redirect: false,
  need_change_password: null,
  error: null
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AuthTypes.AUTH_LOGIN_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case AuthTypes.AUTH_LOGIN_SUCCESS:
      let user = action.payload.credentials[0];
        return {
          ...state,
          error: null,
          user: user,
          token: action.payload.credentials[1],
          need_change_password: action.payload.credentials[2],
          redirect: true
        };
    case AuthTypes.AUTH_LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        user: null,
        token: null,
        need_change_password: null,
        redirect: false
      };
    case "FAILURE":
      if (action.payload.error) {
        if (action.payload.error.status === 406) {
          return {
            ...state,
            error: "Su sesión ha expirado, vuelva a iniciar sesión",
            user: null,
            token: null,
            need_change_password: null,
            redirect: false
          };
        }
      };
      return {
        ...state,
        error: "Sucedio un error Inesperado",
        user: null,
        token: null,
        redirect: false
      };
    // -------------------------------------------------------------------------

    case AuthTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case AuthTypes.CHANGE_PASSWORD_SUCCESS:
      // let user = action.payload.credentials[0];
      return {
        ...state,
        error: null,
        // user: user,
        // token: action.payload.credentials[1],
        redirect: true,
        need_change_password: false
      };

    case AuthTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    //--------------------------------------------------------------------------

    case AuthTypes.AUTH_CLEAN:
      return {
        ...state,
        error: null,
        redirect: false,
        user: null,
        token: null,
        need_change_password: null,
      };

    default:
      return state;
  }
}
