import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from './redux/actions/AuthActions';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './styles/main.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import classNames from 'classnames';
import AppTopbar from './views/home/components/AppTopbar';
import { AppFooter } from './views/home/components/AppFooter';
import { AppMenu } from './views/home/components/AppMenu';
// import { AppProfile } from './views/home/components/AppProfile';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './styles/home/layout.scss';
import Home from './views/home/Home';
import Login from './views/login/Login';
import RecoveryPassword from './views/account/RecoveryPassword';
import EmailPassword from './views/login/EmailPassword';
import UnlockAccount from './views/login/UnlockAccount';
import PasswordExpired from './views/login/PasswordExpired';
import LogIndex from './views/logs/Index';
import DailyReportIndex from './views/dailyReport/containers/Index';
import DailyReportNew from './views/dailyReport/Parte/container/New';
import { menuItems } from './config/sidebar/config';
import Logo from './config/sidebar/logo.svg';
import RoomIndex from './views/room/container';
import EmployeIndex from './views/employe/container';
import SiatIndex from './views/siat/containers';
import UnpleasantIndex from './views/unpleasant';
import Siat5 from './views/siat5';
import Siat5New from './views/siat5New';
import EstablishmentEmail from './views/establishment_email';
import EstablishmentContact from './views/establishment_contact';
import CommunicationIndex from './views/communication/containers';
import * as DailyReportActions from './../src/redux/actions/DailyReportActions';
import IdleTimer from 'react-idle-timer';

class App extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      layoutMode: 'static',
      layoutColorMode: 'light',
      staticMenuInactive: true,
      overlayMenuActive: false,
      mobileMenuActive: false,
      visible: false,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.idleTimer = null;
    this.onIdle = this._onIdle.bind(this);
  }

  _onIdle(e) {
    console.log('user is idle', e);
    console.log('last active', this.idleTimer.getLastActiveTime());
    this.logout();
    window.location = '/login';
  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === 'overlay') {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === 'static') {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  async onMenuItemClick(event) {
    if (!event.item.items) {
      await this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    if (event.item.to != undefined) {
      await this.setState({
        loading: true,
      });
      await this.setState({
        loading: false,
      });
    }
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
          'gi'
        ),
        ' '
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  }

  logout = async() => {
    await this.props.AuthActions.cleanAuth();
  };
  async componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  render() {
    const wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive':
        this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active':
        this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive,
    });

    const sidebarClassName = classNames('layout-sidebar', {
      'layout-sidebar-dark': this.state.layoutColorMode === 'dark',
      'layout-sidebar-light': this.state.layoutColorMode === 'light',
    });

    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onIdle={this.onIdle}
          timeout={1000 * 60 * 60}
        />
        <Router>
          {this.props.user ?
            (this.props.need_change_password ?
              (
                <div>
                  <div className="layout-main">
                    {this.state.loading === true ? (
                      <div className="p-grid p-fluid p-justify-center">
                        <ProgressSpinner
                          style={{ width: '20%', height: '20%', marginTop: '10%' }}
                          animationDuration=".5s"
                        />
                      </div>
                    ) : (
                      <div>
                        <Switch>
                          <Route exact path="*" component={PasswordExpired} />
                        </Switch>
                      </div>
                    )}
                  </div>

                  <div className="layout-mask"></div>
                </div>
              ) :
              (
                <div className={wrapperClass} onClick={this.onWrapperClick}>
                  <AppTopbar onToggleMenu={this.onToggleMenu} logout={this.logout} />

                  <div
                    ref={(el) => (this.sidebar = el)}
                    className={sidebarClassName}
                    onClick={this.onSidebarClick}
                  >
                    <div className="layout-logo">
                      <img
                        alt="Logo"
                        src={Logo}
                        style={{ width: '100%', height: '25vh' }}
                      />
                    </div>
                    <AppMenu
                      model={menuItems}
                      onMenuItemClick={this.onMenuItemClick}
                      // dailyReports={this.props.dailyReports}
                      nextDateDailyReport={this.props.next_date_daily_report}
                    />
                  </div>

                  <div className="layout-main">
                    {this.state.loading === true ? (
                      <div className="p-grid p-fluid p-justify-center">
                        <ProgressSpinner
                          style={{ width: '20%', height: '20%', marginTop: '10%' }}
                          animationDuration=".5s"
                        />
                      </div>
                    ) : (
                      <div>
                        <Switch>
                          <Route exact path="/RecoveryPassword" component={RecoveryPassword} />
                          <Route exact path="/" component={Home} />
                          <Route exact path="/login" component={Login} />
                          <Route exact path="/partes" component={DailyReportIndex} />
                          <Route exact path="/rooms" component={RoomIndex} />
                          <Route exact path="/employes" component={EmployeIndex}/>
                          <Route exact path="/siats" component={SiatIndex} />
                          <Route
                            exact
                            path="/unpleasant"
                            component={UnpleasantIndex}
                          />
                          <Route
                            exact
                            path="/partes/new"
                            component={DailyReportNew}
                          />
                          <Route exact path="/siat5" component={Siat5} />
                          <Route exact path="/siat5/new" component={Siat5New} />
                          <Route exact path="/siat5/:id/edit" component={Siat5New} />

                          <Route
                            exact
                            path="/partes/:id/edit"
                            component={DailyReportNew}
                          />
                          <Route
                            exact
                            path="/communications"
                            component={CommunicationIndex}
                          />
                          <Route
                            exact
                            path="/establishment_emails"
                            component={EstablishmentEmail}
                          />

                          <Route
                            exact
                            path="/establishment_contacts"
                            component={EstablishmentContact}
                          />

                          <Route exact path="/logs" component={LogIndex} />
                        </Switch>
                      </div>
                    )}
                  </div>

                  {/* <AppFooter /> */}
                  <div className="layout-mask"></div>
                </div>
              )
            )
             : (
            <div>
              <div className="layout-main">
                {this.props.loading === true ? (
                  <div className="p-grid p-fluid p-justify-center">
                    <ProgressSpinner
                      style={{ width: '20%', height: '20%', marginTop: '10%' }}
                      animationDuration=".5s"
                    />
                  </div>
                ) : (
                  <div>
                    <Switch>
                      <Route exact path="/" component={Login} />
                      <Route exact path="/login" component={Login} />
                      <Route exact path="/ForgotPassword" component={EmailPassword} />
                      <Route exact path="/RecoveryPassword" component={RecoveryPassword} />
                      <Route exact path="/UnlockAccount" component={UnlockAccount} />
                      <Route exact path="*" component={Login} />
                    </Switch>
                  </div>
                )}
              </div>

              <div className="layout-mask"></div>
            </div>
          )}
          <AppFooter />
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  need_change_password: state.AuthReducer.need_change_password,
  user: state.AuthReducer.user,
  token: state.AuthReducer.token,
  // loading: state.AuthReducer.loading,
  // dailyReports: state.DailyReportReducer.dailyReports,
  next_date_daily_report: state.DailyReportReducer.next_date_daily_report,
});

const mapDispatchToProps = (dispatch) => ({
  AuthActions: bindActionCreators(AuthActions, dispatch),
  DailyReportActions: bindActionCreators(DailyReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
