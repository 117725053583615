import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {InputTextarea} from 'primereact/inputtextarea';
import {Fieldset} from 'primereact/fieldset';
import {ScrollPanel} from 'primereact/scrollpanel';


const inputStyle = { margin: '10px 0px' };
const labelStyle = {
  fontSize: "12px",
  color: "#898989",
};

const CommunicationShow = props => {
  const footer = (
    <div>
      <Button
        className="p-button-danger"
        label="Cerrar"
        icon="pi pi-times"
        onClick={props.close}
      />
    </div>
  );
  return (
    <Dialog
      header="Comunicado"
      footer={footer}
      visible={props.visible}
      style={{ width: '70vw' }}
      modal={true}
      onHide={props.close}
    >
      <div className="p-grid p-fluid">

        <div className="p-col-12 p-md-12 p-opacity" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.communication.title}
            />
            <label htmlFor="in">Título</label>
          </span>
        </div>
        <div className="p-col-12 p-md-12" style={inputStyle}>
            <label style={labelStyle}>Contenido</label>
            <div className="p-inputtext p-component p-opacity p-filled">
              <ScrollPanel style={{width: '100%', height: '200px'}} className="custombar2">
              {
                props.communication.content.indexOf('</') !== -1?
                  (
                    <div dangerouslySetInnerHTML={{__html: props.communication.content.replace(/(<? *script)/gi, 'illegalscript')}} >
                    </div>
                  )
                :
                  ""
              }
              </ScrollPanel>
            </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CommunicationShow;
