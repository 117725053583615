import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { booking_types, room_types } from "../../config/models";
import { Button } from "primereact/button";
import { GetFormattedDate } from '../helper_java/date_format'

const Table = props => {
  const { state } = props.form;

  let button_edit =(rowData)=> {
    if(rowData != 1)return(
        <Button
        label="Editar"
        icon="pi pi-pencil"
        className="p-button-info"
        menuStyle={{ width: '100px'}}
        style={{textAlign:'left'}}
        onClick={e => {props.edit(rowData.guest_record.id);}}
      />
    );
  }
  const actionTemplate = (rowData, column) => {
    return (
      <div
      style={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <Button
        onClick={e => {props.show(rowData.guest_record.id);}}
        type="button"
        icon="pi pi-search"
        className="p-button-success"
        style={{marginRight: ".5em"}}
      ></Button>
      {
        state != 1?
          button_edit(rowData)
          :
          ""
      }
      </div>
    );
  };

  const column_acctions = (actionTemplate) =>{
    return (
      <Column
        header="Acciones"
        body={actionTemplate}
        style={{textAlign:'center', width: '9%', paddingRight: '0', paddingLeft: '0'}}
      />
    )
  }

  const renderHeader = (data)=> {
    if (data != undefined){
      return (
        <div>
          Registros
          <div className="p-datatable-globalfilter-container">
            Total registros: {data.length}
          </div>
        </div>
      );
    }
  }

  return (
    <DataTable
      header= {renderHeader(props.records)}
      style={{ margin: "10px 0px" }}
      value={props.records}

      responsive={true}
      paginator={true}
      rows={10}
      rowsPerPageOptions={[5, 10, 20]}
    >
      <Column
        header="Nro."
        body={(rowData, value) => {
          return value.rowIndex+1
        }}
        style={{textAlign:'center', width: '4%', paddingRight: '0', paddingLeft: '0'}}
      />
      <Column
        body={rowData => {
          return typeof rowData.arrival.getMonth === "function"
            ? GetFormattedDate(rowData.arrival.toISOString().substring(0, 10))
            : rowData.arrival;
        }}
        header="Fecha de ingreso"
        style={{textAlign:'center', width: '9%', paddingRight: '0', paddingLeft: '0'}}
      />
      <Column
        body={rowData => {
          return typeof rowData.departure.getMonth === "function"
            ? GetFormattedDate(rowData.departure.toISOString().substring(0, 10))
            : rowData.departure;
        }}
        header="Fecha de salida"
        style={{textAlign:'center', width: '9%', paddingRight: '0', paddingLeft: '0'}}
      />

      <Column field="days"
        header="Días de pernocte"
        style={{textAlign:'center', width:'100px'}}
      />

      <Column
        body={rowData => {
          return rowData.person.name + " " + rowData.person.last_name  + " " + rowData.person.middle_name;
        }}
        header="Nombre Completo"
        style={{width:'200px'}}
      />

      <Column
        body={rowData => {
          return rowData.document;
        }}
        header="Número de documento de identidad"
        style={{width:'200px'}}
      />

      {/* <Column
        body={rowData => {
          return booking_types.filter(
            booking => booking.value === rowData.booking_type
          )[0].label;
        }}
        header="Medio de reserva"
        style={{textAlign:'center', width: '20%', paddingRight: '0', paddingLeft: '0'}}
      /> */}
      {/* <Column
        body={rowData => {
          return room_types.filter(room => room.value === rowData.room_type)[0]
            .label;
        }}
        header="Tipo de habitación"
        style={{textAlign:'center', width: '10%', paddingRight: '0', paddingLeft: '0'}}
      /> */}
      <Column
        field="nationality_id"
        body={rowData => {
          return rowData.nationality.name;
        }}
        header="Nacionalidad"
        style={{textAlign:'center', width: '9%', paddingRight: '0', paddingLeft: '0'}}
      />

      <Column
        body={rowData => {
        if (rowData.guest_record.state_siat){
          return "Completo";
        }
        else{
          return "Pendiente";
        }
        }}
        header="Registro"
        style={{width:'200px'}}
      />

      {/* <Column field="days"
        header="Días de pernocte"
        style={{textAlign:'center', width: '9%', paddingRight: '0', paddingLeft: '0'}}
      /> */}

      {column_acctions(actionTemplate)}
    </DataTable>
  );
};

export default Table;
