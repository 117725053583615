import { SIAT4Types } from "../types";

const initialState = {
  siats4: [],
  siat: null,
  records: [],
  delete_list: [],
  redirect: false,
  error: null
};

const parseStringToDate = stringDate => {
  if (stringDate == null) return null;
  let from = stringDate.split("-");
  return new Date(from[0], from[1] - 1, from[2]);
};

export default function siat4Reducer(state = initialState, action) {
  switch (action.type) {
    case SIAT4Types.SIAT4_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT4Types.SIAT4_GET_SUCCESS:
      return {
        ...state,
        siats4: action.payload.siat4s,
        redirect: true,
        error: null
      };

    case SIAT4Types.SIAT4_GETBYID_SUCCESS:
      let siat_four_records = action.payload.siat.siat_four_records;
      for (let i = 0; i < siat_four_records.length; i++) {
        siat_four_records[i].local_id = i + 1;
      }
      return {
        ...state,
        siat: action.payload.siat,
        delete_list: [],
        records: siat_four_records,
        redirect: true,
        error: null
      };

    case SIAT4Types.SIAT4_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        siats4: []
      };

    case SIAT4Types.SIAT4_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT4Types.SIAT4_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        delete_list: [],
        records: [],
        siats4: state.siats4.concat(action.payload.siat4)
      };

    case SIAT4Types.SIAT4_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case SIAT4Types.SIAT4_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT4Types.SIAT4_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true,
        delete_list: [],
        siats4: state.siats4.filter(
          siat4 => siat4.id !== action.payload.SIAT4_id
        )
      };

    case SIAT4Types.SIAT4_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case SIAT4Types.SIAT4_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT4Types.SIAT4_EDIT_SUCCESS:
      let updated_siat4 = action.payload.siat4;
      return {
        ...state,
        redirect: true,
        delete_list: [],
        siats4: state.siats4.map(siat4 => {
          if (siat4.id === updated_siat4.id) {
            return updated_siat4;
          } else {
            return siat4;
          }
        })
      };

    case SIAT4Types.SIAT4_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    //local

    case SIAT4Types.SIAT4_SETINITIAL_SUCCESS:
      return {
        siats4: [],
        siat: null,
        records: [],
        redirect: false,
        error: null
      };

    case SIAT4Types.SIAT4_LOCALCREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        // records: state.records.concat(action.payload.guest_record)
        records: [].concat(action.payload.guest_record, state.records)
      };

    case SIAT4Types.SIAT4_LOCALEDIT_SUCCESS:
      let local_updated_siat4 = action.payload.guest_record;
      return {
        ...state,
        redirect: true,
        records: state.records.map(siat4 => {
          if (siat4.local_id === local_updated_siat4.local_id) {
            return local_updated_siat4;
          } else {
            return siat4;
          }
        })
      };

    case SIAT4Types.SIAT4_LOCALDELETE_SUCCESS:
      let new_delete_list = []
      let delete_item = { id: action.payload.record.id, _destroy: true };

      if (action.payload.record.id != undefined){
        if (state.records.length ==1){
          return {
            ...state,
            error: "No se puede eliminar el último registro del SIAT IV, este no puede estar vacío"
          };
        }else{
          new_delete_list = state.delete_list.concat(delete_item)
        }
      }

      return {
        ...state,
        redirect: true,
        delete_list: new_delete_list,
        records: state.records.filter(
          siat4 => siat4.local_id !== action.payload.record.local_id
        ),
        error: null
      };

    case SIAT4Types.SIAT4_EDIT_RECORD_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };
    case SIAT4Types.SIAT4_EDIT_RECORD_SUCCESS:
      return {
        ...state
      };
    case SIAT4Types.SIAT4_EDIT_RECORD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
