import { NationalityTypes } from "../types";
import { NationalityEndpoints, MainURL } from "../endpoints";
import axios from "axios";

const nationalityGetBegin = () => ({
  type: NationalityTypes.NATIONALITY_GET_REQUEST
});

const nationalityGetSuccess = nationalities => ({
  type: NationalityTypes.NATIONALITY_GET_SUCCESS,
  payload: { nationalities }
});

const nationalityGetFailure = error => ({
  type: NationalityTypes.NATIONALITY_GET_FAILURE,
  payload: { error }
});

export const getNationalities = token => {
  return dispatch => {
    dispatch(nationalityGetBegin());
    return axios
      .get(MainURL + NationalityEndpoints.NATIONALITY_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(nationalityGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(nationalityGetFailure(error.response));
      });
  };
};

const nationalityCreateBegin = () => ({
  type: NationalityTypes.NATIONALITY_CREATE_REQUEST
});

const nationalityCreateSuccess = nationalities => ({
  type: NationalityTypes.NATIONALITY_CREATE_SUCCESS,
  payload: { nationalities }
});

const nationalityCreateFailure = error => ({
  type: NationalityTypes.NATIONALITY_CREATE_FAILURE,
  payload: { error }
});

export const createNationality = (token, nationality) => {
  return dispatch => {
    dispatch(nationalityCreateBegin());
    return axios
      .post(MainURL + NationalityEndpoints.NATIONALITY_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        },
        data: {
          nationality: nationality
        }
      })
      .then(response => {
        dispatch(nationalityCreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(nationalityCreateFailure(error.response));
      });
  };
};

const nationalityDeleteBegin = () => ({
  type: NationalityTypes.NATIONALITY_DELETE_REQUEST
});

const nationalityDeleteSuccess = nationality => ({
  type: NationalityTypes.NATIONALITY_DELETE_SUCCESS,
  payload: { nationality }
});

const nationalityDeleteFailure = error => ({
  type: NationalityTypes.NATIONALITY_DELETE_FAILURE,
  payload: { error }
});

export const deleteNationality = (token, nationality_id) => {
  return dispatch => {
    dispatch(nationalityDeleteBegin());
    return axios
      .delete(
        MainURL +
          NationalityEndpoints.NATIONALITY_ENDPOINT +
          `/${nationality_id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(nationalityDeleteSuccess(response.data));
      })
      .catch(error => {
        dispatch(nationalityDeleteFailure(error.response));
      });
  };
};

const nationalityEditBegin = () => ({
  type: NationalityTypes.NATIONALITY_EDIT_REQUEST
});

const nationalityEditSuccess = nationality => ({
  type: NationalityTypes.NATIONALITY_EDIT_SUCCESS,
  payload: { nationality }
});

const nationalityEditFailure = error => ({
  type: NationalityTypes.NATIONALITY_EDIT_FAILURE,
  payload: { error }
});

export const editNationality = (token, nationality) => {
  return dispatch => {
    dispatch(nationalityEditBegin());
    return axios
      .put(
        MainURL +
          NationalityEndpoints.NATIONALITY_ENDPOINT +
          `/${nationality.id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          },
          data: {
            nationality: nationality
          }
        }
      )
      .then(response => {
        dispatch(nationalityEditSuccess(response.data));
      })
      .catch(error => {
        dispatch(nationalityEditFailure(error.response));
      });
  };
};
