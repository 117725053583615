import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { SplitButton } from 'primereact/splitbutton';
import { MainURL } from '../../redux/endpoints/index';
import { GetFormattedDate } from '../helper_java/date_format';
import ModalImage from 'react-modal-image';
import { GetFullNameValidated } from '../helper_java/person_helper'
import './unpleasant.scss';


export class UnpleasantTable extends Component {
  constructor() {
    super();
    this.state = {
      globalFilter: null,
      globalFilterUE: null,
    };
  }

  header() {
    return (
      <div>
        Huéspedes no gratos
        <div className="p-datatable-globalfilter-container">
          <InputText
            maxLength={50}
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Búsqueda general"
          />
        </div>
      </div>
    );
  }

  renderHeader() {
    return (
      <div>
        Huéspedes no gratos de otros Establecimientos
        <div className="p-datatable-globalfilter-container">
          <InputText
            maxLength={50}
            type="search"
            onInput={(e) => this.setState({ globalFilterUE: e.target.value })}
            placeholder="Búsqueda general"
          />
        </div>
      </div>
    );
  }

  footer() {
    return (
      <div className="p-clearfix" style={{ width: '100%' }}>
        <Button
          style={{ float: 'left' }}
          label="Agregar huésped no grato"
          icon="pi pi-plus"
          onClick={this.props.addNew}
        />
      </div>
    );
  }

  actionTemplate() {
    return (rowData) => {
      let items = [
        {
          label: 'Eliminar',
          icon: 'pi pi-times',
          command: (e) => {
            this.props.delete(rowData.id);
          },
        },
      ];
      return (
        <SplitButton
          label="Editar"
          icon="pi pi-pencil"
          className="p-button-info"
          menuStyle={{ width: '100px'}}
          style={{textAlign:'left'}}
          model={items}
          onClick={(e) => {
            this.props.edit(rowData);
          }}
        />
      );
    };
  }

  render() {
    const headerUE = this.renderHeader();
    const header = this.header();

    return (
      <div>
        <TabView>
          <TabPanel header="Propios">
            <div style={{ margin: '10px' }}>{this.props.children}</div>
            <DataTable
              ref={(el) => (this.dt = el)}
              header={header}
              responsive
              className="p-datatable-customers"
              rowHover
              globalFilter={this.state.globalFilter}
              footer={this.footer()}
              value={this.props.unpleasants}
              paginator={true}
              rows={10}
              rowsPerPageOptions={[5, 10, 20]}
              emptyMessage="No tiene huéspedes no gratos registrados"
            >
              <Column
                field={(rowData) => {
                  const person = rowData.guest_record.person;
                  return (GetFullNameValidated(person.name, person.last_name, person.middle_name));
                }}
                filterMaxLength={50}
                header="Huésped"
                filter={true}
                filterPlaceholder="Buscar por Nombre"
                filterMatchMode="contains"
              />
              <Column
                field={(rowData) => {
                  return GetFormattedDate(rowData.daily_report.date);
                }}
                filterMaxLength={20}
                header="Parte"
                filter={true}
                filterPlaceholder="Buscar por Parte"
                filterMatchMode="contains"
                style={{textAlign:'center', width: '12.3%'}}
              />
              <Column
                field={(rowData) => {
                  return GetFormattedDate(rowData.created_at);
                }}
                filterMaxLength={20}
                header="Creado en"
                filter={true}
                filterPlaceholder="Buscar por Fecha"
                filterMatchMode="contains"
                style={{textAlign:'center', width: '12.6%'}}
              />
              <Column
                filterMaxLength={50}
                field="description"
                header="Descripción"
                filter={true}
                filterPlaceholder="Buscar por Descripción"
                filterMatchMode="contains"
              />
              <Column
                field={(rowData) => {
                  return (
                    <div className="p-grid p-fluid p-justify-center">
                      {/* <img
                        style={{ height: '50px' }}
                        src={rowData.image_url != null ? MainURL + rowData.image_url.url : ''}
                        alt="Sin imagen"
                      /> */}
                      <ModalImage
                        className="p-datatable-img-preview"
                        small={rowData.image_url != null ? MainURL + rowData.image_url : ''}
                        large={rowData.image_url != null ? MainURL + rowData.image_url : ''}
                        hideDownload={true}
                      />
                    </div>
                  );
                }}
                header="Imagen"
                style={{textAlign:'center', width: '12%'}}
              />
              <Column
                header="Acciones"
                body={this.actionTemplate()}
                style={{textAlign:'center', width: '11%', paddingRight: '0', paddingLeft: '0'}}
              />
            </DataTable>
          </TabPanel>

          <TabPanel header="Otros Establecimientos">
            <div style={{ margin: '10px' }}>{this.props.children}</div>
            <DataTable
              ref={(el) => (this.dt1 = el)}
              header={headerUE}
              responsive
              className="p-datatable-customers"
              rowHover
              globalFilter={this.state.globalFilterUE}
              value={this.props.unpleasant_establishments}
              paginator={true}
              rows={10}
              rowsPerPageOptions={[5, 10, 20]}
              emptyMessage="No existen huéspedes no gratos registrados por otros Establecimientos"
            >
              <Column
                field={(rowData) => {
                  const person = rowData.guest_record.person;
                  return (GetFullNameValidated(person.name, person.last_name, person.middle_name));
                }}
                filterMaxLength={50}
                header="Huésped"
                filter={true}
                filterPlaceholder="Buscar por nombre"
                filterMatchMode="contains"
              />
              <Column
                field={(rowData) => {
                  return rowData.daily_report.establishment_name;
                }}
                header="Establecimiento"
                filterMaxLength={50}
                filter={true}
                filterPlaceholder="Buscar por Establecimiento"
                filterMatchMode="contains"
              />
              <Column
                field={(rowData) => {
                  return GetFormattedDate(rowData.daily_report.date);
                }}
                header="Parte"
                filterMaxLength={20}
                filter={true}
                filterPlaceholder="Buscar por Parte"
                filterMatchMode="contains"
                style={{textAlign:'center', width: '12.3%'}}
              />
              <Column
                field={(rowData) => {
                  return GetFormattedDate(rowData.created_at);
                }}
                header="Creado en"
                filterMaxLength={20}
                filter={true}
                filterPlaceholder="Buscar por Fecha"
                filterMatchMode="contains"
                style={{textAlign:'center', width: '12.6%'}}
              />
              <Column
                filterMaxLength={50}
                field="description"
                header="Descripción"
                filter={true}
                filterPlaceholder="Buscar por Descripción"
                filterMatchMode="contains"
              />
              <Column
                body={(rowData) => {
                  return (
                    <div className="p-grid p-fluid p-justify-center">
                      {/* <img
                        style={{ height: '50px' }}
                        src={rowData.image_url != null ? MainURL + rowData.image_url.url : ''}
                        alt="Sin imagen"
                      /> */}
                      <ModalImage
                        className="p-datatable-img-preview"
                        small={rowData.image_url != null ? MainURL + rowData.image_url : ''}
                        large={rowData.image_url != null ? MainURL + rowData.image_url : ''}
                        hideDownload={true}
                      />
                    </div>
                  );
                }}
                header="Imagen"
                style={{textAlign:'center', width: '12%'}}
              />
            </DataTable>
          </TabPanel>
        </TabView>
      </div>
    );
  }
}
