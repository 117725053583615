import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './EstablishmentInfo.css';

const EstablishmentInfo = props => {
  return (
    <ul className="establishmentInfo" style={{ padding: 0, listStyle: 'none' }}>
      <li>
        <strong>Establecimiento: </strong>
        {props.information ? props.information.name : ''}
      </li>
      <li>
        <strong>Dirección: </strong>
        {props.information ? props.information.address : ''}
      </li>
      <li>
        <strong>E-mail: </strong>
        {props.information ? props.information.email : ''}
      </li>
      {/* <li>
        <strong>Estado: </strong>
        {props.information ? props.information.status : ""}
      </li> */}
    </ul>
  );
};

export default EstablishmentInfo;
