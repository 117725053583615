import React from "react";
import { Button } from "primereact/button";

function ParteFooter(props) {
  // if (props.guest_records.length > 0) {
    return (
      <div className="p-grid" style={{ marginTop: "15px" }}>
        <div className="p-col-12 p-md-6 p-lg-6">
          {props.state !=2 ?
            <Button
              style={{ marginRight: "20px" }}
              label={props.id > 0 ? "Actualizar" : "Guardar"}
              icon="pi pi-check"
              onClick={props.handleSubmit}
            />
            :
            false
          }
          <Button
            className="p-button-danger"
            label="Cancelar"
            icon="pi pi-times"
            onClick={props.cancel}
          />
        </div>
      </div>
    );
  // } else {
  //   return "";
  // }
}

export default ParteFooter;
