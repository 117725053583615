import { SIAT5Types } from "../types";
import { Siat5Endpoints, MainURL } from "../endpoints";
import axios from "axios";

const siat5GetBegin = () => ({
  type: SIAT5Types.SIAT5_GET_REQUEST
});

const siat5GetSuccess = siat5s => ({
  type: SIAT5Types.SIAT5_GET_SUCCESS,
  payload: { siat5s }
});

const siat5GetFailure = error => ({
  type: SIAT5Types.SIAT5_GET_FAILURE,
  payload: { error }
});

export const getsiats = token => {
  return dispatch => {
    dispatch(siat5GetBegin());
    return axios
      .get(MainURL + Siat5Endpoints.SIAT5_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat5GetSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat5GetFailure(error.response));
      });
  };
};

const siat5GetByIdBegin = () => ({
  type: SIAT5Types.SIAT5_GETBYID_REQUEST
});

const siat5GetByIdSuccess = siat => ({
  type: SIAT5Types.SIAT5_GETBYID_SUCCESS,
  payload: { siat }
});

const siat5GetByIdFailure = error => ({
  type: SIAT5Types.SIAT5_GETBYID_FAILURE,
  payload: { error }
});
export const getsiatById = (token, siat_id) => {
  return dispatch => {
    dispatch(siat5GetByIdBegin());
    return axios
      .get(`${MainURL}${Siat5Endpoints.GET_SIATS_RECORDS}/${siat_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat5GetByIdSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat5GetByIdFailure(error.response));
      });
  };
};

const siat5ModalShowBegin = () => ({
  type: SIAT5Types.SIAT5_MODAL_SHOW_REQUEST
});

const siat5ModalShowSuccess = siat5 => ({
  type: SIAT5Types.SIAT5_MODAL_SHOW_SUCCESS,
  payload: { siat5, success: "SIAT II actualizado exitosamente" }
});

const siat5ModalShowFailure = error => ({
  type: SIAT5Types.SIAT5_MODAL_SHOW_FAILURE,
  payload: { error }
});

export const siat5Modalshow = (token, siats_record_id) => {
  return dispatch => {
    dispatch(siat5ModalShowBegin());
    return axios
      .get(MainURL + Siat5Endpoints.SIATS_MODAL_SHOW + `/${siats_record_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat5ModalShowSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat5ModalShowFailure(error.response));
      });
  };
};

export const setInitialState = () => {
  return dispatch => {
    dispatch({
      type: SIAT5Types.SIAT5_SETINITIAL_SUCCESS,
      payload: null
    });
  };
};

const updateStateSiatBegin = () => ({
  type: SIAT5Types.UPDATE_STATE_SIAT_REQUEST
});

const updateStateSiatSuccess = guest_record => ({
  type: SIAT5Types.UPDATE_STATE_SIAT_SUCCESS,
  payload: { guest_record }
});

const updateStateSiatFailure = error => ({
  type: SIAT5Types.UPDATE_STATE_SIAT_FAILURE,
  payload: { error }
});

export const updateStateSiat = (token, guest_record) => {
  return dispatch => {
    dispatch(updateStateSiatBegin());
    return axios
    .put(MainURL + Siat5Endpoints.UPDATE_STATE_SIAT_ENDPOINT + `/${guest_record.id}`, guest_record, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(updateStateSiatSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateStateSiatFailure(error.response));
      });
  };
};
