import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { room_types, agency_types, booking_types } from '../../config/models';
import {GetFormattedDate} from '../helper_java/date_format';

const Show = props => {
  const renderHeader = (data)=> {
    if (data != undefined){
      return (
        <div class="p-col-11 p-dialog-title" style={{display: 'inline-block', marginBottom: '-20px', marginTop: '-20px' }}>
          SIAT
          {/*<div className="p-datatable-globalfilter-container">
            Total registros: {data.length}
          </div>*/}
        </div>
      );
    }
  }

  const total_value = (data) => {
    if (data != undefined){
      return "Total registros: " + data.length
    }
  }

  return (
    <Dialog
      header={renderHeader(props.data.siat_two_records)}
      visible={props.visible}
      style={{ width: '70vw' }}
      modal={true}
      onHide={props.close}
    >
      <DataTable
        emptyMessage="No tienes siats registrados"
        value={props.data.siat_two_records}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        scrollable={true}
        scrollHeight="400px"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
        currentPageReportTemplate={total_value(props.data.siat_two_records)}
      >
        <Column
          header="Nro."
          body={(rowData, value) => {
            return value.rowIndex+1
          }}
          style={{textAlign:'center', width: '4em', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column
          body={rowData => {
            return GetFormattedDate(rowData.arrival);
          }}
          header="Fecha de ingreso"
          style={{textAlign:'center', width:'150px'}}
        />
        <Column
          body={rowData => {
            return GetFormattedDate(rowData.departure);
          }}
          header="Fecha de salida"
          style={{textAlign:'center', width:'140px'}}
        />
        <Column field="days"
          header="Días de pernocte"
          style={{textAlign:'center', width:'100px'}}
        />
        <Column
          body={rowData => {
            return rowData.person.name + " " + rowData.person.last_name  + " " + rowData.person.middle_name;
          }}
          header="Nombre Completo"
          style={{width:'200px'}}
        />
        <Column
          body={rowData => {
            return rowData.document;
          }}
          header="Número de documento de identidad"
          style={{width:'200px'}}
        />
        <Column
          body={rowData => {
            return rowData.nationality_name;
          }}
          header="Nacionalidad"
          style={{width:'200px'}}
        />
        <Column
          body={rowData => {
          if (rowData.guest_record.state_siat){
            return "Completo";
          }
          else{
            return "Pendiente";
          }
          }}
          header="Registro"
          style={{textAlign:'center', width:'100px'}}
        />
      </DataTable>
    </Dialog>
  );
};

export default Show;
