import { LogTypes } from '../types';

const initialState = {
  error: {
    status: 0
  },
  logs: []
};

export default function logReducer(state = initialState, action) {
  switch (action.type) {
    case LogTypes.LOG_GET_REQUEST:
      return {
        ...state,
        error: {
          status: 0
        }
      };

    case LogTypes.LOG_GET_SUCCESS:
      return {
        ...state,
        error: {
          status: 0
        },
        logs: action.payload
      };

    case LogTypes.LOG_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        logs: []
      };

    default:
      return state;
  }
}
