import React, { Component, useEffect } from "react";
import * as AuthActions from "../../redux/actions/AuthActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
// import { connect } from "react-redux";
import Logo from "../../config/sidebar/logo.svg";


import { ProgressSpinner } from "primereact/progressspinner";

import SimpleReactValidator from "simple-react-validator";
import { FormMessages } from "../../config/formTranslations";

class Login extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: "p-message p-component p-message-error",
      messages: FormMessages
    });

    this.state = {
      username: "",
      password: ""
    }
  }

  componentDidMount(){
    if(this.props.location.pathname !== '/'){
      window.location="/"
    }
  }

  showError = () => {
    if (this.props.auth_error) {
      let error = this.props.auth_error.data
        ? this.props.auth_error.data.message
        : this.props.auth_error;
      this.messages.show({
        severity: "error",
        summary: "",
        detail: error,
        closable: false,
      });
    }else{
      console.log("Bienvenido")
      // document.location.href = '/'
    }
  };

  handleChange = (field_name,event) => {
    const { name, value } = event.target;
    this.setState({
      [field_name]: value
    });
  };

  handleSubmit = async() => {
    this.messages.clear();
    if ( this.validator.allValid() ) {
      await this.props.AuthActions.userLogin(this.state.username, this.state.password)
      this.showError();
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleKeyPress = async(event) => {
    if (event.key === "Enter" && this.validator.allValid()) {
      await this.props.AuthActions.userLogin(this.state.username, this.state.password);
      this.showError();
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    if (this.props.redirect === true) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        {this.props.loading === true ? (
          <div className="p-grid p-fluid p-justify-center">
            <ProgressSpinner
              style={{ width: "20%", height: "20%", marginTop: "10%" }}
              animationDuration=".5s"
            />
          </div>
        ) : (
          <div className="p-grid p-fluid p-justify-center">
            <div>
              <div className="backgorund-login">
                <div className="layout-logo">
                  <img
                    alt="Logo"
                    src={Logo}
                    style={{ width: "100%", height: "30vh" }}
                  />
                </div>
                <div className="p-inputgroup inputs-login-top">
                  <span className="p-inputgroup-addon icon-login">
                    <i className="pi pi-user" />
                  </span>
                  <InputText
                    onKeyPress={event => this.handleKeyPress(event)}
                    onChange={event => this.handleChange('username',event)}
                    value={this.state.username}
                    maxLength="50"
                    placeholder="Usuario"
                    name="usuario"
                  />
                </div>
                {this.validator.message("usuario", this.state.username, "required")}
                <div className="p-inputgroup inputs-login">
                  <span className="p-inputgroup-addon icon-login">
                    <i className="pi pi-lock" />
                  </span>
                  <Password
                    onKeyPress={event => this.handleKeyPress(event)}
                    feedback={false}
                    onChange={event => this.handleChange('password',event)}
                    value={this.state.password}
                    maxLength="50"
                    placeholder="Contraseña"
                    name="contraseña"
                  />
                </div>
                {this.validator.message("contraseña", this.state.password, "required")}
                <Messages ref={(el) => this.messages = el} />
                <div className="">
                  <Button
                    className="button-login "
                    onClick={() => this.handleSubmit()}
                    label="Ingresar"
                  />
                </div>
                <div style={{marginTop:'20px', fontSize:'15px'}}>
                  <a href="/ForgotPassword" >¿Olvidó su contraseña?</a>      
                </div>
                <div style={{marginTop:'20px', fontSize:'15px'}}>
                  <a href="/UnlockAccount" >Reenviar instrucciones de desbloqueo</a>       
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.AuthReducer.user,
  token: state.AuthReducer.token,
  loading: state.LoadingReducer.loading,
  redirect: state.AuthReducer.redirect,

  error: state.ErrorReducer.error,
  auth_error: state.AuthReducer.error
});

const mapDispatchToProps = dispatch => ({
  AuthActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
