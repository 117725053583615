import {Slugify} from '../../../helper_java/string_format';

var errors = [];
export const convertExcelRowToObject = (array, countries, nationalities, guest_records) => {

  errors = [];
  let result_array = [];
  let index = 0;
  let daily_report = {};
  array.shift();
  array.shift();
  array.shift();
  for (const row of array) {
    index++;
    if(validateNotNulls(row)) {
      let parte = {};
      parte.person_attributes = {};
      parte.local_id = "L"+new Date().getUTCMilliseconds()+index;
      let arrival = isValidDate(row[0], index, "fecha de ingreso");
      if (arrival) {
          parte.arrival = arrival.toISOString().substring(0, 10);
      }

      let departure = isValidDate(row[1], index, "fecha de salida");
      if (departure) {
        parte.departure = departure.toISOString().substring(0, 10);
      }

      let room = validateLengths(row[2], index, "habitación", 10);
      if (room){
        parte.room = room;
      }

      if (row[3]) {
        let origin_name = row[3].toLowerCase().trim();
        let search_results = countries.filter(
          country => Slugify(country.name.toLowerCase().trim()) === Slugify(origin_name)
        );
        if (search_results.length > 0) {
          parte.origin_id = search_results[0].id;
          parte.origin = search_results[0];
          parte.origin_name = search_results[0].name;
        } else {
          errors.push(convertErrorMessage(index, "país origen"));
        }
      } else {
        errors.push(convertErrorMessage(index, "país origen"));
      }

      //nationality
      if (row[4]) {
        let nationality_name = row[4].toLowerCase().trim();
        let search_results = nationalities.filter(
          nationality =>
          Slugify(nationality.name.toLowerCase().trim()) === Slugify(nationality_name)
        );
        if (search_results.length > 0) {
          parte.person_attributes.nationality_id = search_results[0].id;
          parte.person_attributes.nationality = search_results[0];
          parte.person_attributes.nationality_name = search_results[0].name;
        } else {
          errors.push(convertErrorMessage(index, "nacionalidad"));
        }
      } else {
        errors.push(convertErrorMessage(index, "nacionalidad"));
      }
      // doc type
      if (row[5]) {
        let doc_type_name = row[5].toLowerCase().trim();
        if (doc_type_name === "pasaporte") {
          parte.person_attributes.doc_type = 1;
          parte.person_attributes.doc_type_name = "Pasaporte";
        } else if (doc_type_name === "ci") {
          parte.person_attributes.doc_type = 2;
          parte.person_attributes.doc_type_name = "Cédula de Identidad";
        } else {
          errors.push(convertErrorMessage(index, "tipo de documento"));
        }
      } else {
        errors.push(convertErrorMessage(index, "tipo de documento"));
      }

      let documentNumber = validateLengths(row[6], index, "número de documento", 20);
      if (documentNumber) {
        parte.person_attributes.document = documentNumber;
      }

      let personName = validateLengths(row[7], index, "nombre", 30);
      if (personName) {
        parte.person_attributes.name = personName;
      }

      let personLastName = validateLengths(row[8], index, "apellido paterno", 30);
      if (personLastName) {
        parte.person_attributes.last_name = personLastName;
      }

      let personMiddleName = ((row[9] === null) || (row[9] === undefined) || (row[9].length === 0)) ? '' : validateLengths(row[9], index, "apellido materno", 30);
      if (personMiddleName) {
        parte.person_attributes.middle_name = personMiddleName;
      }

      let profession = validateLengths(row[10], index, "profesión", 50);
      if (profession) {
        parte.person_attributes.profession = profession;
      }

      // country
      if (row[11]) {
        let country_name = row[11].toLowerCase().trim();
        let search_results = countries.filter(
          country => Slugify(country.name.toLowerCase().trim()) === Slugify(country_name)
        );
        if (search_results.length > 0) {
          parte.person_attributes.country_id = search_results[0].id;
          parte.person_attributes.country = search_results[0];
        } else {
          errors.push(convertErrorMessage(index, "país de nacimiento"));
        }
      } else {
        errors.push(convertErrorMessage(index, "país de nacimiento"));
      }


      let birthdate = isValidDate(row[12], index, "fecha de nacimiento");
      if (birthdate) {
        parte.person_attributes.birthdate = birthdate
          .toISOString()
          .substring(0, 10);
      }

      let civil_status = validateLengths(row[13], index, "civil_status", 50);
      if (civil_status) {
        if(civil_status=="S.E."){
          parte.person_attributes.civil_status = null;
        }else{
          parte.person_attributes.civil_status = civil_status;
        }
      }

      if (isGuestRecordDuplicated(parte, result_array)) {
        errors.push(
          `La persona con el número de documento: ${parte.person_attributes.document}, esta duplicada en el presente Importador.`
        );
      } else if (isPersonAlreadyInDailyReport(guest_records, parte)){
        console.log('Persona exists in DailyReport');
      }
      else {
        result_array.push(parte);
      }
    }
  }
  daily_report.guest_records = result_array;
  return { daily_report, errors };
};

const isGuestRecordDuplicated = (validate_guest_record, guest_records) => {
  const guest_record_array = guest_records.filter(
    guest_record =>
      guest_record.person_attributes.document ==
      validate_guest_record.person_attributes.document
  );
  if (guest_record_array.length > 0) {
    return true;
  }
  return false;
};

function isPersonAlreadyInDailyReport(guest_records, guest_record){
  const guest_record_array = guest_records.filter(
    gr =>
    gr.person_attributes.document ===
    guest_record.person_attributes.document
  );
  if (guest_record_array.length > 0) {
    errors.push(`La persona con el número de documento: ${guest_record.person_attributes.document}, ya se encuentra registrada con el nombre: ${guest_record_array[0].person_attributes.name} ${guest_record_array[0].person_attributes.last_name} ${guest_record_array[0].person_attributes.middle_name}`);
    return true;
  }
  return false;
}

function validateNotNulls(row){

  let clone = row.slice(0); //create a clone because row is used everywhere
  clone.splice(9,1); //remove app materno
  var anyNull = clone.some(function (el) {
    return el === null;
  });
  if(anyNull){
    errors.push(`, No estan permitidos campos vacios, por favor resuelva y vuelva a intentarlo.`);
    return false;
  } else{
    return true;
  }
}

function validateLengths(stringValidated, index, message, allowedLength){
  stringValidated = stringValidated.toString();
  if (stringValidated.length > allowedLength){
    errors.push(`Fila ${index} el campo ${message} tiene una longitud que no es permitida.`);
    return false;
  }
  return stringValidated;
}

function isValidDate(stringDate, index, message) {
  const moment = require('moment');
  let date = moment();

  if(moment(stringDate, ["DD/MM/YYYY", "MM/DD/YYYY", "YYYY-MM-DD"], "bo", true).isValid()){
    date = moment(stringDate, ["DD/MM/YYYY", "MM/DD/YYYY", "YYYY-MM-DD"], "bo", true).toDate();
  } else {
    errors.push(`Fila ${index} el campo ${message} no corresponde a una fecha reconocida.`);
    return false;
  }
  if((message === 'fecha de nacimiento') || (message === 'fecha de ingreso')){
    let today = moment();
    if (moment(stringDate, ["DD/MM/YYYY", "MM/DD/YYYY", "YYYY-MM-DD"], "bo", true).isAfter(today)){
      errors.push(`Fila ${index} el campo ${message} no puede ser una fecha superior a la de hoy.`);
    return false;
    }
  }
  return date;
}

function convertErrorMessage(row, field) {
  return `Fila ${row} no se encontro el campo ${field}`;
}
