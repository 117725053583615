import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as GuestRecordActions from "../../../../redux/actions/GuestRecordActions";
import * as DailyReportActions from "../../../../redux/actions/DailyReportActions";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import GuestFormView from "../components/GuestFormView";
import SimpleReactValidator from "simple-react-validator";
import { FormMessages } from "../../../../config/formTranslations";

class GuestForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: "p-message p-component p-message-error",
      messages: FormMessages,
      validators: {
        space_invalid: {
          message: 'El :attribute no es válido.',
          rule: (val, params, validator) => {
            var re = /^([a-zA-ZÀ-ÿ\u00f1\u00d1])+(\s{0,1}[a-zA-ZÀ-ÿ\u00f1\u00d1])*$/
            return validator.helpers.testRegex(String(val).toLowerCase().trim(),re)
          }
        },
        space_invalid_cargo: {
          message: 'La :attribute no es válida.',
          rule: (val, params, validator) => {
            var re = /^([a-zA-ZÀ-ÿ\u00f1\u00d1])+(\s{0,1}[a-zA-ZÀ-ÿ\u00f1\u00d1])*$/
            return validator.helpers.testRegex(String(val).toLowerCase().trim(),re)
          }
        }

      }
    });

    this.state = this.setInitialState();
  }
  setInitialState = () => {
    return {
      this_person_has_guest_records: null,
      new_person: null,
      send_data: false,
      form: {
        arrival: "",
        departure: "",
        room: "",
        origin: "",
        destiny: "",
        country_id: "",
        nationality_id: "",
        profession: "",
        civil_status: "",
        document: "",
        doc_type: "",
        birthdate: "",
        name: "",
        middle_name: "",
        last_name: "",
        preload: false,
        disabled: false,
        guest_id: null,
        last_guest_record_id: null,
        m_type: null
      }
    };
  };
  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };
  searchPersonByDni = async event => {
    const {value} = event.target;
    if (value === ''){
      this.setState({
        form: {
          ...this.state.form,
          name: "",
          profession: "",
          civil_status: "",
          birthdate: "",
          last_name: "",
          middle_name: "",
          nationality_id: "",
          doc_type: "",
          country_id: "",
          disabled: false,
        }
      });
    }else{
      await this.props.searchPersonByDni(event.target.value);
      if(this.props.person == null){
        this.setState({
          new_person: true,
          form: {
            ...this.state.form,
            name: "",
            profession: "",
            civil_status: "",
            birthdate: "",
            last_name: "",
            middle_name: "",
            nationality_id: "",
            doc_type: "",
            country_id: "",
            disabled: false,
          }
        })
      }
    }

  };

  componentDidUpdate(prevProps) {
    if (this.props.guest_record !== prevProps.guest_record) {
      if (this.props.guest_record != null) {
        const {
          arrival,
          departure,
          room,
          origin_id,
          destiny_id,
          preload,
            id,
        } = this.props.guest_record;
        const {
          name,
          middle_name,
          last_name,
          country_id,
          profession,
          civil_status,
          doc_type,
          birthdate,
          nationality_id
        } = this.props.guest_record.person_attributes;
        let origin = this.props.countries.filter(
          country => country.id === origin_id
        )[0];
        let destiny = this.props.countries.filter(
          country => country.id === destiny_id
        )[0];
        let country = this.props.countries.filter(
          country => country.id === country_id
        )[0];
        let nationality = this.props.nationalities.filter(
          nationality => nationality.id === nationality_id
        )[0];
        this.setState({
          this_person_has_guest_records: this.props.this_person_has_guest_records,
          form: {
            preload,
            origin: origin,
            origin_id: origin ? origin.id : 0,
            origin_name: origin ? origin.name : "",
            destiny: destiny,
            room,
            destiny_name: destiny ? destiny.name : "",
            destiny_id: destiny ? destiny.id : 0,
            departure: this.parseStringToDate(departure),
            arrival: this.parseStringToDate(arrival),
            country,
            country_id: country,
            country_name: country ? country.name : "",
            nationality,
            nationality_id: nationality,
            profession,
            civil_status,
            document: this.props.guest_record.person_attributes.document,
            doc_type,
            birthdate: this.parseStringToDate(birthdate),
            name,
            middle_name,
            last_name,
            guest_id: id,
            last_guest_record_id: this.props.guest_record.last_guest_record_id,
            m_type: this.props.guest_record.m_type
          }
        });
      }
    }
  }

  parseStringToDate(stringDate) {
    if (stringDate == null) return "";
    let from = stringDate.split("-");
    return new Date(from[0], from[1] - 1, from[2]);
  }

  closeVisitModal = () => {
    this.validator.hideMessages();
    this.props.DailyReportActions.restartCanDestroySuccessRecords()
    this.setState({
      ...this.state,
      form: this.setInitialState().form,
      this_person_has_guest_records: null,
      new_person: null
    });

    this.props.close();
  };

  handleSubmit = () => {
    this.setState({
      send_data: true
    });
    if (this.validator.allValid()) {
      const { arrival, departure, room, origin, destiny } = this.state.form;
      if (arrival.getTime() > this.props.date.getTime()) {
        this.messages.show({
          severity: "error",
          summary: "Fechas inválidas",
          detail: "La fecha de ingreso no puede ser mayor a la fecha de parte",
          closable: false,
        });
        return;
      }
      if (departure < arrival) {
        this.messages.show({
          severity: "error",
          summary: "Fechas inválidas",
          sticky: true,
          detail: "La fecha de salida debe ser mayor a la fecha de ingreso.",
          closable: false,
        });
        return;
      }
      if (this.state.form.civil_status == "") {
        return;
      }

      const {
        name,
        middle_name,
        last_name,
        country_id,
        profession,
        civil_status,
        document,
        doc_type,
        birthdate,
        nationality_id,
      } = this.state.form;

      let guest_record = {
        arrival: arrival.toISOString().substring(0, 10),
        departure: departure.toISOString().substring(0, 10),
        room,
        origin_id: origin.id,
        origin: origin,
        origin_name: origin.name,
        destiny_name: destiny ? destiny.name : "",
        destiny_id: destiny ? destiny.id : "",
        destiny,
        person_attributes: {
          name,
          middle_name,
          last_name,
          birthdate: birthdate.toISOString().substring(0, 10),
          country: country_id,
          country_id: country_id.id,
          profession,
          civil_status,
          document: document,
          doc_type,
          nationality: nationality_id,
          nationality_id: nationality_id.id
        }
      };
      if (guest_record.destiny) guest_record.destiny = guest_record.destiny.id;
      if (this.isGuestRecordDuplicated(guest_record, this.props.guest_record)) {
        this.messages.show({
          severity: "error",
          summary: "Registro duplicado",
          sticky: true,
          detail:
            "Ya existe una persona registrada con el mismo número de documento",
          closable: false,
        });
        return;
      }
      if (this.props.guest_record == null) {
        guest_record.local_id = new Date().getUTCMilliseconds();
        this.props.GuestRecordActions.createGuestRecord(guest_record);
      } else {
        guest_record.local_id = this.props.guest_record.id
          ? this.props.guest_record.id
          : this.props.guest_record.local_id;
        guest_record.id = this.props.guest_record.id;
        this.props.GuestRecordActions.editGuestRecord(guest_record);
      }
      this.props.DailyReportActions.restartCanDestroySuccessRecords()
      this.props.close();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  isGuestRecordDuplicated(validate_guest_record, current_guest_record) {
    let update_number = current_guest_record
      ? current_guest_record.person_attributes.document
      : -1;
    const guest_record_array = this.props.guest_records.filter(
      guest_record =>
        guest_record.person_attributes.document ==
          validate_guest_record.person_attributes.document &&
        validate_guest_record.person_attributes.document != update_number
    );
    if (guest_record_array.length > 0) {
      return true;
    }
    return false;
  }

  editPerson = rowData => {
    this.openPersonModal();
    this.setState({
      currentPerson: rowData
    });
  };
  deletePerson = rowData => {};

  componentWillReceiveProps(nextProps) {
    if (nextProps.person != null && this.state.form.document === nextProps.person.document) {
      const person = nextProps.person;
      this.setState({
        form: {
          ...this.state.form,
          name: person.name,
          profession: person.profession,
          civil_status: person.civil_status,
          birthdate: this.parseStringToDate(person.birthdate),
          last_name: person.last_name,
          middle_name: person.middle_name,
          nationality_id: this.props.nationalities.filter( object => object.id === person.nationality_id) [0],
          doc_type: person.doc_type,
          country_id: this.props.countries.filter( object => object.id === person.country_id) [0],
          disabled: true,
        }
      });
    }else{
      this.setState({
        form: {
          ...this.state.form,
          name: "",
          profession: "",
          civil_status: "",
          birthdate: "",
          last_name: "",
          middle_name: "",
          nationality_id: "",
          doc_type: "",
          country_id: "",
          disabled: false,
        }
      });
    }
  };

  async componentDidMount() {
      if (this.props.guest_record) {
        const {
          arrival,
          departure,
          room,
          origin_id,
          destiny_id,
          preload,
          id,
        } = this.props.guest_record;
        const {
          name,
          middle_name,
          last_name,
          country_id,
          profession,
          civil_status,
          doc_type,
          birthdate,
          nationality_id
        } = this.props.guest_record.person_attributes;
        let origin = this.props.countries.filter(
            country => country.id === origin_id
        )[0];
        let destiny = this.props.countries.filter(
            country => country.id === destiny_id
        )[0];
        let country = this.props.countries.filter(
            country => country.id === country_id
        )[0];
        let nationality = this.props.nationalities.filter(
            nationality => nationality.id === nationality_id
        )[0];

        await this.setState({
          this_person_has_guest_records: this.props.this_person_has_guest_records,
          form: {
            preload,
            origin: origin,
            origin_id: origin ? origin.id : 0,
            origin_name: origin ? origin.name : "",
            destiny: destiny,
            room,
            destiny_name: destiny ? destiny.name : "",
            destiny_id: destiny ? destiny.id : 0,
            departure: this.parseStringToDate(departure),
            arrival: this.parseStringToDate(arrival),
            country,
            country_id: country,
            country_name: country ? country.name : "",
            nationality,
            nationality_id: nationality,
            profession,
            civil_status,
            document: this.props.guest_record.person_attributes.document,
            doc_type,
            birthdate: this.parseStringToDate(birthdate),
            name,
            middle_name,
            last_name,
            guest_id: id,
            last_guest_record_id: this.props.guest_record.last_guest_record_id,
            m_type: this.props.guest_record.m_type
          }
        });
      }

  };

  validate_edit_departure = (next_date_daily_report, date_form, form, state_edit_finish_entry) =>{
    // var new_date = new Date(
    //   new Date(
    //     new Date(new Date(next_date_daily_report.next_date).setDate(new Date(next_date_daily_report.next_date).getDate() + 0))
    //   ).setHours(0, 0, 0, 0)
    // );
    // if( (this.props.state == 1) || (this.props.state == 0 && date_form >= new_date) ){
    if(((this.props.state == 1 || (this.props.state == 0 && form.m_type == "ingreso" && this.props.state_edit_finish_entry)) || form.m_type == null) || (state_edit_finish_entry == true)) {
      return false;
    }
    return true;
  };
  editable_state_person = () =>{
    if((this.props.state == 0 && this.props.quantity_daily_reports_finished==1) || (this.props.quantity_daily_reports_finished==0)){
      return true
    }
    if(this.props.quantity_daily_reports_finished>1 || this.props.quantity_daily_reports_finished!=null ){
      return false
    }
    return null
  }

  get_error_civil_status = ()=>{
    if(this.state.form.civil_status == "" && this.state.send_data == true){
      return (
        <div class="p-message p-component p-message-error">
          El campo estado civil es obligatorio.
        </div>
      )
    }
    return null
  }

  render() {
    const error_civil_status = (this.get_error_civil_status());
    const footer = (
      <div>
        <Button
          label="Guardar"
          onClick={this.handleSubmit}
          icon="pi pi-check"
        />
        <Button
          className="p-button-danger"
          label="Cancelar"
          icon="pi pi-times"
          onClick={this.closeVisitModal}
        />
      </div>
    );

    return (
      <Dialog
        style={{ width: "50vw" }}
        maximizable
        footer={footer}
        header={
          this.props.guest_record == null ? "Nuevo registro" : "Editar registro"
        }
        visible={this.props.visible}
        modal={true}
        onHide={this.closeVisitModal}
      >
        <Messages ref={el => (this.messages = el)} />
        <GuestFormView
          error_civil_status= {error_civil_status}
          state = {this.props.state}
          handleChange={this.handleChange}
          form={this.state.form}
          countries={this.props.countries}
          nationalities={this.props.nationalities}
          validator={this.validator}
          part_id={this.props.parte_id}
          handleOnBlur={this.searchPersonByDni}
          this_person_has_guest_records={this.state.this_person_has_guest_records}
          new_person={this.state.new_person}
          validate_edit_departure={this.validate_edit_departure(this.props.next_date_daily_report, this.props.date, this.state.form, this.props.state_edit_finish_entry)}
          can_destroy={this.props.can_destroy}
          state_edit_finish_entry={this.props.state == 0 && this.state.form.m_type == "ingreso" && this.props.state_edit_finish_entry? true : false}
          editable_state_person= {this.editable_state_person()}
          quantity_daily_reports_finished = {this.props.quantity_daily_reports_finished}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  countries: state.CountryReducer.countries,
  nationalities: state.NationalityReducer.nationalities,
  token: state.AuthReducer.token,
  guest_records: state.ParteReducer.guest_records,
  person: state.DailyReportReducer.person,
  this_person_has_guest_records: state.DailyReportReducer.this_person_has_guest_records,
  can_destroy: state.DailyReportReducer.can_destroy,
  quantity_daily_reports_finished: state.DailyReportReducer.quantity_daily_reports_finished,
});
const mapDispatchToProps = dispatch => ({
  DailyReportActions: bindActionCreators(DailyReportActions, dispatch),
  GuestRecordActions: bindActionCreators(GuestRecordActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(GuestForm);
