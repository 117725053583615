import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const inputStyle = { margin: '10px 0px' };

const SiatShow = props => {
  const footer = (
    <div>
      <Button
        className="p-button-danger"
        label="Cerrar"
        icon="pi pi-times"
        onClick={props.close}
      />
    </div>
  );
  return (
    <Dialog
      header="SIAT I"
      footer={footer}
      visible={props.visible}
      style={{ width: '70vw' }}
      modal={true}
      onHide={props.close}
    >
      <div className="p-grid p-fluid">

        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.date}
              disabled="true"
            />
            <label htmlFor="in">Fecha</label>
          </span>
        </div>
        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.nit}
              disabled="true"
            />
            <label htmlFor="in">NIT del contribuyente</label>
          </span>
        </div>
        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.branch}
              disabled="true"
            />
            <label htmlFor="in">Casa matriz o sucursal</label>
          </span>
        </div>

        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.owner}
              disabled="true"
            />
            <label htmlFor="in">Nombre o razón social del propietario del establecimiento</label>
          </span>
        </div>
        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.establishment_name}
              disabled="true"
            />
            <label htmlFor="in">Nombre comercial del establecimiento</label>
          </span>
        </div>

        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.address}
              disabled="true"
            />
            <label htmlFor="in">Dirección del establecimiento</label>
          </span>
        </div>
        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.establishment_type_desc}
              disabled="true"
            />
            <label htmlFor="in">Tipo de establecimiento</label>
          </span>
        </div>

        {props.form.category_type === 10 ?
          <div className="p-col-12 p-md-6" style={inputStyle}>
            <span className="p-float-label">
              <InputText
                value={props.form.description}
                disabled="true"
              />
              <label htmlFor="in">Descripción tipo de establecimiento (otros)</label>
            </span>
          </div>
          :
            ''
        }

        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.category_desc}
              disabled="true"
            />
            <label htmlFor="in">Categoría del establecimiento</label>
          </span>
        </div>
        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.total_room}
              disabled="true"
            />
            <label htmlFor="in">Número total de habitaciones</label>
          </span>
        </div>

        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.total_apartment}
              disabled="true"
            />
            <label htmlFor="in">Número total de departamentos</label>
          </span>
        </div>
        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.total_bungalow}
              disabled="true"
            />
            <label htmlFor="in">Número total de cabañas o bungalows</label>
          </span>
        </div>

        <div className="p-col-12 p-md-6" style={inputStyle}>
          <span className="p-float-label">
            <InputText
              value={props.form.reg_mod_desc}
              disabled="true"
            />
            <label htmlFor="in">Modalidad de registro del huésped</label>
          </span>
        </div>

        {props.form.reg_mod === 3 ?
          <div className="p-col-12 p-md-6" style={inputStyle}>
            <span className="p-float-label">
              <InputText
                value={props.form.mod_desc}
                disabled="true"
              />
              <label htmlFor="in">Descripción de la modalidad de otro sistema de registro</label>
            </span>
          </div>
          :
            ''
        }

      </div>
    </Dialog>
  );
};

export default SiatShow;
