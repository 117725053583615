export function GetFullName(name, last_name, mater_name){
  var full_name = ""
  if( mater_name != null && last_name !=null){
    full_name = last_name + " " + mater_name + " " + name
  }
  else {
    if(mater_name != null){
      full_name = mater_name + " " + name
    }else{
      if(last_name != null){
        full_name = last_name + " " + name
      }else{
        full_name = name
      }
    }
  }
  return full_name;
}

export function GetFullNameValidated(name, last_name, middle_name){
  let full_name = '';
  
  full_name = ((name === null) || (name === undefined) || (name.length === 0)) ? '' : name;
  full_name = ((last_name === null) || (last_name === undefined) || (last_name.length === 0)) ? full_name : (full_name + ' ' + last_name);
  full_name = ((middle_name === null) || (middle_name === undefined) || (middle_name.length === 0)) ? full_name : (full_name + ' ' + middle_name);
  
  return full_name
}
