import React, { Component } from "react";
import SiatTable from "./table";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Siat5Actions from "../../redux/actions/Siat5Actions";
import * as DailyReportActions from "../../redux/actions/DailyReportActions";
import Show from "./show";
import WithMessage from "../messaheHOC";
const TableWithMessage = WithMessage(SiatTable);

class Siat5 extends Component {
  state = {
    showVisible: false,
    siat: null
  };
  async componentDidMount() {
    await this.props.DailyReportActions.getDailyReports(this.props.token);
    await this.props.Siat5Actions.getsiats(this.props.token);
  }

  add = () => {
    this.props.history.push(`/siat5/new`);
  };

  show = siat => {
    this.setState({
      siat,
      showVisible: true
    });
  };

  closeShow = () => {
    this.setState({
      showVisible: false
    });
  };

  edit = siat_id => {
    this.props.history.push(`/siat5/${siat_id}/edit`);
  };

  render() {
    return (
      <div>
        <TableWithMessage
          loading={this.props.loading}
          success={this.props.success}
          error={this.props.error}
          data={this.props.siats5}
          add={this.add}
          edit={this.edit}
          show={this.show}
          nextDateDailyReport={this.props.next_date_daily_report}
        />
        {this.state.siat != null ? (
          <Show
            visible={this.state.showVisible}
            close={this.closeShow}
            data={this.state.siat}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.AuthReducer.user,
  token: state.AuthReducer.token,
  siats5: state.Siat5Reducer.siats5,
  redirect: state.DailyReportReducer.redirect,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error,
  success: state.LoadingReducer.success,
  next_date_daily_report: state.DailyReportReducer.next_date_daily_report
});

const mapDispatchToProps = dispatch => ({
  Siat5Actions: bindActionCreators(Siat5Actions, dispatch),
  DailyReportActions: bindActionCreators(DailyReportActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Siat5);
