import React from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { es } from "../../../../config/translations";
import { Checkbox } from "primereact/checkbox";
import { FileUpload } from "primereact/fileupload";
import ExcelFile from "../../../../config/formato_parte.xlsx";

function ParteForm(props) {
  const { date, state, id } = props.form;
  function enable_edit_or_create_part() {
    return date != null
  }
  return (
    <div>
      <h3 style={{ marginLeft: "7px" }} className="first">
        {id > 0 ? "Editar parte" : "Nueva parte"}
      </h3>

      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-4">
          <div className="p-inputgroup">
            <span className="p-inputgroup-addon">
              <i className="pi pi-calendar"></i>
            </span>
            <Calendar
              locale={es}
              maxDate={new Date()}
              dateFormat="dd/mm/yy"
              placeholder="Fecha"
              name="date"
              onChange={props.handleChange}
              value={date}
              disabled={true}
            />
          </div>
          {props.validator.message("Fecha", date, "required")}
        </div>
        <div className="p-col-12 p-md-2">
          <Checkbox
            name="state"
            inputId="cb1"
            onChange={props.handleCheckbox}
            checked={state == 1 ? false : true}
            disabled={props.state == 1 ? false : true}
          />
          <label htmlFor="cb1" className="p-checkbox-label">
            Finalizado
          </label>
        </div>

        {
          state == 1 ?
          <>
            { enable_edit_or_create_part() ?
                <div className="p-col-fixed" style={{ width: "200px" }}>
                  <Button
                      onClick={props.showGuestModal}
                      label="Agregar registro"
                      className="p-button-success"
                      icon="pi pi-plus"
                      style={{ marginRight: ".25em" }}
                  />
                </div> :
                ""
            }
            <div className="p-col-fixed" style={{ width: "200px" }}>
              <FileUpload
                mode="basic"
                chooseLabel="Importar excel"
                auto={true}
                customUpload={true}
                accept=".xlsx"
                uploadHandler={event => {
                  props.handleImport(event);
                }}
                maxFileSize={1000000}
              />
            </div>
          </>
          :
          ""
        }
        <div className="p-col-fixed" style={{ width: "200px" }}>
          <a href={ExcelFile} download="plantilla_parte.xlsx">
            <Button
              label="Descargar plantilla"
              className="p-button-secondary"
              icon="pi pi-download"
              style={{ marginRight: ".25em" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ParteForm;
