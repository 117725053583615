import React, { Component } from 'react';
import { Card } from 'primereact/card';
import { Messages } from 'primereact/messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Redirect } from 'react-router-dom';
import Form from './form';
import FormShow from './form_show';
import ModalFormFinalizate from './modal_form_finalizate';
import SiatTable from './table';
import HeaderForm from './header_form';
import SimpleReactValidator from 'simple-react-validator';
import { FormMessages } from '../../config/formTranslations';
import * as NationalityActions from '../../redux/actions/NationalityActions';
import * as Siat2Actions from '../../redux/actions/Siat2Actions';
import * as Siat3Actions from '../../redux/actions/Siat3Actions';
import * as Siat4Actions from '../../redux/actions/Siat4Actions';
import * as Siat5Actions from '../../redux/actions/Siat5Actions';
import Footer from './footer';

class Siat5New extends Component {
  constructor(props) {
    super(props);
    this.validator = this.newValidator();
    this.local_validator = this.newValidator();
    this.state = this.setIinitalState();
    this.handleFinish = this.handleFinish.bind(this);
  }
  setIinitalState = () => {
    return {
      modalVisible: false,
      modalshowvisible: false,
      modalFormFinalizate: false,
      modalFormFinalizateNotBlocked: false,
      modal_siats_two: {
        id: null,
        siat_two_id: null,
        observation: null,
        agency_nit: null,
        agency_type: null,
        arrival: null,
        departure: null,
        document: null,
        bill_desc: null,
        bill_number: null,
        booking_engine: null,
        booking_type: null,
        days: null,
        nationality_id: null,
        room_type: null,
        room_desc: null,
      },
      modal_siats_three: {
        id: null,
        siat_three_id: null,
        code_daily: null,
        unique_code: null,
        amount_charged: null,
        payment_type: null,
        payment_desc: null,
        nit: null,
        doc_type: null,
        doc_number: null,
      },
      modal_siats_four: {
        id: null,
        siat_four_id: null,
        agency_name: null,
        agency_nit: null,
        commission: null,
        financial_entity_name: null,
        payment_desc: null,
        payment_type: null,
      },
      guest_record: null,
      form: {
        id: null,
        nit: '',
        branch: ''
      }
    }
  }

  newValidator = ()=>{
    return new SimpleReactValidator({
      className: 'p-message p-component p-message-error',
      messages: FormMessages,
      validators: {
        invalid_value: {  // name the rule
          message: 'El campo no puede ser cero.',
          rule: (val, params, validator) => {
            if(
              (val == 0 && this.state.modal_siats_three.unique_code != null && this.state.modal_siats_three.unique_code[0] != 0)
            ){
              return validator.helpers.testRegex(parseInt(val),/^[1-9][0-9]*$/)
            }
          }
        },
        invalid_nit_if_zero: {  // name the rule
          message: 'El campo no puede ser cero.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(parseInt(val),/^[1-9][0-9]*$/)
          }
        },
        new_required:{
          message: "El campo :attribute",
          rule: function (e) {
            return !(e=="")
          },
          required: !0
        }
      }

    });
  }

  async componentDidMount() {
    this.props.NationalityActions.getNationalities(this.props.token);
    const siat_id = this.props.match.params.id;
    if (siat_id != null) {
      await this.props.Siat5Actions.getsiatById(this.props.token, siat_id);
      let siat = this.props.siat;
      this.setState({
        form: siat
      });
    }else{
      this.props.Siat5Actions.setInitialState();
      this.state = this.setIinitalState();
    }
  }

  handleChange = event => {
    const { name } = event.target;
    let value = event.target.value;
    let input_type = event.target.dataset.type;
    if (input_type === 'number' && value.includes('-')) {
      value = value.split('-').join('');
    }

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  handleFinish = async () => {
    if (this.validator.allValid()) {
      let siats = {
        ...this.state.form,
        state: 1
      };
      await this.props.Siat2Actions.editsiat2(this.props.token, siats);
      await this.props.Siat3Actions.editsiat3(this.props.token, siats);
      await this.props.Siat4Actions.editSiat4(this.props.token, siats);
      this.cancelUpload();
      this.closeModal();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleUpload = async () => {
    if (this.validator.allValid()) {
      let siats = {
        ...this.state.form
        // establishment_id: this.props.user.establishment_id,
        // siat_two_records_attributes: this.props.records.reverse()
      };
      await this.props.Siat2Actions.editsiat2(this.props.token, siats);
      await this.props.Siat3Actions.editsiat3(this.props.token, siats);
      await this.props.Siat4Actions.editSiat4(this.props.token, siats);
      this.cancelUpload();
      this.closeModal();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };
  // get_siat_state = (siats) => {
  //   if(
  //     (siats[0].bill_number != null || siats[0].bill_number != '' || siats[0].bill_number == 0) &&
  //     (siats[0].booking_type != null || siats[0].booking_type != '' || siats[0].booking_type == 0) &&
  //     (siats[0].room_type != null || siats[0].room_type != '' || siats[0].room_type == 0) &&
  //
  //     (siats[1].doc_type != null || siats[1].doc_type != '' || siats[1].doc_type == 0) &&
  //     (siats[1].doc_number != null || siats[1].doc_number != '' || siats[1].doc_number == 0) &&
  //     (siats[1].unique_code != null || siats[1].unique_code != '' || siats[1].unique_code == 0) &&
  //     (siats[1].code_daily != null || siats[1].code_daily != '' || siats[1].code_daily == 0) &&
  //     (siats[1].amount_charged != null || siats[1].amount_charged != '' || siats[1].amount_charged == 0) &&
  //     (siats[1].payment_type != null || siats[1].payment_type != '' || siats[1].payment_type == 0) &&
  //     (siats[1].nit != null || siats[1].nit != '' || siats[1].nit == 0) &&
  //
  //     (siats[2].agency_name != null || siats[2].agency_name != '' || siats[2].agency_name == 0) &&
  //     (siats[2].agency_nit != null || siats[2].agency_nit != '' || siats[2].agency_nit == 0) &&
  //     (siats[2].commission != null || siats[2].commission != '' || siats[2].commission == 0) &&
  //     (siats[2].payment_type != null || siats[2].payment_type != '' || siats[2].payment_type == 0)
  //
  //   ){
  //     return true
  //   }else{
  //     return false
  //   }
  // }

  handleSubmit = async() => {
    if (this.local_validator.allValid()) {
      await this.props.Siat2Actions.editRecord(this.props.token, this.state.modal_siats_two);
      await this.props.Siat3Actions.editRecord(this.props.token, this.state.modal_siats_three);
      await this.props.Siat4Actions.editRecord(this.props.token, this.state.modal_siats_four);

      // var get_siat_state = await this.get_siat_state(
      //   [this.state.modal_siats_two, this.state.modal_siats_three, this.state.modal_siats_four]
      // )

      await this.setState({
        guest_record: {
          ...this.state.guest_record,
          state_siat: true
        }
      });

      await this.props.Siat5Actions.updateStateSiat(
        this.props.token,
        this.state.guest_record
      );

      this.closeModal();
    } else {
      this.local_validator.showMessages();
      this.forceUpdate();
    }
  };

  cancelUpload = () => {
    this.state = this.setIinitalState();
    this.props.Siat5Actions.setInitialState();
    this.props.history.push(`/siat5`);
  };

  fix_nationality = (nationality) =>{
    return {id: nationality.id, name: nationality.name, state: nationality.state}
  };

  handleRecordChangeTwo = async(event) => {
    const { name, value } = event.target;
    // let { name } = event.target.name;
    // let value = event.target.value;
    let input_type = event.target.dataset.type;
    if (input_type === 'number' && value.includes('-')) {
      value = value.split('-').join('');
    }

    if (name == "bill_number" && value != 0){
      await this.setState({
        modal_siats_two: {
          ...this.state.modal_siats_two,
          observation: ""
        }
      });
    }

    await this.setState({
      modal_siats_two: {
        ...this.state.modal_siats_two,
        [name]: value
      }
    });
  };

  handleChangeTwoSelect = async(event) => {
    const { name, value } = event.target;
    await this.setState({
      modal_siats_two: {
        ...this.state.modal_siats_two,
        [name]: value
      }
    });

    if (name == "room_type" && value != 4){
      await this.setState({
        modal_siats_two: {
          ...this.state.modal_siats_two,
          room_desc: ""
        }
      });
    }

    if(name == "booking_type" && value == 1){
      await this.setState({
        modal_siats_two: {
          ...this.state.modal_siats_two,
          agency_nit: "0"
        }
      });
    }
    else{
      if(this.state.modal_siats_two.booking_type != 1){
        await this.setState({
          modal_siats_two: {
            ...this.state.modal_siats_two,
            agency_nit: "",
            agency_type: ""
          }
        });
      }
    }

    if (name == "booking_type" && value != 3){
      await this.setState({
        form: {
          ...this.state.form,
          booking_engine: ""
        }
      });
    }
  };

  showModal = () => {
    this.setState({
      modalVisible: true
    });
  };

  check_if_all_are_complete = () => {
    var records = this.props.records
    for (let i = 0; i < records.length; i++) {
      if(records[i].guest_record.state_siat != true){
        return false
      }
    }
    return true
  }

  showModalFormFinalizate = () => {
    var aux = false;

    if (this.props.next_date_daily_report.next_date != null){
      var year1 = this.props.next_date_daily_report.next_date.substring(0, 4);
      var month1 = this.props.next_date_daily_report.next_date.substring(5, 7);
    }else{
      var date_now = new Date();
      var year1 = date_now.getMonth()+1
      var month1 = date_now.getFullYear()
    }
    var month2 = this.state.form.date.substring(3, 5);
    var year2 = this.state.form.date.substring(6, 10);

    if(this.check_if_all_are_complete() && year1 == year2 && month1 != month2){
      aux = true
    }
    this.setState({
      modalFormFinalizate: true,
      modalFormFinalizateNotBlocked: aux
    });
  };

  closeModalFormFinalizate = () => {
    this.setState({
      modalFormFinalizate: false
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
      modalshowvisible: false,
      modalFormFinalizate: false,
      modal_siats_two: {
        id: null,
        siat_two_id: null,
        observation: null,
        agency_nit: null,
        agency_type: null,
        arrival: null,
        departure: null,
        document: null,
        bill_desc: null,
        bill_number: null,
        booking_engine: null,
        booking_type: null,
        days: null,
        nationality_id: null,
        room_type: null,
        room_desc: null,
      },
      modal_siats_three: {
        id: null,
        siat_three_id: null,
        code_daily: null,
        unique_code: null,
        amount_charged: null,
        payment_type: null,
        payment_desc: null,
        nit: null,
        doc_type: null,
        doc_number: null,
      },
      modal_siats_four: {
        id: null,
        siat_four_id: null,
        agency_name: null,
        agency_nit: null,
        commission: null,
        financial_entity_name: null,
        payment_desc: null,
        payment_type: null,
      },
      guest_record: null
    });
    this.local_validator.hideMessages();
  };

  editRecord = async(guest_record_id) => {
    await this.props.Siat5Actions.siat5Modalshow(this.props.token, guest_record_id)
    this.setState({
      modalVisible: true,
      modal_siats_two: this.props.modal_siats_two,
      modal_siats_three: this.props.modal_siats_three,
      modal_siats_four: this.props.modal_siats_four,
      guest_record: this.props.guest_record
    });
  };

  showRecord = async(guest_record_id) => {
    await this.props.Siat5Actions.siat5Modalshow(this.props.token, guest_record_id)
    this.setState({
      modalshowvisible: true,
      modal_siats_two: this.props.modal_siats_two,
      modal_siats_three: this.props.modal_siats_three,
      modal_siats_four: this.props.modal_siats_four,
      guest_record: this.props.guest_record
    });
  };

  showErrorsSiat = () => {
    if (this.props.error_siat != null){
      this.messages.show({
        severity: "error",
        detail: this.props.error_siat || this.props.error_siat2 || this.props.error_siat3 || this.props.error_siat4,
        closable: false,
      });
    }
  }

  // Formulario III ///

  handleRecordChangeThree = async(event) => {
    const { name, value } = event.target;
    // const { name } = event.target;
    // let value = event.target.value;
    let input_type = event.target.dataset.type;
    if (input_type === 'number' && value.includes('-')) {
      if(name != "unique_code" && name != "code_daily"){
        value = value.split('-').join('');
      }
    }
    if (name == "unique_code" && value != null && value[0] == "0"){
      await this.setState({
        modal_siats_three: {
          ...this.state.modal_siats_three,
          code_daily: 0
        }
      });
    }

    this.setState({
      modal_siats_three: {
        ...this.state.modal_siats_three,
        [name]: value
      }
    });
  };
  handleChangeThreeSelect = async(event) => {
    const { name, value } = event.target;

    if (name == "payment_type" && value != 7){
      await this.setState({
        modal_siats_three: {
          ...this.state.modal_siats_three,
          payment_desc: ""
        }
      });
    }

    await this.setState({
      modal_siats_three: {
        ...this.state.modal_siats_three,
        [name]: value
      }
    });
  };

  handleRecordChangeFour = event => {
    const { name } = event.target;
    let value = event.target.value;
    let input_type = event.target.dataset.type;
    if (input_type === 'number' && value.includes('-')) {
      value = value.split('-').join('');
    }

    this.setState({
      modal_siats_four: {
        ...this.state.modal_siats_four,
        [name]: value
      }
    });
  };

  handleChangeFourSelect = async(event) => {
    const { name, value } = event.target;

    if (name == "payment_type" && value != 6){
      await this.setState({
        modal_siats_four: {
          ...this.state.modal_siats_four,
          payment_desc: ""
        }
      });
    }

    if ( name == "payment_type" ){
      if( !(value >= 4 && value <= 5) ){
        await this.setState({
          modal_siats_four: {
            ...this.state.modal_siats_four,
            financial_entity_name: ""
          }
        });
      }
    }

    await this.setState({
      modal_siats_four: {
        ...this.state.modal_siats_four,
        [name]: value
      }
    });
  };

  render() {
    this.local_validator.purgeFields();
    if (this.props.redirect === true) {
      return <Redirect to="/" />;
    } else if (this.props.error.status === 401) {
      return <Redirect to="/login" />;
    } else
      return this.props.loading ? (
        <div className="p-grid p-fluid p-justify-center">
          <ProgressSpinner
            style={{ width: '20%', height: '20%', marginTop: '10%' }}
            animationDuration=".5s"
          />
        </div>
      ) : (
        <Card>
          <Messages ref={el => (this.messages = el)} />
          <HeaderForm
            handleChange={this.handleChange}
            form={this.state.form}
            showModal={this.showModal}
            validator={this.validator}
          />
          <Form
            fix_nationality = {this.fix_nationality}
            handleSubmit={this.handleSubmit}
            handleChangeTwoSelect={this.handleChangeTwoSelect}
            handleChangeThreeSelect={this.handleChangeThreeSelect}
            handleChangeFourSelect={this.handleChangeFourSelect}
            onSelectArrival={this.onSelectArrival}
            onSelectDeparture={this.onSelectDeparture}
            handleChangeTwo={this.handleRecordChangeTwo}
            modal_siats_two={this.state.modal_siats_two}
            handleChangeThree={this.handleRecordChangeThree}
            modal_siats_three={this.state.modal_siats_three}
            handleChangeFour={this.handleRecordChangeFour}
            modal_siats_four={this.state.modal_siats_four}
            nationalities={this.props.nationalities}
            visible={this.state.modalVisible}
            closeModal={this.closeModal}
            validator={this.local_validator}
          />

          <FormShow
            fix_nationality = {this.fix_nationality}
            handleSubmit={this.handleSubmit}
            handleChangeTwoSelect={this.handleChangeTwoSelect}
            handleChangeThreeSelect={this.handleChangeThreeSelect}
            handleChangeFourSelect={this.handleChangeFourSelect}
            onSelectArrival={this.onSelectArrival}
            onSelectDeparture={this.onSelectDeparture}
            handleChangeTwo={this.handleRecordChangeTwo}
            modal_siats_two={this.state.modal_siats_two}
            handleChangeThree={this.handleRecordChangeThree}
            modal_siats_three={this.state.modal_siats_three}
            handleChangeFour={this.handleRecordChangeFour}
            modal_siats_four={this.state.modal_siats_four}
            nationalities={this.props.nationalities}
            visible={this.state.modalshowvisible}
            closeModal={this.closeModal}
            validator={this.local_validator}
          />
          <ModalFormFinalizate
            visible={this.state.modalFormFinalizate}
            blocked_button= {!this.state.modalFormFinalizateNotBlocked}
            closeModal={this.closeModalFormFinalizate}
            handleFinish={this.handleFinish}
          />
          {this.props.records.length > 0 ? (
            <SiatTable
              showErrorsSiat = {this.showErrorsSiat}
              form={this.state.form}
              records={this.props.records}
              edit={this.editRecord}
              show={this.showRecord}
            />
          ) : (
            ''
          )}
          {this.props.records.length > 0 ? (
            <Footer
              state={this.state.form.state}
              submit={this.handleUpload}
              cancel={this.cancelUpload}
              update={this.state.form.id > 0}
              showModalFormFinalizate={this.showModalFormFinalizate}
            />
          ) : (
            ''
          )}
        </Card>
      );
  }
}

const mapStateToProps = state => ({
  user: state.AuthReducer.user,
  token: state.AuthReducer.token,
  nationalities: state.NationalityReducer.nationalities,
  records: state.Siat5Reducer.records,
  modal_siats_two: state.Siat5Reducer.modal_siats_two,
  modal_siats_three: state.Siat5Reducer.modal_siats_three,
  modal_siats_four: state.Siat5Reducer.modal_siats_four,
  guest_record: state.Siat5Reducer.guest_record,
  delete_list: state.Siat5Reducer.delete_list,
  siat: state.Siat5Reducer.siat,
  loading: state.LoadingReducer.loading,
  redirect: state.DailyReportReducer.redirect,
  error: state.ErrorReducer.error,
  error_siat2: state.Siat2Reducer.error,
  error_siat3: state.Siat3Reducer.error,
  error_siat4: state.Siat4Reducer.error,
  error_siat: state.Siat5Reducer.error,
  next_date_daily_report: state.DailyReportReducer.next_date_daily_report

});

const mapDispatchToProps = dispatch => ({
  NationalityActions: bindActionCreators(NationalityActions, dispatch),
  Siat2Actions: bindActionCreators(Siat2Actions, dispatch),
  Siat3Actions: bindActionCreators(Siat3Actions, dispatch),
  Siat4Actions: bindActionCreators(Siat4Actions, dispatch),
  Siat5Actions: bindActionCreators(Siat5Actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Siat5New);
