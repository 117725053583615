// 2020-03-20 to 20/03/2020
export function GetFormattedDate(date){
  if (date != undefined){
    var newdate = date.slice(0, 10).split('-');
    return newdate[2] +'/'+ newdate[1] +'/'+ newdate[0];
  }
}

// 2020-03-20 10:00:00 to 20/03/2020 10:00:00
export function GetFormattedDateTime(date){
  if (date != undefined){
    var vdate = date.split(' ');
    var date = GetFormattedDate( vdate[0] )
    var hour = vdate[1]
    return date + " " + hour;
  }
}

export function GetFormattedDateForNewDate(date){
  var month = date.getMonth()+1;
  var day = date.getDate();

  var output = (day<10 ? '0' : '') + day + '/' +
      (month<10 ? '0' : '') + month + '/' +
      date.getFullYear();
  return output;
}
