export const EstablishmentContactTypes = {
  ESTABLISHMENTCONTACT_GET_REQUEST: "ESTABLISHMENTCONTACT_GET_REQUEST",
  ESTABLISHMENTCONTACT_GET_SUCCESS: "ESTABLISHMENTCONTACT_GET_SUCCESS",
  ESTABLISHMENTCONTACT_GET_FAILURE: "ESTABLISHMENTCONTACT_GET_FAILURE",

  ESTABLISHMENTCONTACT_CREATE_REQUEST: "ESTABLISHMENTCONTACT_CREATE_REQUEST",
  ESTABLISHMENTCONTACT_CREATE_SUCCESS: "ESTABLISHMENTCONTACT_CREATE_SUCCESS",
  ESTABLISHMENTCONTACT_CREATE_FAILURE: "ESTABLISHMENTCONTACT_CREATE_FAILURE",

  ESTABLISHMENTCONTACT_DELETE_REQUEST: "ESTABLISHMENTCONTACT_DELETE_REQUEST",
  ESTABLISHMENTCONTACT_DELETE_SUCCESS: "ESTABLISHMENTCONTACT_DELETE_SUCCESS",
  ESTABLISHMENTCONTACT_DELETE_FAILURE: "ESTABLISHMENTCONTACT_DELETE_FAILURE",

  ESTABLISHMENTCONTACT_EDIT_REQUEST: "ESTABLISHMENTCONTACT_EDIT_REQUEST",
  ESTABLISHMENTCONTACT_EDIT_SUCCESS: "ESTABLISHMENTCONTACT_EDIT_SUCCESS",
  ESTABLISHMENTCONTACT_EDIT_FAILURE: "ESTABLISHMENTCONTACT_EDIT_FAILURE"
};
