import React, { useRef } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { MainURL } from "../../../redux/endpoints";
import ModalFormFinalizate from "./modal_form_finalizate";
import {Menu} from 'primereact/menu';


const SiatTable = props => {
  let menu = useRef(null);

  let header = (
    <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
      SIATS I
    </div>
  );
  let footer = (props) =>{
    if(props.nextDateDailyReport != null){
      if(props.nextDateDailyReport.start_operation == true){
        return (
          <div className="p-clearfix" style={{ width: "100%" }}>
            <Button
              style={{ float: "left" }}
              label="Agregar SIAT I"
              icon="pi pi-plus"
              onClick={props.addNew}
            />
          </div>
        );
      }
    }
  }


  const actionTemplate = rowData => {
    let items = [
      {
        label: "Ver",
        icon: "pi pi-search",
        command: e => {
          props.show(rowData);
        }
      },
      {
        label: "Finalizar SIAT I",
        icon: "pi pi-check-circle",
        command: e => {
          props.showModalFormFinalizate(rowData)
        }
      },
      {
        label: "Eliminar",
        icon: "pi pi-times",
        command: e => {
          props.delete(rowData.id);
        }
      }
    ];

    let show_split_button = (rowData) => {
      if(rowData.state != 1) {
        return(
          <SplitButton
            label="Editar"
            icon="pi pi-pencil"
            className="p-button-info"
            menuStyle={{ width: '150px', marginLeft: "-2.5em" }}
            style={{textAlign:'left', marginRight: ".5em" }}
            model={items}
            onClick={e => {
              props.edit(rowData);
            }}
          />
        )
      }
      else{
        return(
          <Button
            label="Ver"
            icon="pi pi-search"
            className="p-button-info"
            style={{ marginRight: ".5em" }}
            onClick={e => {
              props.show(rowData);
            }}
          />
        )
      }
    }
    let items_mm = [
      {
        label: 'Descarga txt',
        icon: 'pi pi-file',
        command: e => {
          var elems = document.getElementsByClassName("menu_download");
          if(elems.length == 1){
            elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
          }
          window.open(MainURL + "/report/siat_one_text/" + rowData.id, "_self");

        }
      },
      {
        label: 'Descarga xlsx',
        icon: 'pi pi-file-excel',
        command: e => {
          var elems = document.getElementsByClassName("menu_download");
          if(elems.length == 1){
            elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
          }
          window.open(MainURL + "/report/siat_one/" + rowData.id, "_self");
        }
      }
    ]
    return (
      <div>
        {show_split_button(rowData)}
        <Button id={rowData.id} icon="pi pi-download" onClick={(event) => {
            var elems = document.getElementsByClassName("p-menu-overlay-visible");


            if(elems.length == 1)
            {
              if( elems[0].id != "popup_menu"+event.currentTarget.id ){
                elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
                document.getElementById("popup_menu"+event.currentTarget.id).setAttribute("class", "p-menu p-component menu_download p-menu-dynamic p-menu-overlay p-menu-overlay-visible");
              }
              else{
                elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
              }
            }
            else{
              document.getElementById("popup_menu"+event.currentTarget.id).setAttribute("class", "p-menu p-component menu_download p-menu-dynamic p-menu-overlay p-menu-overlay-visible");
            }
          }
        } aria-controls={"popup_menu"+rowData.id} aria-haspopup={true} />
        <Menu model={items_mm} popup={true} ref={menu} id={"popup_menu"+rowData.id} style={{ textAlign: "left" }}/>
      </div>
    );

  };

  return (
    <div>
      <div style={{ margin: "10px" }}>{props.children}</div>
      <ModalFormFinalizate
        form={props.form}
        visible={props.visibleModalFinish}
        closeModal={props.closeModalFinish}
        handleFinish={props.handleFinish}
      />
      <DataTable
        header={header}
        footer={footer(props)}
        value={props.siats}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        emptyMessage="No tiene siats registrados"
      >
        <Column field="date"
          header="Fecha"
          style={{textAlign:'center', width: '9%', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column
          body={rowData => {
            return rowData.state === 0?
              <div className="badge_succes">No Finalizado</div>
              : <div className="badge_danger">Finalizado</div>;
          }}
          header="Estado"
          style={{textAlign:'center', width: '10%', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column field="establishment_type_desc"
          header="Tipo"
          style={{width: '24%'}}
        />
        <Column field="category_desc"
          header="Categoría"
          style={{textAlign:'center', width: '9%', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column field="total_room"
          header="Total habitaciones"
          style={{textAlign:'center', width: '12%'}}
        />
        <Column field="total_apartment"
          header="Total dptos"
          style={{textAlign:'center', width: '8%', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column field="total_bungalow"
          header="Total cabañas"
          style={{textAlign:'center', width: '9%', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column
          header="Acciones"
          body={actionTemplate}
          style={{textAlign:'center', width: '13%', paddingRight: '0', paddingLeft: '0'}}
        />
      </DataTable>
    </div>
  );
};

export default SiatTable;
