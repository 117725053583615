export const CategoryTypes = {
  CATEGORY_GET_REQUEST: 'CATEGORY_GET_REQUEST',
  CATEGORY_GET_SUCCESS: 'CATEGORY_GET_SUCCESS',
  CATEGORY_GET_FAILURE: 'CATEGORY_GET_FAILURE',

  CATEGORY_CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
  CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
  CATEGORY_CREATE_FAILURE: 'CATEGORY_CREATE_FAILURE',

  CATEGORY_DELETE_REQUEST: 'CATEGORY_DELETE_REQUEST',
  CATEGORY_DELETE_SUCCESS: 'CATEGORY_DELETE_SUCCESS',
  CATEGORY_DELETE_FAILURE: 'CATEGORY_DELETE_FAILURE',

  CATEGORY_EDIT_REQUEST: 'CATEGORY_EDIT_REQUEST',
  CATEGORY_EDIT_SUCCESS: 'CATEGORY_EDIT_SUCCESS',
  CATEGORY_EDIT_FAILURE: 'CATEGORY_EDIT_FAILURE'
};
