import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Checkbox } from 'primereact/checkbox';

const Form = (props) => {
  const {
    id,
    name,
    position,
    send_email,
    state,
    email,
    cell_phone,
  } = props.form;

  const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateName = (name) => {
    var re = /^([a-zA-ZÀ-ÿ\u00f1\u00d1])+(\s{0,1}[a-zA-ZÀ-ÿ\u00f1\u00d1])*$/;
    return re.test(String(name).toLowerCase().trim());
  };

  const inputStyle = { margin: '5 0px' };

  const labelStyle = { fontSize: '12px', color: '#898989', display: 'block' };

  const validate = (validate_email, validate_name) => {
    if (validate_name == true && validate_email == true) {
      return true;
    }
    return false;
  };

  const footer = (
    <div>
      <Button
        label={id ? 'Actualizar' : 'Guardar'}
        icon="pi pi-check"
        // disa   bled={!validate(validateEmail(email), validateName(name))}
        onClick={props.handleSubmit}
      />
      <Button
        label="Cancelar"
        className="p-button-danger"
        icon="pi pi-times"
        onClick={props.closeModal}
      />
    </div>
  );

  return (
    <Dialog
      header={id ? 'Actualizar e-mail de contacto' : 'Nuevo e-mail de contacto'}
      visible={props.visible}
      style={{ width: '50vw' }}
      modal={true}
      footer={footer}
      onHide={props.closeModal}
    >
      <form>
        <div className="p-grid p-fluid" style={inputStyle}>
          <div className="p-col-12 p-md-6">
            <span className="p-float-label">
              <InputText
                onChange={props.handleChangeName}
                value={name}
                name="name"
                maxLength={60}
              />    
                           
              <label htmlFor="in">Nombre</label>
            </span>

            {props.validator.message('nombre', name, 'required|space_invalid|min:5')}
          
          </div>

          <div className="p-col-12 p-md-6">
            <span className="p-float-label">
              <InputText
                onChange={props.handleChangeName}
                value={position}
                name="position"
                maxLength={60}
              />
              <label htmlFor="in">Cargo</label>

            </span>
            {props.validator.message('cargo', position, 'required|space_invalid_Change|min:5')}
          </div>
        </div>

        <div className="p-grid p-fluid" style={inputStyle}>
          <div className="p-col-12 p-md-6">
            <span className="p-float-label">
              <div>
                <div className="p-grid p-fluid">
                  <div className="p-col-12 p-md-2">
                    <label style={labelStyle}>Enviar e-mail</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <Checkbox
                      style={{ marginLeft: '12px' }}
                      name="send_email"
                      onChange={props.handleChangeCheck}
                      checked={send_email}
                    ></Checkbox>
                  </div>
                </div>
              </div>
            </span>
            {props.validator.message('send_email', send_email, 'required')}
          </div>
          <div className="p-col-12 p-md-2">
            <span className="p-float-label">
              <div>
                <div className="p-grid p-fluid">
                  <div className="p-col-12 p-md-4">
                    
                    <label style={labelStyle}>Activar</label>
                  </div>
                  <div className="p-col-12 p-md-8">
                    <Checkbox
                      style={{ marginLeft: '5px' }}
                      name="state"
                      onChange={props.handleChangeCheck}
                      checked={state == 0 ? true : false}
                    ></Checkbox>
                  </div>
                </div>
              </div>
            </span>
            {props.validator.message('Activar', state, 'required')}
          </div>
        </div>

        <div className="p-grid p-fluid" style={inputStyle}>
          <div className="p-col-12 p-md-6">
            <span className="p-float-label">
              <InputText
                id="[number"
                onChange={props.handleChange}
                value={cell_phone ? cell_phone : ''}
                keyfilter="pint"
                maxLength="15"
                name="cell_phone"
              />

              <label htmlFor="in">Teléfono</label>
            </span>
            {props.validator.message("Teléfono", cell_phone, "required|zero_invalid|min:7|max:15")}
          </div>
          <div className="p-col-6 p-md-6">
            <span className="p-float-label">
              <InputText
                maxLength={50}
                onChange={props.handleChange}
                value={email}
                keyfilter="email"
                name="email"
              />
              
              <label htmlFor="in">E-mail</label>
            </span>
            {props.validator.message('email', email, 'required|email_invalid')}
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default Form;
