import React, { Component } from 'react';
import SiatForm from '../components/SiatForm';
import SimpleReactValidator from 'simple-react-validator';
import { FormMessages } from '../../../config/formTranslations';
import SiatTable from '../components/SiatTable';
import SiatShow from '../components/SiatShow';
import { Messages } from 'primereact/messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as SiatActions from '../../../redux/actions/SiatActions';
import * as DailyReportActions from "../../../redux/actions/DailyReportActions";
import { ProgressSpinner } from 'primereact/progressspinner';

import WithMessage from '../../messaheHOC';
const TableWithMessage = WithMessage(SiatTable);
class Siat extends Component {
  constructor(props) {
    super(props);
    this.validator = this.newValidator();
    this.state = this.setIinitalState();
  }
  newValidator = ()=>{
    return new SimpleReactValidator({
      className: 'p-message p-component p-message-error',
      messages: FormMessages
    });
  }
  setIinitalState = () => {
    return {
      formVisible: false,
      showVisible: false,
      modalFormFinalizate: false,
      form: {
        id: '',
        address: '',
        category: '',
        description: '',
        category_type: '',
        total_room: 0,
        total_apartment: 0,
        total_bungalow: 0,
        reg_mod: '',
        mod_desc: '',
        owner: '',
        branch: '',
        establishment_name: '',
        nit: ''
      }
    };
  };

  async componentDidMount() {
    await this.props.DailyReportActions.getDailyReports(this.props.token);
    await this.props.SiatActions.getSiats(this.props.token);
  }

  handleChange = event => {
    const { name } = event.target;
    let value = event.target.value;
    let input_type = event.target.dataset.type;

    if (input_type === 'number' && value.includes('-')) {
      value = value.split('-').join('');
    }
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  handleChangeSelect = async(event)  => {
    const { name, value } = event.target;

    if (name == "reg_mod" && value != 3){
      await this.setState({
        form: {
          ...this.state.form,
          mod_desc: ""
        }
      });
    }

    if (name == "category_type" && value != 10){
      await this.setState({
        form: {
          ...this.state.form,
          description: ""
        }
      })
    }

    await this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  showModalFormFinalizate = siat => {
    this.setState({
      // form: { ...siat },
      form: siat,
      modalFormFinalizate: true
    });
  };

  restartForm=()=>{
    this.setState({
      form: {
        id: '',
        address: '',
        category: '',
        description: '',
        category_type: '',
        total_room: 0,
        total_apartment: 0,
        total_bungalow: 0,
        reg_mod: '',
        mod_desc: '',
        owner: '',
        branch: '',
        establishment_name: '',
        nit: ''
      }
    });
  }

  closeModalFormFinalizate = () => {
    this.restartForm()
    this.setState({
      modalFormFinalizate: false,
      form: {
        id: '',
        address: '',
        category: '',
        description: '',
        category_type: '',
        total_room: 0,
        total_apartment: 0,
        total_bungalow: 0,
        reg_mod: '',
        mod_desc: '',
        owner: '',
        branch: '',
        establishment_name: '',
        nit: ''
      }
    });
  };

  closeModal = async() => {
    await this.restartForm()
    await this.setState({
      formVisible: false,
      showVisible: false,
      modalFormFinalizate: false
    });
    this.validator.hideMessages();
  };

  handleFinish = async rowData => {
    if (this.validator.allValid()) {
      await this.setState({
        form: {
          ...this.state.form,
          state: 1
        }
      });

      let siat = this.state.form;
      siat.establishment_id = this.props.user.establishment_id;
      if (siat.id > 0) {
        this.props.SiatActions.editSiat(this.props.token, siat);
      } else {
        this.props.SiatActions.createSiat(this.props.token, siat);
      }
      this.setState({
        ...this.state,
        formVisible: false,
        modalFormFinalizate: false,
        form: this.setIinitalState().form
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleSubmit = () => {
    if (this.validator.allValid()) {
      let siat = this.state.form;
      siat.establishment_id = this.props.user.establishment_id;
      if (siat.id > 0) {
        this.props.SiatActions.editSiat(this.props.token, siat);
      } else {
        this.props.SiatActions.createSiat(this.props.token, siat);
      }
      this.setState({
        ...this.state,
        formVisible: false,
        form: this.setIinitalState().form
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  addNew = () => {
    this.setState({
      formVisible: true
    });
  };

  deleteSiat = siat_id => {
    if (window.confirm('¿Estas seguro de eliminar este siat?'))
      this.props.SiatActions.deleteSiat(this.props.token, siat_id);
  };

  editSiat = siat => {
    this.setState({
      form: { ...siat },
      formVisible: true
    });
  };

  showSiat = siat => {
    this.setState({
      form: { ...siat },
      showVisible: true
    });
  };

  render() {
    this.validator.purgeFields();
    return (
      <div>
        <Messages ref={el => (this.messages = el)} />
        <SiatForm
          handleSubmit={this.handleSubmit}
          validator={this.validator}
          visible={this.state.formVisible}
          handleChange={this.handleChange}
          handleChangeSelect={this.handleChangeSelect}
          form={this.state.form}
          closeModal={this.closeModal}
        />

        <SiatShow
          visible={this.state.showVisible}
          form={this.state.form}
          close={this.closeModal}
        />

        <TableWithMessage
          form={this.state.form}
          loading={this.props.loading}
          success={this.props.success}
          error={this.props.error}
          show={this.showSiat}
          edit={this.editSiat}
          delete={this.deleteSiat}
          addNew={this.addNew}
          siats={this.props.siats}
          visibleModalFinish={this.state.modalFormFinalizate}
          closeModalFinish={this.closeModalFormFinalizate}
          handleFinish={this.handleFinish}
          showModalFormFinalizate={this.showModalFormFinalizate}
          closeModalFormFinalizate={this.closeModalFormFinalizate}
          nextDateDailyReport={this.props.next_date_daily_report}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  user: state.AuthReducer.user,
  siats: state.SiatReducer.siats,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error,
  success: state.LoadingReducer.success,
  next_date_daily_report: state.DailyReportReducer.next_date_daily_report,
});

const mapDispatchToProps = dispatch => ({
  SiatActions: bindActionCreators(SiatActions, dispatch),
  DailyReportActions: bindActionCreators(DailyReportActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Siat);
