import React from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { room_types, agency_types, booking_types } from '../../config/models';
import {GetFormattedDate} from '../../helper_java/date_format';
import { GetFullName } from '../../helper_java/person_helper'

const Show = props => {
  const renderHeader = (data)=> {
    if (data != undefined){
      return (
        <div class="p-col-11 p-dialog-title" style={{display: 'inline-block', marginBottom: '-20px', marginTop: '-20px' }}>
          Parte - {GetFormattedDate(data.date)}
        </div>
      );
    }
  }

  const total_value = (data) => {
    if (data != undefined){
      return "Total registros: " + data.length
    }
  }

  return (
    <Dialog
      header={renderHeader(props.data)}
      visible={props.visible}
      style={{ width: '70vw' }}
      modal={true}
      onHide={props.close}
    >
      <DataTable
        emptyMessage="El parte no tiene registros" 
        value={props.data.guest_records}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        scrollable={true}
        scrollHeight="400px"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
        currentPageReportTemplate={total_value(props.data.guest_records)}
      >
        <Column
          header="Nro."
          body={(rowData, value) => {
            return value.rowIndex+1
          }}
          style={{textAlign:'center', width: '4em', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column
          header="Tipo de movimiento"
          body={guest => {
            if (guest.m_type == 'ingreso') {
              return <div className="badge_entry">Ingreso</div>;
            }
            if (guest.m_type == 'permanencia') {
              return <div className="badge_permanence">Permanencia</div>;
            }
            if (guest.m_type == 'egreso') {
              return <div className="badge_exit">Salida</div>;
            }
            if (guest.m_type == 'day_use') {
              return <div className="badge_day_use">Day Use</div>;
            }
          }}
          style={{textAlign:'center', width: '8em', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column
          header="Ingreso"
          body={rowData => {
            return GetFormattedDate(rowData.arrival);
          }}
          style={{textAlign:'center', width: '7em', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column
          header="Salida"
          body={rowData => {
            return GetFormattedDate(rowData.departure);
          }}
          style={{textAlign:'center', width: '7em', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column header="Origen"
          body={rowData => {
            return rowData.person.country.name;
          }}
          style={{textAlign:'center', width: '9em', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column header="Nombre Completo"
          body={rowData => {
            return GetFullName(rowData.person.name, rowData.person.last_name, rowData.person.middle_name)
          }}
        />
        <Column header="Nro. Documento"
          body={rowData => {
            return rowData.person.document;
          }}
          style={{textAlign:'center', width: '10em', paddingRight: '0', paddingLeft: '0'}}
        />

        <Column field="room"
          header="Habitación"
          style={{textAlign:'center', width: '7em', paddingRight: '0', paddingLeft: '0'}}
        />

        <Column header="Nacionalidad"
          body={rowData => {
            return rowData.person.nationality.name;
          }}
          style={{textAlign:'center',  width: '10em', paddingRight: '0', paddingLeft: '0'}}
        />
      </DataTable>
    </Dialog>
  );
};

export default Show;
