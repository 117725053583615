export const room_types = [
  {
    value: 1,
    label: "Habitación"
  },
  {
    value: 2,
    label: "Departamento"
  },
  {
    value: 3,
    label: "Cabaña o bungalows"
  },
  {
    value: 4,
    label: "Otros"
  }
];

export const fiscal_document_types = [
  {
    value: 1,
    label: "Factura"
  },
  {
    value: 2,
    label: "Nota fiscal"
  }
];

export const payment_types = [
  {
    value: 1,
    label: "Efectivo"
  },
  {
    value: 2,
    label: "Tarjeta electrónica (crédito, débito o prepago)"
  },
  {
    value: 3,
    label: "Cheque"
  },
  {
    value: 4,
    label: "Transferencia bancaria"
  },
  {
    value: 5,
    label: "Depósito bancario"
  },
  {
    value: 6,
    label: "Cuentas por cobrar"
  },
  {
    value: 7,
    label: "Otros"
  }
];

export const siat4_payment_types = [
  {
    value: 1,
    label: "Efectivo"
  },
  {
    value: 2,
    label: "Tarjeta electrónica (crédito, débito o prepago)"
  },
  {
    value: 3,
    label: "Cheque"
  },
  {
    value: 4,
    label: "Transferencia bancaria"
  },
  {
    value: 5,
    label: "Depósito bancario"
  },
  {
    value: 6,
    label: "Otros"
  }
];

export const agency_types = [
  {
    value: 0,
    label: "No corresponde"
  },
  {
    value: 1,
    label: "Comisionista"
  },
  {
    value: 2,
    label: "Cliente"
  }
];

export const booking_types = [
  {
    value: 0,
    label: "No corresponde"
  },
  {
    value: 1,
    label: "Agencia de viajes con residencia en el país"
  },
  {
    value: 2,
    label: "Agencia de viajes extranjera sin residencia en el país"
  },
  {
    value: 3,
    label: "Internet u otra comunicación electrónica"
  },
  {
    value: 4,
    label: "Personal"
  }
];

export const establishment_categories = [
  {
    value: 1,
    label: "1 estrella"
  },
  {
    value: 2,
    label: "2 estrellas"
  },
  {
    value: 3,
    label: "3 estrellas"
  },
  {
    value: 4,
    label: "4 estrellas"
  },
  {
    value: 5,
    label: "5 estrellas"
  },
  {
    value: 6,
    label: "Clase A"
  },
  {
    value: 7,
    label: "Clase B"
  },
  {
    value: 8,
    label: "Categoría única"
  },
  {
    value: 9,
    label: "Sin Categoría"
  }
];

export const register_models = [
  {
    value: 1,
    label: "Tarjetas de registro"
  },
  {
    value: 2,
    label: "Libros de registro"
  },
  {
    value: 3,
    label: "Otro sistema de registro"
  }
];

export const establishment_types = [
  {
    value: 1,
    label: "Hotel"
  },
  {
    value: 2,
    label: "Apart Hotel"
  },
  {
    value: 3,
    label: "Hotel Boutique"
  },
  {
    value: 4,
    label: "Resort"
  },
  {
    value: 5,
    label: "Hostal"
  },
  {
    value: 6,
    label: "Residencial"
  },
  {
    value: 7,
    label: "Alojamiento"
  },
  {
    value: 8,
    label: "Hotel Aeropuerto"
  },
  {
    value: 9,
    label: "Casa de huéspedes"
  },
  {
    value: 10,
    label: "Otros de pernocte o alojamiento temporal"
  }
];

export const observations = [
  {
    value: "Anticipo",
    label: "Anticipo"
  },
  {
    value: "Huésped disconforme",
    label: "Huésped disconforme"
  }
];
