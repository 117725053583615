import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { MainURL } from "../../../redux/endpoints";
import CommunicationShow from '../components/CommunicationShow';

const CommunicationTable = props => {
  let header = (
    <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
      Comunicados
    </div>
  );

  const actionTemplate = rowData => {
    return (
      <div>
        <Button
          label="Ver"
          icon="pi pi-search"
          className="p-button-info"
          style={{ marginRight: ".5em" }}
          onClick={e => {
            props.showModal(rowData)
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <CommunicationShow
        visible={props.showVisible}
        communication={props.communication}
        close={props.closeModal}
      />
      <DataTable
        header={header}
        value={props.communications}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        emptyMessage="No tiene comunicados"
      >
        <Column field="title" header="Título" />
        <Column field="date"
          header="Fecha y Hora"
          style={{textAlign:'center', width: '16%', paddingRight: '0', paddingLeft: '0'}}
        />

        <Column
          header="Acciones"
          body={actionTemplate}
          style={{textAlign:'center', width: '12%', paddingRight: '0', paddingLeft: '0'}}
        />
      </DataTable>
    </div>
  );
};

export default CommunicationTable;
