import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as AuthActions from '../../redux/actions/AuthActions';
import * as DashboardActions from '../../redux/actions/DashboardActions';
import * as DailyReportActions from '../../redux/actions/DailyReportActions';
import { ProgressSpinner } from 'primereact/progressspinner';
import HomeView from './components/Home';
import EstablishmentInfo from './components/EstablishmentInfo';
import { Card } from 'primereact/card';
import Logo from '../../config/sidebar/logo.svg';

class Home extends Component {
  componentDidMount = async () => {
    await this.props.DailyReportActions.getDailyReports(this.props.token);
    await this.props.DashboardActions.getDashboardInformation(this.props.token);
  };

  render() {
    if (this.props.error === 'Unauthorized') {
      return <Redirect to="/login" />;
    }
    const header = (
      <div style={{ paddingTop: '35px' }}>
        <img alt="Card" src={Logo} style={{ width: '100%', height: '20vh' }} />
      </div>
    );
    return (
      <div>
        {this.props.loading === true ? (
          <div className="p-grid p-fluid p-justify-center">
            <ProgressSpinner
              style={{ width: '20%', height: '20%', marginTop: '10%' }}
              animationDuration=".5s"
            />
          </div>
        ) : (
          <Card header={header}>
            <h1
              style={{
                textAlign: 'center',
                margin: '15px',
                fontWeight: 'bold'
              }}
            >
              Bienvenido a la Cámara Hotelera de Santa Cruz
            </h1>
            <p
              style={{
                margin: '0px 10px',
                fontSize: '20px',
                fontWeight: 'bold'
              }}
            >
              Fecha: {new Date().toLocaleDateString('es')}
            </p>
            <div className="p-grid">
              <div className="p-col">
                <h2 style={{ margin: '10px 0px', fontWeight: 'bold' }}>
                  Información del establecimiento
                </h2>
                <EstablishmentInfo
                  information={this.props.information.establishment}
                />
              </div>
              <div className="p-col" style={{ margin: '0px 30px' }}>
                <h2 style={{ margin: '10px 0px', fontWeight: 'bold' }}>
                  Noticias
                </h2>
                <HomeView daily_check={this.props.information.daily_check} />
              </div>
            </div>
          </Card>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  information: state.DashboardReducer.information,
  user: state.AuthReducer.user,
  token: state.AuthReducer.token,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error
});

const mapDispatchToProps = dispatch => ({
  DailyReportActions: bindActionCreators(DailyReportActions, dispatch),
  DashboardActions: bindActionCreators(DashboardActions, dispatch),
  AuthActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
