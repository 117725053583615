export const EmployeTypes = {
  EMPLOYE_GET_REQUEST: "EMPLOYE_GET_REQUEST",
  EMPLOYE_GET_SUCCESS: "EMPLOYE_GET_SUCCESS",
  EMPLOYE_GET_FAILURE: "EMPLOYE_GET_FAILURE",

  EMPLOYE_CREATE_REQUEST: "EMPLOYE_CREATE_REQUEST",
  EMPLOYE_CREATE_SUCCESS: "EMPLOYE_CREATE_SUCCESS",
  EMPLOYE_CREATE_FAILURE: "EMPLOYE_CREATE_FAILURE",

  EMPLOYE_DELETE_REQUEST: "EMPLOYE_DELETE_REQUEST",
  EMPLOYE_DELETE_SUCCESS: "EMPLOYE_DELETE_SUCCESS",
  EMPLOYE_DELETE_FAILURE: "EMPLOYE_DELETE_FAILURE",

  EMPLOYE_EDIT_REQUEST: "EMPLOYE_EDIT_REQUEST",
  EMPLOYE_EDIT_SUCCESS: "EMPLOYE_EDIT_SUCCESS",
  EMPLOYE_EDIT_FAILURE: "EMPLOYE_EDIT_FAILURE"
};
