import { EstablishmentEmailTypes } from "../types";

const initialState = {
  establishment_emails: [],
  redirect: false,
  error: null
};

export default function EstablishmentEmailReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_GET_SUCCESS:
      return {
        ...state,
        establishment_emails: action.payload.establishment_emails,
        redirect: true,
        error: null
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        establishment_emails: []
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        establishment_emails: state.establishment_emails.concat(
          action.payload.establishmentEmail
        )
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true,
        establishment_emails: state.establishment_emails.filter(
          establishment_email => establishment_email.id !== action.payload.id
        )
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_EDIT_SUCCESS:
      let updated_establishment_email = action.payload.establishmentEmail;
      return {
        ...state,
        redirect: true,
        establishment_emails: state.establishment_emails.map(
          establishment_email => {
            if (establishment_email.id === updated_establishment_email.id) {
              return updated_establishment_email;
            } else {
              return establishment_email;
            }
          }
        )
      };

    case EstablishmentEmailTypes.ESTABLISHMENTEMAIL_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
