import React, { Component } from 'react';
import RoomForm from '../components/RoomForm';
import SimpleReactValidator from 'simple-react-validator';
import { FormMessages } from '../../../config/formTranslations';
import RoomTable from '../components/RoomTable';
import { Messages } from 'primereact/messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as RoomActions from '../../../redux/actions/RoomActions';
import { ProgressSpinner } from 'primereact/progressspinner';
import WithMessage from '../../messaheHOC';
const TableWithMessage = WithMessage(RoomTable);

class Room extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: 'p-message p-component p-message-error',
      messages: FormMessages,
      validators: {
        zero_invalid: {  // name the rule
          message: 'La cantidad no es válida.',
          rule: (val, params, validator) => {
              return validator.helpers.testRegex(parseInt(val),/^[1-9][0-9]*$/)
          }
        }
      }
    });
    this.state = this.setIinitalState();
  }
  
  showError = () => {
    if (this.props.room_error) {
      let error = this.props.room_error.data
        ? this.props.room_error.data.name
        : this.props.room_error;
      this.messages.show({
        severity: "error",
        summary: "",
        detail: error,
        closable: false,
      });
    }
  };

  setIinitalState = () => {
    return {
      formVisible: false,
      form: {
        id: '',
        quantity: '',
        quantitybeds: '',
        name: ''
      }
    };
  };

  componentDidMount() {
    this.props.RoomActions.getRooms(this.props.token);
  }

  get_total_quantity = () => {
    var total_quantity = 0;
    this.props.rooms.forEach(function(item) {
      total_quantity += item.quantity;
    });
    return total_quantity;
  };
  get_total_quantitybeds = () => {
    var total_quantitybeds = 0;
    this.props.rooms.forEach(function(item) {
      total_quantitybeds += item.quantitybeds;
    });
    return total_quantitybeds;
  };
  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };
  closeModal = () => {
    this.validator.hideMessages();
    this.setState({
      formVisible: false,
      showVisible: false,
      form: {
        habitacion: '',
        room: ''
      }
    });
  };
  handleSubmit = async() => {
    if (this.validator.allValid()) {
      let room = this.state.form;
      room.establishment_id = this.props.user.establishment_id;
      if (room.id > 0) {
        await this.props.RoomActions.editRoom(this.props.token, room);
        this.showError();
      } else {
        await this.props.RoomActions.createRoom(this.props.token, room);
        this.showError();
      }
      this.setState({
        ...this.state,
        formVisible: false,
        form: this.setIinitalState().form
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  addNew = () => {
    this.setState({
      formVisible: true
    });
  };

  deleteRoom = room_id => {
    if (window.confirm('¿Está seguro de eliminar esta habitación?'))
      this.props.RoomActions.deleteRoom(this.props.token, room_id);
  };

  editRoom = room => {
    this.setState({
      form: { ...room },
      formVisible: true
    });
  };

  render() {
   this.get_total_quantity();
   this.get_total_quantitybeds();
    if (this.props.loading) {
      return (
        <div className="p-grid p-fluid p-justify-center">
          <ProgressSpinner
            style={{ width: '20%', height: '20%', marginTop: '10%' }}
            animationDuration=".5s"
          />
        </div>
      );
    }
    return (
      <div>
        <Messages ref={el => (this.messages = el)} />
        <RoomForm
          handleSubmit={this.handleSubmit}
          validator={this.validator}
          visible={this.state.formVisible}
          handleChange={this.handleChange}
          form={this.state.form}
          closeModal={this.closeModal}
        />
        <TableWithMessage
          get_total_quantity={this.get_total_quantity}
          get_total_quantitybeds={this.get_total_quantitybeds}     
          loading={this.props.loading}
          success={this.props.success}
          error={this.props.error}
          edit={this.editRoom}
          delete={this.deleteRoom}
          addNew={this.addNew}
          rooms={this.props.rooms}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  user: state.AuthReducer.user,
  rooms: state.RoomReducer.rooms,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error,
  success: state.LoadingReducer.success,
  room_error: state.RoomReducer.error
});

const mapDispatchToProps = dispatch => ({
  RoomActions: bindActionCreators(RoomActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);
