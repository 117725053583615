const initialState = {
  loading: false,
  success: ""
};

export default function loadingReducer(state = initialState, action) {
  let type = action.type.split("_").pop();
  switch (type) {
    case "REQUEST":
      return {
        ...state,
        loading: true
      };

    case "SUCCESS":
      console.log(action);
      let model = action.type.split("_")[0];
      let http = action.type.split("_")[1];
      if (
        (model == "SIAT2" ||
          model == "SIAT3" ||
          model == "SIAT4" ||
          model == "DAILY") &&
        http == "GET"
      ) {
        return {
          ...state,
          loading: false
        };
      } else {
        return {
          ...state,
          success: action.payload ? action.payload.success : "",
          loading: false
        };
      }
    case "FAILURE":
      return {
        ...state,
        success: "",
        loading: false
      };

    default:
      return state;
  }
}
