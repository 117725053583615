import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {GetFormattedDate} from '../../helper_java/date_format';

const RoomTable = props => {
  let footerGroup = (
    <ColumnGroup>
      <Row>
          <Column />
          <Column footer={"Total: " + props.get_total_quantity()} />
          <Column footer={"Total: " + props.get_total_quantitybeds()} />
          <Column />
          <Column />
          
      </Row>
   </ColumnGroup>
 )

  let header = (
    <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
      Habitaciones
    </div>
  );
  let footer = (
    <div className="p-clearfix" style={{ width: "100%" }}>
      <Button
        style={{ float: "left" }}
        label="Agregar habitación"
        icon="pi pi-plus"
        onClick={props.addNew}
      />
    </div>
  );
  const actionTemplate = rowData => {
    let items = [
      {
        label: "Eliminar",
        icon: "pi pi-times",
        command: e => {
          props.delete(rowData.id);
        }
      }
    ];
    return (
      <SplitButton
        label="Editar"
        icon="pi pi-pencil"
        className="p-button-info"
        menuStyle={{ width: '100px'}}
        style={{textAlign:'left'}}
        model={items}
        onClick={e => {
          props.edit(rowData);
        }}
      />
    );
  };
  return (
    <div>
      <h4>*En esta pantalla visualizará la oferta de habitaciones correspondiente al mes en curso, todo lo gestionado no afectará a meses anteriores.</h4>
      <div style={{ margin: "10px" }}>{props.children}</div>
      <DataTable
        footerColumnGroup={footerGroup}
        header={header}
        footer={footer}
        value={props.rooms}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        emptyMessage="No tiene habitaciones registradas"
      >
        <Column field="name"
          header="Descripción"

        />
        <Column field="quantity"
          header="Cantidad de habitaciones"
          style={{textAlign:'center', width: '20%', paddingRight: '0', paddingLeft: '0'}}
        />

        <Column field="quantitybeds"
          header="Cantidad de camas"
          style={{textAlign:'center', width: '20%', paddingRight: '0', paddingLeft: '0'}}
        />

        <Column
          header="Fecha de Creación"
          body={rowData => { return GetFormattedDate(rowData.created_at);}}
          style={{textAlign:'center', width: '20%', paddingRight: '0', paddingLeft: '0'}}
        />

        <Column
          header="Acciones"
          body={actionTemplate}
          style={{textAlign:'center', width: '16%', paddingRight: '0', paddingLeft: '0'}}
        />
      </DataTable>
    </div>
  );
};

export default RoomTable;
