export const SiatTypes = {
  SIAT_GET_REQUEST: "SIAT_GET_REQUEST",
  SIAT_GET_SUCCESS: "SIAT_GET_SUCCESS",
  SIAT_GET_FAILURE: "SIAT_GET_FAILURE",

  SIAT_CREATE_REQUEST: "SIAT_CREATE_REQUEST",
  SIAT_CREATE_SUCCESS: "SIAT_CREATE_SUCCESS",
  SIAT_CREATE_FAILURE: "SIAT_CREATE_FAILURE",

  SIAT_DELETE_REQUEST: "SIAT_DELETE_REQUEST",
  SIAT_DELETE_SUCCESS: "SIAT_DELETE_SUCCESS",
  SIAT_DELETE_FAILURE: "SIAT_DELETE_FAILURE",

  SIAT_EDIT_REQUEST: "SIAT_EDIT_REQUEST",
  SIAT_EDIT_SUCCESS: "SIAT_EDIT_SUCCESS",
  SIAT_EDIT_FAILURE: "SIAT_EDIT_FAILURE"
};
