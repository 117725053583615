import { SiatTypes } from "../types";
import { SiatEndpoints, MainURL } from "../endpoints";
import axios from "axios";

const siatGetBegin = () => ({
  type: SiatTypes.SIAT_GET_REQUEST
});

const siatGetSuccess = siats => ({
  type: SiatTypes.SIAT_GET_SUCCESS,
  payload: { siats }
});

const siatGetFailure = error => ({
  type: SiatTypes.SIAT_GET_FAILURE,
  payload: { error }
});

export const getSiats = token => {
  return dispatch => {
    dispatch(siatGetBegin());
    return axios
      .get(MainURL + SiatEndpoints.SIAT_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siatGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(siatGetFailure(error.response));
      });
  };
};

const siatCreateBegin = () => ({
  type: SiatTypes.SIAT_CREATE_REQUEST
});

const siatCreateSuccess = siat => ({
  type: SiatTypes.SIAT_CREATE_SUCCESS,
  payload: { siat, success: "SIAT creado exitosamente" }
});

const siatCreateFailure = error => ({
  type: SiatTypes.SIAT_CREATE_FAILURE,
  payload: { error }
});

export const createSiat = (token, data) => {
  return dispatch => {
    dispatch(siatCreateBegin());
    return axios
      .post(MainURL + SiatEndpoints.SIAT_ENDPOINT, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siatCreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(siatCreateFailure(error.response));
      });
  };
};

const siatDeleteBegin = () => ({
  type: SiatTypes.SIAT_DELETE_REQUEST
});

const siatDeleteSuccess = siat_id => ({
  type: SiatTypes.SIAT_DELETE_SUCCESS,
  payload: { siat_id, success: "SIAT eliminado exitosamente" }
});

const siatDeleteFailure = error => ({
  type: SiatTypes.SIAT_DELETE_FAILURE,
  payload: { error }
});

export const deleteSiat = (token, SIAT_ID) => {
  return dispatch => {
    dispatch(siatDeleteBegin());
    return axios
      .delete(MainURL + SiatEndpoints.SIAT_ENDPOINT + `/${SIAT_ID}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siatDeleteSuccess(SIAT_ID));
      })
      .catch(error => {
        dispatch(siatDeleteFailure(error.response));
      });
  };
};

const siatEditBegin = () => ({
  type: SiatTypes.SIAT_EDIT_REQUEST
});

const siatEditSuccess = siat => ({
  type: SiatTypes.SIAT_EDIT_SUCCESS,
  payload: { siat, success: "SIAT actualizado exitosamente" }
});

const siatEditFailure = error => ({
  type: SiatTypes.SIAT_EDIT_FAILURE,
  payload: { error }
});

export const editSiat = (token, data) => {
  return dispatch => {
    dispatch(siatEditBegin());
    return axios
      .put(MainURL + SiatEndpoints.SIAT_ENDPOINT + `/${data.id}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siatEditSuccess(response.data));
      })
      .catch(error => {
        dispatch(siatEditFailure(error.response));
      });
  };
};
