import React from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import ModalImage from 'react-modal-image';
import { MainURL } from '../../redux/endpoints/index';
import { GetFormattedDate } from '../helper_java/date_format'
import { GetFullNameValidated } from '../helper_java/person_helper'
import './unpleasant.scss';

const Form = props => {
  const { id, guest_record, daily_report, description, image } = props.form;
  const dropdownLabelStyle = {
    margin: '0px',
    color: '#898989',
    fontSize: '12px'
  };
  let people = [];
  if (daily_report && props.daily_report_guest_records) {
    people = props.daily_report_guest_records.map(function(guest_record) {
      return {
        name: GetFullNameValidated(guest_record.person.name, guest_record.person.last_name, guest_record.person.middle_name),
        id: guest_record.id,
        person_id: guest_record.person.id
      };
    });
  }

  let dailyReportsFiltered = [];
  props.dailyReports.forEach(function(daily_report) {
    if (daily_report.state === 'finalizado') {
      dailyReportsFiltered.push(daily_report);
    }
  });

  return (
    <Dialog
      header={id ? 'Actualizar huésped no grato' : 'Nuevo huésped no grato'}
      visible={props.visible}
      style={{ width: '50vw' }}
      modal={true}
      onHide={props.closeModal}
    >
      <Fieldset legend="Nota Importante" toggleable={true}>
        <h2 style={{ fontSize: '14px', color: '#007ad9' }}>
          Sólo se puede agregar Huéspedes no gratos, a Huéspedes de partes que
          se encuentren en estado "FINALIZADO". Los partes "No Finalizados" o
          los partes "En Espera", no forman parte del siguiente listado de
          partes.
        </h2>
      </Fieldset>
      <form onSubmit={props.handleSubmit}>
        <div className="p-grid p-fluid">
          <div className="p-col-12 p-md-6">
            <p style={dropdownLabelStyle}>Parte</p>
            <Dropdown
              onChange={props.handleChange}
              filter={true}
              name="daily_report"
              optionLabel="date"
              options={dailyReportsFiltered}
              value={daily_report}
              itemTemplate={(daily_report) => (GetFormattedDate(daily_report.date))}
            />
            {props.validator.message('Parte', daily_report, 'required')}
          </div>
          {daily_report ? (
            <div className="p-col-12 p-md-6">
              <p style={dropdownLabelStyle}>Huésped</p>
              <Dropdown
                onChange={props.handleChange}
                filter={true}
                name="guest_record"
                optionLabel="name"
                value={guest_record}
                options={people}
              />
              {props.validator.message('Huésped', guest_record, 'required')}
            </div>
          ) : (
            ''
          )}
          <div className="p-col-12 p-md-12" style={{ marginTop: '15px' }}>
            <p style={dropdownLabelStyle}>Descripción</p>
            {/* <label htmlFor="in">Descripción</label> */}
            <span className="p-float-label">
              <InputTextarea
                rows={5}
                cols={30}
                onChange={props.handleChange}
                value={description}
                name="description"
                maxLength={500}
              />
            </span>
            {props.validator.message('descripción', description, 'required')}
          </div>
          <div className="p-col-12 p-md-6 max-width-input" style={{ marginTop: '15px' }}>
            {((image !== '') && (image !== null)) ? (
                            <div>
                                <p style={dropdownLabelStyle}>Actual Imagen en Sistema</p>
                                <ModalImage
                                  className="p-datatable-img-preview"
                                  small={MainURL + image}
                                  large={MainURL + image}
                                  hideDownload={true}
                                />
                            </div>)
                          : ('')}
            <p style={dropdownLabelStyle}>Subir una imagen de Referencia</p>
            <input
              id="unpleasant_image_id"
              type="file"
              name="image"
              accept="image/*"
              onChange={props.handleImageChange}
            />
            {props.validator.message('image', props.selectedImage, 'validImage')}
          </div>

          <div className="p-col-12 p-md-12">
            <Button
              style={{ width: '120px', marginRight: '15px' }}
              label={id ? 'Actualizar' : 'Guardar'}
              icon="pi pi-check"
              type="submit"
            />
            <Button
              style={{ width: '120px' }}
              type="button"
              label="Cancelar"
              className="p-button-danger"
              icon="pi pi-times"
              onClick={props.closeModal}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default Form;
