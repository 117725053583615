import { SIAT2Types } from "../types";
import { Siat2Endpoints, MainURL } from "../endpoints";
import axios from "axios";

const siat2GetBegin = () => ({
  type: SIAT2Types.SIAT2_GET_REQUEST
});

const siat2GetSuccess = siat2s => ({
  type: SIAT2Types.SIAT2_GET_SUCCESS,
  payload: { siat2s }
});

const siat2GetFailure = error => ({
  type: SIAT2Types.SIAT2_GET_FAILURE,
  payload: { error }
});

export const getsiats = token => {
  return dispatch => {
    dispatch(siat2GetBegin());
    return axios
      .get(MainURL + Siat2Endpoints.SIAT2_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat2GetSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat2GetFailure(error.response));
      });
  };
};

const siat2GetByIdBegin = () => ({
  type: SIAT2Types.SIAT2_GETBYID_REQUEST
});

const siat2GetByIdSuccess = siat => ({
  type: SIAT2Types.SIAT2_GETBYID_SUCCESS,
  payload: { siat }
});

const siat2GetByIdFailure = error => ({
  type: SIAT2Types.SIAT2_GETBYID_FAILURE,
  payload: { error }
});

export const getsiatById = (token, siat_id) => {
  return dispatch => {
    dispatch(siat2GetByIdBegin());
    return axios
      .get(`${MainURL}${Siat2Endpoints.SIAT2_ENDPOINT}/${siat_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat2GetByIdSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat2GetByIdFailure(error.response));
      });
  };
};

const siat2CreateBegin = () => ({
  type: SIAT2Types.SIAT2_CREATE_REQUEST
});

const siat2CreateSuccess = siat2 => ({
  type: SIAT2Types.SIAT2_CREATE_SUCCESS,
  payload: { siat2, success: "SIAT II creado exitosamente" }
});

const siat2CreateFailure = error => ({
  type: SIAT2Types.SIAT2_CREATE_FAILURE,
  payload: { error }
});

export const createsiat2 = (token, data) => {
  return dispatch => {
    dispatch(siat2CreateBegin());
    return axios
      .post(MainURL + Siat2Endpoints.SIAT2_ENDPOINT, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat2CreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat2CreateFailure(error.response));
      });
  };
};

const siat2DeleteBegin = () => ({
  type: SIAT2Types.SIAT2_DELETE_REQUEST
});

const siat2DeleteSuccess = SIAT2_id => ({
  type: SIAT2Types.SIAT2_DELETE_SUCCESS,
  payload: { SIAT2_id, success: "SIAT II eliminado exitosamente" }
});

const siat2DeleteFailure = error => ({
  type: SIAT2Types.SIAT2_DELETE_FAILURE,
  payload: { error }
});

export const deletesiat2 = (token, SIAT2_id) => {
  return dispatch => {
    dispatch(siat2DeleteBegin());
    return axios
      .delete(MainURL + Siat2Endpoints.SIAT2_ENDPOINT + `/${SIAT2_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat2DeleteSuccess(SIAT2_id));
      })
      .catch(error => {
        dispatch(siat2DeleteFailure(error.response));
      });
  };
};

const siat2EditBegin = () => ({
  type: SIAT2Types.SIAT2_EDIT_REQUEST
});

const siat2EditSuccess = siat2 => ({
  type: SIAT2Types.SIAT2_EDIT_SUCCESS,
  payload: { siat2, success: "SIAT II actualizado exitosamente" }
});

const siat2EditFailure = error => ({
  type: SIAT2Types.SIAT2_EDIT_FAILURE,
  payload: { error }
});

export const editsiat2 = (token, data) => {
  return dispatch => {
    dispatch(siat2EditBegin());
    return axios
      .put(MainURL + Siat2Endpoints.SIAT2_ENDPOINT + `/${data.id}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat2EditSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat2EditFailure(error.response));
      });
  };
};

export const setInitialState = () => {
  return dispatch => {
    dispatch({
      type: SIAT2Types.SIAT2_SETINITIAL_SUCCESS,
      payload: null
    });
  };
};

export const createRecord = guest_record => {
  return dispatch => {
    dispatch({
      type: SIAT2Types.SIAT2_LOCALCREATE_SUCCESS,
      payload: { guest_record }
    });
  };
};

const siat2editRecortBegin = () => ({
  type: SIAT2Types.SIAT2_EDIT_RECORD_REQUEST
});

const siat2editRecortSuccess = siat => ({
  type: SIAT2Types.SIAT2_EDIT_RECORD_SUCCESS,
  payload: { siat }
});

const siat2editRecortFailure = error => ({
  type: SIAT2Types.SIAT2_EDIT_RECORD_FAILURE,
  payload: { error }
});

export const editRecord = (token, data) => {
  return dispatch => {
    dispatch(siat2editRecortSuccess());
    return axios
    .put(MainURL + Siat2Endpoints.SIAT2RECORD_ENDPOINT + `/${data.id}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat2editRecortSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat2editRecortFailure(error.response));
      });
  };
};

export const deleteRecord = record => {
  return dispatch => {
    dispatch({
      type: SIAT2Types.SIAT2_LOCALDELETE_SUCCESS,
      payload: { record }
    });
  };
};
