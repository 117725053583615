import { DailyReportTypes } from "../types";

const initialState = {
  dailyReports: [],
  dailyReport: [],
  next_date_daily_report: null,
  person: null,
  can_destroy: null,
  quantity_daily_reports_finished:null,
  error: {
    status: 0
  },
  this_person_has_guest_records: null
};

export default function dailyReportReducer(state = initialState, action) {
  switch (action.type) {
    case DailyReportTypes.DAILY_REPORT_CREATE_REQUEST:
      return {
        ...state,
        error: {
          status: 0
        }
      };

    case DailyReportTypes.DAILY_REPORT_CREATE_SUCCESS:
      let dailyReports = state.dailyReports;
      dailyReports.push(action.payload.params);
      return {
        ...state,
        dailyReports
      };

    case DailyReportTypes.DAILY_REPORT_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case DailyReportTypes.DAILY_REPORT_UPDATE_REQUEST:
      return {
        ...state,
        error: {
          status: 0
        }
      };

    case DailyReportTypes.DAILY_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        dailyReports: state.dailyReports.map(dailyReport => {
          if (dailyReport.id === action.payload.params.id) {
            return action.payload.params;
          } else {
            return dailyReport;
          }
        })
      };

    case DailyReportTypes.DAILY_REPORT_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case DailyReportTypes.DAILY_REPORT_GET_ALL_REQUEST:
      return {
        ...state
      };

    case DailyReportTypes.DAILY_REPORT_GET_ALL_SUCCESS:
      return {
        ...state,
        next_date_daily_report: action.payload.dailyReports.next_daily_report,
        dailyReports: JSON.parse(action.payload.dailyReports.daily_reports)
      };

    case DailyReportTypes.DAILY_REPORT_GET_ALL_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case DailyReportTypes.DAILY_REPORT_CLEAN_REQUEST:
      return {
        ...state,
        error: {
          status: 0
        }
      };
    case DailyReportTypes.PERSON_SEARCH_SUCCESS:
      var can_destroy_response = null
      var quantity_daily_reports_finished_response = null
      if(action.payload.params != null){
        can_destroy_response = action.payload.params["can_destroy?"]
        quantity_daily_reports_finished_response = action.payload.params["quantity_daily_reports_finished"]
      }
      return {
        ...state,
        person: action.payload.params,
        can_destroy: can_destroy_response,
        quantity_daily_reports_finished: quantity_daily_reports_finished_response
      };
    case DailyReportTypes.RESTART_CAN_DESTROY:
      return {
        ...state,
        can_destroy: null,
        quantity_daily_reports_finished: null
      };

    case DailyReportTypes.THIS_PERSON_HAS_GUEST_RECORDS_SUCCESS:
      return {
        ...state,
        this_person_has_guest_records: action.payload.params.this_person_has_guest_records,
        can_destroy: JSON.parse(action.payload.params.person)["can_destroy?"],
        quantity_daily_reports_finished: JSON.parse(action.payload.params.person)["quantity_daily_reports_finished"]
      };
    case DailyReportTypes.THIS_PERSON_HAS_GUEST_RECORDS_REQUEST:
      return {
        ...state,
        this_person_has_guest_records: null
      };
    case DailyReportTypes.THIS_PERSON_HAS_GUEST_RECORDS_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };


    case DailyReportTypes.GET_GUEST_RECORDS_REQUEST:
      return {
        ...state,
        dailyReport: [],
        error: null
      };
    case DailyReportTypes.GET_GUEST_RECORDS_SUCCESS:
      return {
        ...state,
        dailyReport: action.payload.dailyReports
      };
    case DailyReportTypes.GET_GUEST_RECORDS_FAILURE:
      return {
        ...state,
        dailyReport: [],
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
