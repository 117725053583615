import React, { useRef } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {Menu} from 'primereact/menu';
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { GetFormattedDate } from '../../helper_java/date_format'
import { MainURL } from "../../../redux/endpoints";

const Table = props => {
  let menu = useRef(null);
  let headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Fecha"
          rowSpan={3}
          style={{textAlign:'center', width: '7%', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column header="Estado" rowSpan={3} />
      </Row>
      <Row>
        <Column header="Nacionales" colSpan={4} />
        <Column header="Extranjeros" colSpan={4} />
        <Column header="Acciones" rowSpan={3}/>
      </Row>
      <Row>
        <Column header="Ingresos" />
        <Column header="Permanencias" />
        <Column header="Salidas" />
        <Column header="Day Use" />
        <Column header="Ingresos" />
        <Column header="Permanencias" />
        <Column header="Salidas" />
        <Column header="Day Use" />
      </Row>
      <Row></Row>
    </ColumnGroup>
  );

  var header = (
    <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
      Partes <Button icon="pi pi-refresh" style={{ float: 'right' }} />
    </div>
  );
  let footer = (quantity) =>{
    return(
      <div>
        Hay {quantity} partes
      </div>
    )
  }

  const actionTemplate = (rowData, column) => {
    const moment = require('moment');
    let dateNow = moment();
    let enable = true;
    if (rowData.state === 'no_finalizado'){
      enable = true;
    } else {
      enable = false;
      if ((moment.duration(dateNow.diff(moment(rowData.updated_at))).asHours() < props.global_parameter.edit_daily_report_hours) && (rowData.state !== 'finalizado_en_espera_aprobacion')) {
        enable = true;
      }
    }
    return (
      <div>
        <Button
          onClick={() =>
            // setInputValues({
            //   ...inputValues,
            //   selectedReport: rowData,
            //   visible: true,
            //   action: 'show'
            // })
            props.show(rowData.id)
          }
          type="button"
          icon="pi pi-search"
          className="p-button-success"
          style={{ marginRight: '.5em' }}
        ></Button>
        {enable ? (
          <Button
            onClick={e => {
              props.edit(rowData.id);
            }}
            type="button"
            icon="pi pi-pencil"
            style={{ marginRight: '.5em' }}
            className="p-button-warning"
          />
        ) : (
          ''
        )}
        <Button
          onClick={e => {
            window.open(
              MainURL + '/report/daily_report/' + rowData.id,
              '_self'
            );
          }}
          type="button"
          icon="pi pi-download"
        ></Button>
      </div>
    );
  };


  return (
    <DataTable
      header={header}
      headerColumnGroup={headerGroup}
      value={props.data}
      footer={footer(props.data.length)}

      rowsPerPageOptions={[5, 10, 20]}
      emptyMessage="No tiene partes registrados"
      paginator={true}
      // first={inputValues.first}
      // onPage={e => paginate(e)}
      rows={10}
    >

    <Column
      header="Fecha"
      body={rowData => {
        return GetFormattedDate(rowData.date);
      }}
      style={{textAlign:'center', width: '7%', paddingRight: '0', paddingLeft: '0'}}
    />
    <Column
      body={rowData => {
        if (rowData.state === 'no_finalizado') {
          return <div className="badge_succes">No Finalizado</div>;
        } else if (
          rowData.state === 'finalizado_en_espera_aprobacion'
        ) {
          return <div className="badge_warning">En Espera</div>;
        } else {
          return <div className="badge_danger">Finalizado</div>;
        }
      }}
      style={{textAlign:'center'}}
    />
    <Column field="total_i_national"
      style={{textAlign:'center'}}
    />
    <Column field="total_p_national"
      style={{textAlign:'center'}}
    />
    <Column field="total_e_national"
      style={{textAlign:'center'}}
    />
    <Column field="total_du_national"
      style={{textAlign:'center'}}
    />
    <Column field="total_i_foreign"
      style={{textAlign:'center'}}
    />
    <Column field="total_p_foreign"
      style={{textAlign:'center'}}
    />
    <Column field="total_e_foreign"
      style={{textAlign:'center'}}
    />
    <Column field="total_du_foreign"
      style={{textAlign:'center'}}
    />
    <Column
      body={actionTemplate}
      style={{textAlign:'center', width: '10%', paddingRight: '0', paddingLeft: '0'}}
    />

    </DataTable>

  );
};

export default Table;
