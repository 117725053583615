import { SIAT3Types } from "../types";

const initialState = {
  siats3: [],
  siat: null,
  records: [],
  delete_list: [],
  redirect: false,
  error: null
};

const parseStringToDate = stringDate => {
  if (stringDate == null) return null;
  let from = stringDate.split("-");
  return new Date(from[0], from[1] - 1, from[2]);
};

export default function siat3Reducer(state = initialState, action) {
  switch (action.type) {
    case SIAT3Types.SIAT3_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT3Types.SIAT3_GET_SUCCESS:
      return {
        ...state,
        siats3: action.payload.siat3s,
        redirect: true,
        error: null
      };

    case SIAT3Types.SIAT3_GETBYID_SUCCESS:
      let siat_three_records = action.payload.siat.siat_three_records;
      for (let i = 0; i < siat_three_records.length; i++) {
        siat_three_records[i].local_id = i + 1;
        // siat_three_records[i].arrival = parseStringToDate(
        //   siat_three_records[i].arrival
        // );
        // siat_three_records[i].departure = parseStringToDate(
        //   siat_three_records[i].departure
        // );
      }
      return {
        ...state,
        siat: action.payload.siat,
        delete_list: [],
        records: siat_three_records,
        redirect: true,
        error: null
      };

    case SIAT3Types.SIAT3_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        siats3: []
      };

    case SIAT3Types.SIAT3_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT3Types.SIAT3_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        delete_list: [],
        siats3: state.siats3.concat(action.payload.SIAT3)
      };

    case SIAT3Types.SIAT3_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case SIAT3Types.SIAT3_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT3Types.SIAT3_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true,
        delete_list: [],
        siats3: state.siats3.filter(
          siat3 => siat3.id !== action.payload.SIAT3_id
        )
      };

    case SIAT3Types.SIAT3_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case SIAT3Types.SIAT3_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };
    //SIAT2_EDIT_SUCCESS difference
    case SIAT3Types.SIAT3_EDIT_SUCCESS:
      return {
        ...state,
        redirect: true,
        delete_list: []
      };

    case SIAT3Types.SIAT3_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    //local

    case SIAT3Types.SIAT3_SETINITIAL_SUCCESS:
      return {
        siats3: [],
        siat: null,
        records: [],
        redirect: false,
        error: null
      };

    case SIAT3Types.SIAT3_LOCALCREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        // records: state.records.concat(action.payload.guest_record)
        records: [].concat(action.payload.guest_record, state.records)
      };

    case SIAT3Types.SIAT3_LOCALEDIT_SUCCESS:
      let local_updated_siat3 = action.payload.guest_record;
      return {
        ...state,
        redirect: true,
        records: state.records.map(siat3 => {
          if (siat3.local_id === local_updated_siat3.local_id) {
            return local_updated_siat3;
          } else {
            return siat3;
          }
        })
      };

    case SIAT3Types.SIAT3_LOCALDELETE_SUCCESS:
      let new_delete_list = []
      let delete_item = { id: action.payload.record.id, _destroy: true };

      if (action.payload.record.id != undefined){
        if (state.records.length ==1){
          return {
            ...state,
            error: "No se puede eliminar el último registro del SIAT III, este no puede estar vacío"
          };
        }else{
          new_delete_list = state.delete_list.concat(delete_item)
        }
      }

      return {
        ...state,
        redirect: true,
        delete_list: new_delete_list,
        records: state.records.filter(
          siat3 => siat3.local_id !== action.payload.record.local_id
        ),
        error: null
      };

    case SIAT3Types.SIAT3_EDIT_RECORD_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };
    case SIAT3Types.SIAT3_EDIT_RECORD_SUCCESS:
      return {
        ...state
      };
    case SIAT3Types.SIAT3_EDIT_RECORD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
