import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { MainURL } from '../../redux/endpoints/index';

const EstablishmentContactTable = (props) => {
  let header = (
    <div className="p-clearfix" style={{ lineHeight: '1.87em' }}>
      E-mails de contacto
    </div>
  );
  let footer = (
    <div className="p-clearfix" style={{ width: '100%' }}>
      <Button
        style={{ float: 'left' }}
        label="E-mail de contacto"
        icon="pi pi-plus"
        onClick={props.addNew}
      />
    </div>
  );
  const actionTemplate = (rowData) => {
    let items = [
      {
        label: 'Eliminar',
        icon: 'pi pi-times',
        command: (e) => {
          props.delete(rowData.id);
        },
      },
    ];
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <SplitButton
          label="Editar"
          icon="pi pi-pencil"
          className="p-button-info"
          model={items}
          menuStyle={{ width: '100px'}}
          style={{textAlign:'left'}}
          onClick={(e) => {
            props.edit(rowData);
          }}
        />
      </div>
    );
  };
  return (
    <div>
      <div style={{ margin: '10px' }}>{props.children}</div>
      <DataTable
        header={header}
        footer={footer}
        value={props.establishment_emails}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        emptyMessage="No tienes e-mails de contactos registrados"
      >
        <Column field="name" header="Nombre" />
        <Column field="position" header="Cargo" />
        <Column field="email" header="E-mail" />
        <Column field="cell_phone"
          header="Teléfono"
          style={{textAlign:'center', width: '10%', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column
          header="Envío de e-mail"
          style={{ textAlign: 'center' }}
          body={(rowData) => {
            if (rowData.send_email == true) {
              return <div className="badge_succesfull">Si</div>;
            } else {
              return <div className="badge_succes">No</div>;
            }
          }}
          style={{textAlign:'center', width: '10%', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column field="state"
          header="Estado"
          style={{textAlign:'center', width: '5%', paddingRight: '0', paddingLeft: '0'}}
        />
        <Column
          header="Acciones"
          body={actionTemplate}
          style={{textAlign:'center', width: '10%', paddingRight: '0', paddingLeft: '0'}}
        />
      </DataTable>
    </div>
  );
};

export default EstablishmentContactTable;
