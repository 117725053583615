import React from "react";
import { Button } from "primereact/button";

const Footer = props => {
  if(props.state == 1){
    return(
      <div style={{ marginTop: "15px" }}>
        <Button
          label="Cancelar"
          onClick={props.cancel}
          className="p-button-danger"
          icon="pi pi-times"
        />
      </div>
    );
  }else{
    return (
      <div style={{ marginTop: "15px" }}>
        <Button
          label={props.update ? "Actualizar" : "Guardar"}
          onClick={props.submit}
          style={{ marginRight: "20px" }}
          icon="pi pi-check"
        />
        <Button
          label="Cancelar"
          onClick={props.cancel}
          className="p-button-danger"
          icon="pi pi-times"
        />

        <Button
          label="Finalizar SIAT"
          onClick={props.showModalFormFinalizate}
          className="p-button-warning"
          icon="pi pi-check-circle"
          style={{
            position: "absolute",
            right: "30px"
          }}
        />
      </div>
    );
  }
};

export default Footer;
