import { CommunicationTypes } from "../types";
import { CommunicationEndPoints, MainURL } from "../endpoints";
import axios from "axios";

const communicationsGetBegin = () => ({
  type: CommunicationTypes.COMMUNICATIONS_GET_REQUEST
});

const communicationsGetSuccess = communications => ({
  type: CommunicationTypes.COMMUNICATIONS_GET_SUCCESS,
  payload: { communications }
});

const communicationsGetFailure = error => ({
  type: CommunicationTypes.COMMUNICATIONS_GET_FAILURE,
  payload: { error }
});

export const getComunnications = (token, user) => {
  return dispatch => {
    dispatch(communicationsGetBegin());
    return axios
      .get(MainURL +"/establishments"+ `/${user.establishment_id}`+ CommunicationEndPoints.COMMUNICATIONS_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(communicationsGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(communicationsGetFailure(error.response));
      });
  };
};
