import React, { Component } from "react";
import EstablishmentContactForm from "./form";
import SimpleReactValidator from "simple-react-validator";
import { FormMessages } from "../../config/formTranslations";
import EstablishmentContactTable from "./table";
import { Messages } from "primereact/messages";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as EstablishmentContactActions from "../../redux/actions/EstablishmentContactActions";
import { ProgressSpinner } from "primereact/progressspinner";
import { MainURL } from "../../redux/endpoints";
import axios from "axios";
import WithMessage from "../messaheHOC";
const TableWithMessage = WithMessage(EstablishmentContactTable);

class EstablishmentContact extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: "p-message p-component p-message-error",
      messages: FormMessages
    });
    this.state = this.setIinitalState();
  }

  setIinitalState = () => {
    return {
      formVisible: false,
      people: [],
      form: {
        id: "",
        person: ""
      }
    };
  };

  getPeople() {
    axios
      .get(MainURL + "/people", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.props.token}`
        }
      })
      .then(response => {
        let people = response.data;
        for (let i = 0; i < people.length; i++) {
          people[i].full_name =
            people[i].name +
            " " +
            people[i].last_name +
            " " +
            people[i].middle_name;
        }
        this.setState({
          people: people
        });
      })
      .catch(error => {
        alert("Eror al obtener personas");
      });
  }

  componentDidMount() {
    this.props.EstablishmentContactActions.getEstablishmentContacts(
      this.props.token
    );
    this.getPeople();
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };
  closeModal = () => {
    this.validator.hideMessages();
    this.setState({
      formVisible: false,
      form: this.setIinitalState().form
    });
  };
  handleSubmit = () => {
    if (this.validator.allValid()) {
      let establishmentContact = this.state.form;
      establishmentContact.person_id = establishmentContact.person.id;
      establishmentContact.establishment_id = this.props.user.establishment_id;
      if (establishmentContact.id > 0) {
        this.props.EstablishmentContactActions.editEstablishmentContact(
          this.props.token,
          establishmentContact
        );
      } else {
        this.props.EstablishmentContactActions.createEstablishmentContact(
          this.props.token,
          establishmentContact
        );
      }
      this.setState({
        ...this.state,
        formVisible: false,
        form: this.setIinitalState().form
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  addNew = () => {
    this.setState({
      formVisible: true
    });
  };

  deleteEstablishmentContact = establishment_contact_id => {
    if (window.confirm("¿Estas seguro de eliminar este contacto?"))
      this.props.EstablishmentContactActions.deleteEstablishmentContact(
        this.props.token,
        establishment_contact_id
      );
  };

  editEstablishmentContact = EstablishmentContact => {
    let person = this.state.people.filter(
      person => person.id === EstablishmentContact.person_id
    )[0];
    EstablishmentContact.person = person;
    this.setState({
      form: EstablishmentContact,
      formVisible: true
    });
  };

  render() {
    return (
      <div>
        <Messages ref={el => (this.messages = el)} />
        <EstablishmentContactForm
          handleSubmit={this.handleSubmit}
          validator={this.validator}
          visible={this.state.formVisible}
          people={this.state.people}
          handleChange={this.handleChange}
          form={this.state.form}
          closeModal={this.closeModal}
        />
        <TableWithMessage
          loading={this.props.loading}
          success={this.props.success}
          error={this.props.error}
          edit={this.editEstablishmentContact}
          delete={this.deleteEstablishmentContact}
          addNew={this.addNew}
          establishment_contacts={this.props.establishment_contacts}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  user: state.AuthReducer.user,
  establishment_contacts:
    state.EstablishmentContactReducer.establishment_contacts,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error,
  success: state.LoadingReducer.success
});

const mapDispatchToProps = dispatch => ({
  EstablishmentContactActions: bindActionCreators(
    EstablishmentContactActions,
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentContact);
