export const DailyReportTypes = {
  DAILY_REPORT_CREATE_REQUEST: "DAILY_REPORT_CREATE_REQUEST",
  DAILY_REPORT_CREATE_SUCCESS: "DAILY_REPORT_CREATE_SUCCESS",
  DAILY_REPORT_CREATE_FAILURE: "DAILY_REPORT_CREATE_FAILURE",

  DAILY_REPORT_UPDATE_REQUEST: "DAILY_REPORT_UPDATE_REQUEST",
  DAILY_REPORT_UPDATE_SUCCESS: "DAILY_REPORT_UPDATE_SUCCESS",
  DAILY_REPORT_UPDATE_FAILURE: "DAILY_REPORT_UPDATE_FAILURE",

  DAILY_REPORT_GET_ALL_REQUEST: "DAILY_REPORT_GET_ALL_REQUEST",
  DAILY_REPORT_GET_ALL_SUCCESS: "DAILY_REPORT_GET_ALL_SUCCESS",
  DAILY_REPORT_GET_ALL_FAILURE: "DAILY_REPORT_GET_ALL_FAILURE",

  DAILY_REPORT_CLEAN_REQUEST: "DAILY_REPORT_CLEAN_REQUEST",

  PERSON_SEARCH_REQUEST: "PERSON_SEARCH_REQUEST",
  PERSON_SEARCH_SUCCESS: "PERSON_SEARCH_SUCCESS",
  PERSON_SEARCH_FAILURE: "PERSON_SEARCH_FAILURE",

  THIS_PERSON_HAS_GUEST_RECORDS_SUCCESS: "THIS_PERSON_HAS_GUEST_RECORDS_SUCCESS",
  THIS_PERSON_HAS_GUEST_RECORDS_REQUEST: "THIS_PERSON_HAS_GUEST_RECORDS_REQUEST",
  THIS_PERSON_HAS_GUEST_RECORDS_FAILURE: "THIS_PERSON_HAS_GUEST_RECORDS_FAILURE",

  RESTART_CAN_DESTROY: "RESTART_CAN_DESTROY",

  GET_GUEST_RECORDS_SUCCESS: "GET_GUEST_RECORDS_SUCCESS",
  GET_GUEST_RECORDS_REQUEST: "GET_GUEST_RECORDS_REQUEST",
  GET_GUEST_RECORDS_FAILURE: "GET_GUEST_RECORDS_FAILURE",
};
