import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { es } from "../../../../config/translations";

const ModalImportDifferences = props => {


  const footer = (
    <div>
      <Button
        label={"Aceptar"}
        onClick={props.insertCorrectData}
        icon="pi pi-check"
      />
    </div>
  );

  return (
    <Dialog
      style={{ width: "25vw" }}
      // maximizable
      footer={footer}
      header={
        "Aviso"
      }
      visible={props.visible}
      modal={true}
      onHide={props.closeModal}
    >
      <p style={{marginTop: '0 !important'}}>La importación de datos del excel ha sido exitosa.</p>
    </Dialog>
  );
};

export default ModalImportDifferences;
