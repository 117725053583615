import React, { Component } from "react";
import UnpleasantForm from "./form";
import SimpleReactValidator from "simple-react-validator";
import { FormMessages } from "../../config/formTranslations";
import {UnpleasantTable} from "./table";
import { Messages } from "primereact/messages";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UnPleasantActions from "../../redux/actions/UnPleasantActions";
import * as DailyReportActions from "../../redux/actions/DailyReportActions";
import WithMessage from "../messaheHOC";
import { GetFullNameValidated } from '../helper_java/person_helper'
const TableWithMessage = WithMessage(UnpleasantTable);

class Unpleasant extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: "p-message p-component p-message-error",
      messages: FormMessages,
    validators: {
      validImage: {
          message: 'El archivo debe ser de tipo Imagen (.jpg|.png|.JPG|.PNG|.jpeg|.JPEG).',
          rule: (val, params, validator) => {
            if((val !== '') && (val !== null) && (val !== undefined)){
              return validator.helpers.testRegex(val.name,/(\.jpg|\.png|\.JPG|\.PNG|\.jpeg|\.JPEG)$/)
            } else {
              return true;
            }
          }
        }
      }
    });
    this.state = this.setInitialState();
  }

  setInitialState = () => {
    return {
      formVisible: false,
      daily_report_guest_records: null,
      form: {
        id: "",
        guest_record: "",
        daily_report: "",
        description: "",
        image: ""
      },
      own_unpleasants: [],
      unpleasant_establishments: [],
      selectedImage: ""
    };
  };

  handleImageChange = e => {
      this.setState({
        selectedImage: e.target.files[0]
      });
  };

  async componentDidMount () {
    await this.props.UnPleasantActions.getUnpleasants(this.props.token);
    await this.props.DailyReportActions.getDailyReports(this.props.token);
    this.divideUnpleasants(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.divideUnpleasants(nextProps);
  }

  divideUnpleasants = (props) => {
      let new_unpleasants = [];
      let new_unpleasantEstablishments = [];
      let user = this.props.user;
      props.unpleasants.forEach(function (unpleasant) {
        if (unpleasant.establishment_id === user.establishment_id) {
          new_unpleasants.push(unpleasant);
        } else {
          new_unpleasantEstablishments.push(unpleasant);
        }
      })
      this.setState({
        own_unpleasants: new_unpleasants,
        unpleasant_establishments: new_unpleasantEstablishments
      });
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
    if (name == "daily_report" && value.id != undefined){
      this.get_guest_records(value.id)
    }
  };
  closeModal = () => {
    if ( document.body.contains(document.getElementById('unpleasant_image_id')) ) {
      document.getElementById('unpleasant_image_id').value = null;
    }
    this.validator.hideMessages();
    this.setState({
      formVisible: false,
      form: this.setInitialState().form,
      selectedImage: '',
      daily_report_guest_records: null
    });
  };
  handleSubmit = event => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let unpleasant = this.state.form;
      const formData = new FormData(event.target);
      formData.append("daily_report_id", unpleasant.daily_report.id);
      formData.append("guest_record_id", unpleasant.guest_record.id);
      formData.append("person_id", unpleasant.guest_record.person_id);
      formData.append("establishment_id", this.props.user.establishment_id);

      if(this.state.selectedImage !== ''){
        formData.append("image", this.state.selectedImage);
      }

      if (unpleasant.id > 0) {
        this.props.UnPleasantActions.editUnpleasant(
          this.props.token,
          formData,
          unpleasant.id
        );
      } else {
        this.props.UnPleasantActions.createUnpleasant(
          this.props.token,
          formData
        );
      }
      this.setState({
        ...this.state,
        formVisible: false,
        form: this.setInitialState().form,
        daily_report_guest_records: null,
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  addNew = () => {
    this.setState({
      formVisible: true
    });
  };

  deleteUnpleasant = unpleasant_id => {
    if (window.confirm("¿Está seguro de eliminar este huésped no grato?"))
      this.props.UnPleasantActions.deleteUnpleasant(
        this.props.token,
        unpleasant_id
      );
  };

  editUnpleasant = async(unpleasant) => {
    let daily_report = this.props.dailyReports.filter(
      daily_report => daily_report.id === unpleasant.daily_report_id
    )[0];

    await this.props.DailyReportActions.getDailyReport(this.props.token, daily_report.id);

    let guest_record = this.props.dailyReport.guest_records.filter(
      guest_record => guest_record.id === unpleasant.guest_record_id
    )[0];
    let nameGuest = GetFullNameValidated(guest_record.person.name, guest_record.person.last_name, guest_record.person.middle_name);
    guest_record = {
      name: nameGuest,
      id: guest_record.id,
      person_id: guest_record.person.id
    };
    this.setState({
      form: {
        ...this.state.form,
        image: unpleasant.image_url,
        ...unpleasant,
        daily_report,
        guest_record,
      },
      formVisible: true,
      daily_report_guest_records: this.props.dailyReport.guest_records
    });

  };

  get_guest_records = async(daily_report_id) =>{
    await this.props.DailyReportActions.getDailyReport(this.props.token, daily_report_id);
    this.setState({
      showVisible: true,
      daily_report_guest_records: this.props.dailyReport.guest_records
    });
  }

  render() {
    return (
      <div>
        <Messages ref={el => (this.messages = el)} />
        <UnpleasantForm
          dailyReports={this.props.dailyReports}
          daily_report_guest_records={this.state.daily_report_guest_records}
          handleSubmit={this.handleSubmit}
          handleImageChange={this.handleImageChange}
          validator={this.validator}
          visible={this.state.formVisible}
          handleChange={this.handleChange}
          form={this.state.form}
          closeModal={this.closeModal}
          selectedImage={this.state.selectedImage}
        />
        <TableWithMessage
          loading={this.props.loading}
          success={this.props.success}
          error={this.props.error}
          data={this.props.siats4}
          edit={this.editUnpleasant}
          delete={this.deleteUnpleasant}
          addNew={this.addNew}
          unpleasants={this.state.own_unpleasants}
          unpleasant_establishments={this.state.unpleasant_establishments}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  user: state.AuthReducer.user,
  unpleasants: state.UnpleasantReducer.unpleasants,
  dailyReports: state.DailyReportReducer.dailyReports,
  dailyReport: state.DailyReportReducer.dailyReport,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error,
  success: state.LoadingReducer.success
});

const mapDispatchToProps = dispatch => ({
  UnPleasantActions: bindActionCreators(UnPleasantActions, dispatch),
  DailyReportActions: bindActionCreators(DailyReportActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Unpleasant);
