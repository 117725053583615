import { SIAT4Types } from "../types";
import { Siat4Endpoints, MainURL } from "../endpoints";
import axios from "axios";

const siat4GetBegin = () => ({
  type: SIAT4Types.SIAT4_GET_REQUEST
});

const siat4GetSuccess = siat4s => ({
  type: SIAT4Types.SIAT4_GET_SUCCESS,
  payload: { siat4s }
});

const siat4GetFailure = error => ({
  type: SIAT4Types.SIAT4_GET_FAILURE,
  payload: { error }
});

export const getsiats = token => {
  return dispatch => {
    dispatch(siat4GetBegin());
    return axios
      .get(MainURL + Siat4Endpoints.SIAT4_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat4GetSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat4GetFailure(error.response));
      });
  };
};

const siat4GetByIdBegin = () => ({
  type: SIAT4Types.SIAT4_GETBYID_REQUEST
});

const siat4GetByIdSuccess = siat => ({
  type: SIAT4Types.SIAT4_GETBYID_SUCCESS,
  payload: { siat }
});

const siat4GetByIdFailure = error => ({
  type: SIAT4Types.SIAT4_GETBYID_FAILURE,
  payload: { error }
});

export const getSiatById = (token, siat_id) => {
  return dispatch => {
    dispatch(siat4GetByIdBegin());
    return axios
      .get(`${MainURL}${Siat4Endpoints.SIAT4_ENDPOINT}/${siat_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat4GetByIdSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat4GetByIdFailure(error.response));
      });
  };
};

const siat4CreateBegin = () => ({
  type: SIAT4Types.SIAT4_CREATE_REQUEST
});

const siat4CreateSuccess = siat4 => ({
  type: SIAT4Types.SIAT4_CREATE_SUCCESS,
  payload: { siat4, success: "SIAT IV creado exitosamente" }
});

const siat4CreateFailure = error => ({
  type: SIAT4Types.SIAT4_CREATE_FAILURE,
  payload: { error }
});

export const createSiat4 = (token, data) => {
  return dispatch => {
    dispatch(siat4CreateBegin());
    return axios
      .post(MainURL + Siat4Endpoints.SIAT4_ENDPOINT, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat4CreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat4CreateFailure(error.response));
      });
  };
};

const siat4DeleteBegin = () => ({
  type: SIAT4Types.SIAT4_DELETE_REQUEST
});

const siat4DeleteSuccess = SIAT4_id => ({
  type: SIAT4Types.SIAT4_DELETE_SUCCESS,
  payload: { SIAT4_id, success: "SIAT IV eliminado exitosamente" }
});

const siat4DeleteFailure = error => ({
  type: SIAT4Types.SIAT4_DELETE_FAILURE,
  payload: { error }
});

export const deleteSiat4 = (token, SIAT4_id) => {
  return dispatch => {
    dispatch(siat4DeleteBegin());
    return axios
      .delete(MainURL + Siat4Endpoints.SIAT4_ENDPOINT + `/${SIAT4_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat4DeleteSuccess(SIAT4_id));
      })
      .catch(error => {
        dispatch(siat4DeleteFailure(error.response));
      });
  };
};

const siat4EditBegin = () => ({
  type: SIAT4Types.SIAT4_EDIT_REQUEST
});

const siat4EditSuccess = siat4 => ({
  type: SIAT4Types.SIAT4_EDIT_SUCCESS,
  payload: { siat4, success: "SIAT III actualizado exitosamente" }
});

const siat4EditFailure = error => ({
  type: SIAT4Types.SIAT4_EDIT_FAILURE,
  payload: { error }
});

export const editSiat4 = (token, data) => {
  return dispatch => {
    dispatch(siat4EditBegin());
    return axios
      .put(MainURL + Siat4Endpoints.SIAT4_ENDPOINT + `/${data.id}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat4EditSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat4EditFailure(error.response));
      });
  };
};

export const setInitialState = () => {
  return dispatch => {
    dispatch({
      type: SIAT4Types.SIAT4_SETINITIAL_SUCCESS,
      payload: null
    });
  };
};

export const createRecord = guest_record => {
  return dispatch => {
    dispatch({
      type: SIAT4Types.SIAT4_LOCALCREATE_SUCCESS,
      payload: { guest_record }
    });
  };
};

const siat4editRecortBegin = () => ({
  type: SIAT4Types.SIAT4_EDIT_RECORD_REQUEST
});

const siat4editRecortSuccess = siat => ({
  type: SIAT4Types.SIAT4_EDIT_RECORD_SUCCESS,
  payload: { siat }
});

const siat4editRecortFailure = error => ({
  type: SIAT4Types.SIAT4_EDIT_RECORD_FAILURE,
  payload: { error }
});

export const editRecord = (token, data) => {
  return dispatch => {
    dispatch(siat4editRecortSuccess());
    return axios
    .put(MainURL + Siat4Endpoints.SIAT4RECORD_ENDPOINT + `/${data.id}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat4editRecortSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat4editRecortFailure(error.response));
      });
  };
};

export const deleteRecord = record => {
  return dispatch => {
    dispatch({
      type: SIAT4Types.SIAT4_LOCALDELETE_SUCCESS,
      payload: { record }
    });
  };
};
