import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import DailyReportReducer from "./DailyReportReducer";
import CategoryReducer from "./CategoryReducer";
import EstablishmentReducer from "./EstablishmentReducer";
import CountryReducer from "./CountryReducer";
import RoomReducer from "./RoomReducer";
import EmployeReducer from "./EmployeReducer";
import UnpleasantReducer from "./UnpleasantReducer";
import SiatReducer from "./SiatReducer";
import NationalityReducer from "./NationalityReducer";
import ParteReducer from "./GuestRecordReducer";
import DashboardReducer from "./DashboardReducer";
import Siat2Reducer from "./Siat2Reducer";
import Siat3Reducer from "./Siat3Reducer";
import Siat4Reducer from "./Siat4Reducer";
import Siat5Reducer from "./Siat5Reducer";
import EstablishmentEmailReducer from "./EstablishmentEmailReducer";
import EstablishmentContactReducer from "./EstablishmentContactReducer";
import LogReducer from "./LogReducer";
import LoadingReducer from "./LoadingReducer";
import ErrorReducer from "./ErrorReducer";
import CommunicationReducer from "./CommunicationReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const authReducerPersistConfig = {
  key: "AuthReducer_v2",
  storage: storage,
  blacklist: ["redirect"]
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "AuthReducer",
    "ParteReducer",
    "DailyReportReducer",
    "CategoryReducer",
    "EstablishmentReducer",
    "CountryReducer",
    "LogReducer",
    "LoadingReducer",
    "ErrorReducer",
    "Siat2Reducer",
    "Siat3Reducer",
    "Siat4Reducer",
    "Siat5Reducer",
    "EstablishmentEmailReducer",
    "EstablishmentContactReducer",
    "CommunicationReducer"
  ]
};

const combineReducer = combineReducers({
  AuthReducer: persistReducer(authReducerPersistConfig, AuthReducer),
  DailyReportReducer: DailyReportReducer,
  CategoryReducer: CategoryReducer,
  EstablishmentReducer: EstablishmentReducer,
  CountryReducer: CountryReducer,
  LogReducer: LogReducer,
  LoadingReducer: LoadingReducer,
  NationalityReducer: NationalityReducer,
  ParteReducer: ParteReducer,
  ErrorReducer: ErrorReducer,
  DashboardReducer,
  RoomReducer,
  EmployeReducer,
  SiatReducer,
  UnpleasantReducer,
  Siat2Reducer,
  Siat3Reducer,
  Siat4Reducer,
  Siat5Reducer,
  EstablishmentEmailReducer,
  EstablishmentContactReducer,
  CommunicationReducer
});

const rootReducer = persistReducer(rootPersistConfig, combineReducer);

export default rootReducer;
