import React, { Component } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { FormMessages } from '../../../config/formTranslations';
import { Messages } from 'primereact/messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CommunicationActions from '../../../redux/actions/CommunicationActions';
import { ProgressSpinner } from 'primereact/progressspinner';
import CommunicationTable from '../components/CommunicationTable';
import WithMessage from '../../messaheHOC';
const TableWithMessage = WithMessage(CommunicationTable);
class Communication extends Component {
  constructor(props) {
    super(props);
    this.state = this.setIinitalState();
  }
  setIinitalState = () => {
    return {
      showVisible: false,
      communication:{
        title: '',
        content: '',
        date:''
      }
    }
  }

  componentDidMount() {
    this.props.CommunicationActions.getComunnications(this.props.token, this.props.user);
  }

  showModal = communication => {
    this.setState({
      communication: { ...communication },
      showVisible: true
    });
  };

  closeModal = ()=>{
    this.setState({
      showVisible: false,
      communication: {
        title: '',
        content: '',
        date:''
      }
    });
  }

  render() {
    return (
      <div>
        <Messages ref={el => (this.messages = el)} />
        <TableWithMessage
          loading={this.props.loading}
          success={this.props.success}
          error={this.props.error}
          communications={this.props.communications}
          showVisible={this.state.showVisible}
          showModal={this.showModal}
          closeModal={this.closeModal}
          communication={this.state.communication}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  user: state.AuthReducer.user,
  communications: state.CommunicationReducer.communications,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error,
  success: state.LoadingReducer.success
});

const mapDispatchToProps = dispatch => ({
  CommunicationActions: bindActionCreators(CommunicationActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Communication);
