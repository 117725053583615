import { EmployeTypes } from "../types";

const initialState = {
  employes: [],
  redirect: false,
  error: null
};

export default function employeReducer(state = initialState, action) {
  switch (action.type) {
    case EmployeTypes.EMPLOYE_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EmployeTypes.EMPLOYE_GET_SUCCESS:
      return {
        ...state,
        employes: action.payload.employes,
        redirect: true,
        error: null
      };

    case EmployeTypes.EMPLOYE_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        employes: []
      };

    case EmployeTypes.EMPLOYE_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EmployeTypes.EMPLOYE_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        employes: state.employes.concat(action.payload.employe)
      };

    case EmployeTypes.EMPLOYE_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case EmployeTypes.EMPLOYE_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EmployeTypes.EMPLOYE_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true,
        employes: state.employes.filter(employe => employe.id !== action.payload.employe_id)
      };

    case EmployeTypes.EMPLOYE_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case EmployeTypes.EMPLOYE_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EmployeTypes.EMPLOYE_EDIT_SUCCESS:
      let updated_employe = action.payload.employe;
      return {
        ...state,
        redirect: true,
        employes: state.employes.map(employe => {
          if (employe.id === updated_employe.id) {
            return updated_employe;
          } else {
            return employe;
          }
        })
      };

    case EmployeTypes.EMPLOYE_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
