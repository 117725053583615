import { CountryTypes } from '../types';

const initialState = {
  countries: [],
  redirect: false,
  error: null
};

export default function countryReducer(state = initialState, action) {
  switch (action.type) {
    case CountryTypes.COUNTRY_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case CountryTypes.COUNTRY_GET_SUCCESS:
      return {
        ...state,
        countries: action.payload.countries,
        redirect: true,
        error: null
      };

    case CountryTypes.COUNTRY_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        countries: []
      };

    case CountryTypes.COUNTRY_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case CountryTypes.COUNTRY_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true
      };

    case CountryTypes.COUNTRY_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case CountryTypes.COUNTRY_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case CountryTypes.COUNTRY_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true
      };

    case CountryTypes.COUNTRY_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case CountryTypes.COUNTRY_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case CountryTypes.COUNTRY_EDIT_SUCCESS:
      return {
        ...state,
        redirect: true
      };

    case CountryTypes.COUNTRY_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
