const menuItems = [
  {
    label: 'Dashboard',
    icon: 'pi pi-fw pi-home',
    to: '/'
  },
  {
    label: 'E-mails de contacto',
    icon: 'pi pi-envelope',
    to: '/establishment_emails'
  },
  {
    label: 'Comunicados',
    icon: 'pi pi-volume-up',
    to: '/communications'
  },
  // {
  //   label: "Contactos",
  //   icon: "pi pi-users",
  //   to: "/establishment_contacts"
  // },
  {
    label: 'Empleados',
    icon: 'pi pi-users',
    to: '/employes'
  },
  {
    label: 'Oferta de habitaciones',
    icon: 'pi pi-key',
    to: '/rooms'
  },
  {
    label: 'Huéspedes no gratos',
    icon: 'pi pi-times',
    to: '/unpleasant'
  },
  {
    label: 'SIAT I',
    icon: 'pi pi-cog',
    to: '/siats'
  },
  {
    label: 'SIAT II - III - IV',
    icon: 'pi pi-cog',
    items: [
      
      {
        label: 'Listado',
        icon: 'pi pi-fw pi-list',
        to: '/siat5'
      },

    ]
  },
  // {
  //   label: 'SIAT II',
  //   icon: 'pi pi-cog',
  //   items: [
  //     {
  //       label: 'Listado',
  //       icon: 'pi pi-fw pi-list',
  //       to: '/siat2'
  //     },
  //     {
  //       label: 'Nuevo',
  //       icon: 'pi pi-plus',
  //       to: '/siat2/new'
  //     }
  //   ]
  // },
  // {
  //   label: 'SIAT III',
  //   icon: 'pi pi-cog',
  //   items: [
  //     {
  //       label: 'Listado',
  //       icon: 'pi pi-fw pi-list',
  //       to: '/siat3'
  //     },
  //     {
  //       label: 'Nuevo',
  //       icon: 'pi pi-plus',
  //       to: '/siat3/new'
  //     }
  //   ]
  // },
  // {
  //   label: 'SIAT IV',
  //   icon: 'pi pi-cog',
  //   items: [
  //     {
  //       label: 'Listado',
  //       icon: 'pi pi-fw pi-list',
  //       to: '/siat4'
  //     },
  //     {
  //       label: 'Nuevo',
  //       icon: 'pi pi-plus',
  //       to: '/siat4/new'
  //     }
  //   ]
  // },
  {
    label: 'Partes',
    icon: 'pi pi-fw pi-file',
    items: [
      {
        label: 'Listado',
        icon: 'pi pi-fw pi-list',
        to: '/partes'
      },
      {
        label: 'Nuevo',
        icon: 'pi pi-fw pi-copy',
        to: '/partes/new'
      }
    ]
  }

  // {
  //   label: 'Personas',
  //   icon: 'pi pi-fw pi-user',
  //   items: [
  //     { label: 'Listado', icon: 'pi pi-fw pi-users', to: '/sample' },
  //     { label: 'Nueva', icon: 'pi pi-fw pi-user-plus', to: '/forms' }
  //   ]
  // },
  // {
  //   label: 'Parametrizacion',
  //   icon: 'pi pi-fw pi-microsoft',
  //   items: [
  //     {
  //       label: 'Establecimientos',
  //       icon: 'pi pi-fw pi-home',
  //       items: [
  //         {
  //           label: 'Listado',
  //           icon: 'pi pi-fw pi-list',
  //           to: '/forms'
  //         },
  //         {
  //           label: 'Nuevo',
  //           icon: 'pi pi-fw pi-plus',
  //           to: '/forms'
  //         }
  //       ]
  //     },
  //     {
  //       label: 'Categorias',
  //       icon: 'pi pi-fw pi-bookmark',
  //       items: [
  //         {
  //           label: 'Listado',
  //           icon: 'pi pi-fw pi-list',
  //           to: '/forms'
  //         },
  //         {
  //           label: 'Nueva',
  //           icon: 'pi pi-fw pi-plus',
  //           to: '/forms'
  //         }
  //       ]
  //     },
  //     {
  //       label: 'Paises',
  //       icon: 'pi pi-fw pi-globe',
  //       items: [
  //         {
  //           label: 'Listado',
  //           icon: 'pi pi-fw pi-list',
  //           to: '/forms'
  //         },
  //         {
  //           label: 'Nuevo',
  //           icon: 'pi pi-fw pi-plus',
  //           to: '/forms'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   label: 'Logs',
  //   icon: 'pi pi-fw pi-align-left',
  //   to: '/logs'
  // }
];

export { menuItems };
