import React, { Component } from 'react';
import EmployeForm from '../components/EmployeForm';
import SimpleReactValidator from 'simple-react-validator';
import { FormMessages } from '../../../config/formTranslations';
import EmployeTable from '../components/EmployeTable';
import { Messages } from 'primereact/messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as EmployeActions from '../../../redux/actions/EmployeActions';
import { ProgressSpinner } from 'primereact/progressspinner';
import WithMessage from '../../messaheHOC';
const TableWithMessage = WithMessage(EmployeTable);

class Employe extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: 'p-message p-component p-message-error',
      messages: FormMessages,
      validators: {
        zero_invalid: {  // name the rule
          message: 'La cantidad no es válida.',
          rule: (val, params, validator) => {
              return validator.helpers.testRegex(parseInt(val),/^[1-9][0-9]*$/)
          }
        }
      }
    });
    this.state = this.setIinitalState();
  }
  
  showError = () => {
    if (this.props.employe_error) {
      let error = this.props.employe_error.data
        ? this.props.employe_error.data.name
        : this.props.employe_error;
      this.messages.show({
        severity: "error",
        summary: "",
        detail: error,
        closable: false,
      });
    }
  };

  setIinitalState = () => {
    return {
      formVisible: false,
      form: {
        id: '',
        quantity: '',
        name: ''
      }
    };
  };

  componentDidMount() {
    this.props.EmployeActions.getEmployes(this.props.token);
  }

  get_total_quantity = () => {
    var total_quantity = 0;
    this.props.employes.forEach(function(item) {
      total_quantity += item.quantity;
    });
    return total_quantity;
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };
  closeModal = () => {
    this.validator.hideMessages();
    this.setState({
      formVisible: false,
      showVisible: false,
      form: {
        habitacion: '',
        employe: ''
      }
    });
  };
  handleSubmit = async() => {
    if (this.validator.allValid()) {
      let employe = this.state.form;
      employe.establishment_id = this.props.user.establishment_id;
      if (employe.id > 0) {
        await this.props.EmployeActions.editEmploye(this.props.token, employe);
        this.showError();
      } else {
        await this.props.EmployeActions.createEmploye(this.props.token, employe);
        this.showError();
      }
      this.setState({
        ...this.state,
        formVisible: false,
        form: this.setIinitalState().form
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  addNew = () => {
    this.setState({
      formVisible: true
    });
  };

  deleteEmploye = employe_id => {
    if (window.confirm('¿Está seguro de eliminar este empleado?'))
      this.props.EmployeActions.deleteEmploye(this.props.token, employe_id);
  };

  editEmploye = employe => {
    this.setState({
      form: { ...employe },
      formVisible: true
    });
  };

  render() {
    this.get_total_quantity();
    if (this.props.loading) {
      return (
        <div className="p-grid p-fluid p-justify-center">
          <ProgressSpinner
            style={{ width: '20%', height: '20%', marginTop: '10%' }}
            animationDuration=".5s"
          />
        </div>
      );
    }
    return (
      <div>
        <Messages ref={el => (this.messages = el)} />
        <EmployeForm
          handleSubmit={this.handleSubmit}
          validator={this.validator}
          visible={this.state.formVisible}
          handleChange={this.handleChange}
          form={this.state.form}
          closeModal={this.closeModal}
        />
        <TableWithMessage
          get_total_quantity={this.get_total_quantity}
          loading={this.props.loading}
          success={this.props.success}
          error={this.props.error}
          edit={this.editEmploye}
          delete={this.deleteEmploye}
          addNew={this.addNew}
          employes={this.props.employes}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  user: state.AuthReducer.user,
  employes: state.EmployeReducer.employes,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error,
  success: state.LoadingReducer.success,
  employe_error: state.EmployeReducer.error
});

const mapDispatchToProps = dispatch => ({
  EmployeActions: bindActionCreators(EmployeActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Employe);
