import { GuestRecordTypes } from "../types";
import { DailyReportEndpoints, MainURL } from "../endpoints";
import axios from "axios";

//preload

const getDailyPreloadBegin = () => ({
  type: GuestRecordTypes.PRELOAD_GET_REQUEST
});

const getDailyPreloadSuccess = guest_records => ({
  type: GuestRecordTypes.PRELOAD_GET_SUCCESS,
  payload: { guest_records }
});

const getDailyPreloadFailure = error => ({
  type: GuestRecordTypes.PRELOAD_GET_FAILURE,
  payload: { error }
});

export function getDailyPreload(token) {
  return dispatch => {
    dispatch(getDailyPreloadBegin());
    return axios
      .get(MainURL + DailyReportEndpoints.DAILY_PRELOAD_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(getDailyPreloadSuccess(response.data));
      })
      .catch(error => {
        dispatch(getDailyPreloadFailure(error.response));
      });
  };
}

// /preload

export const createGuestRecord = guest_record => {
  return dispatch => {
    dispatch({
      type: GuestRecordTypes.GUEST_SAVE_SUCCESS,
      payload: { guest_record }
    });
  };
};

export const editGuestRecord = guest_record => {
  return dispatch => {
    dispatch({
      type: GuestRecordTypes.GUEST_UPDATE_SUCCESS,
      payload: { guest_record }
    });
  };
};

export const deleteGuestRecord = guest_record => {
  return dispatch => {
    dispatch({
      type: GuestRecordTypes.GUEST_DELETE_SUCCESS,
      payload: { guest_record }
    });
  };
};

export const cleanState = () => {
  return dispatch => {
    dispatch({ type: GuestRecordTypes.CLEAN_GUEST_SUCCESS });
  };
};

export const loadState = guestRecords => {
  return dispatch => {
    dispatch({
      type: GuestRecordTypes.LOAD_GUEST_SUCCESS,
      payload: { guestRecords }
    });
  };
};

const guestGetBegin = () => ({
  type: GuestRecordTypes.GUEST_EDIT_REQUEST
});

const guestGetSuccess = guest_records => ({
  type: GuestRecordTypes.GUEST_EDIT_SUCCESS,
  payload: { guest_records }
});

const guestGetFailure = error => ({
  type: GuestRecordTypes.GUEST_EDIT_FAILURE,
  payload: { error }
});

export const getGuestRecords = (id, token) => {
  return dispatch => {
    dispatch(guestGetBegin());
    return axios
      .get(MainURL + DailyReportEndpoints.DAILY_REPORT_ENDPOINT + "/" + id, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        let guest_records_array = response.data.guest_records;
        for (var i = 0; i < guest_records_array.length; i++) {
          guest_records_array[i].person_attributes =
            guest_records_array[i].person;
        }
        dispatch(guestGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(guestGetFailure(error.response));
      });
  };
};
