import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { es } from "../../../config/translations";

const Form = props => {
  console.log(props)
  const footer = (
    <div>
      <Button
        label={"Finalizar SIAT I"}
        onClick={props.handleFinish}
        icon="pi pi-check"
      />
      <Button
        className="p-button-danger"
        label="Cancelar"
        icon="pi pi-times"
        onClick={props.closeModal}
      />
    </div>
  );
  console.log(props)
  return (
    <Dialog
      style={{ width: "40vw" }}
      //maximizable
      footer={footer}
      header={
        "Finalizar SIAT I"
      }
      visible={props.visible}
      modal={true}
      onHide={props.closeModal}
    >
      <div align="center">
        <p>¿Está seguro de <b>Finalizar su SIAT I - {props.form.date}</b>?</p>
        <p>Una vez <b>FINALIZADO</b> no se podrá volver a <b>EDITAR o ELIMINAR</b>.</p>
      </div>
    </Dialog>
  );
};

export default Form;
