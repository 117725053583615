import { NationalityTypes } from "../types";

const initialState = {
  nationalities: [],
  redirect: false,
  error: null
};

export default function nationalityReducer(state = initialState, action) {
  switch (action.type) {
    case NationalityTypes.NATIONALITY_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case NationalityTypes.NATIONALITY_GET_SUCCESS:
      return {
        ...state,
        nationalities: action.payload.nationalities,
        redirect: true,
        error: null
      };

    case NationalityTypes.NATIONALITY_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        nationalities: []
      };

    case NationalityTypes.NATIONALITY_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case NationalityTypes.NATIONALITY_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true
      };

    case NationalityTypes.NATIONALITY_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case NationalityTypes.NATIONALITY_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case NationalityTypes.NATIONALITY_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true
      };

    case NationalityTypes.NATIONALITY_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case NationalityTypes.NATIONALITY_EDIT_SUCCESS:
      return {
        ...state,
        redirect: true
      };

    case NationalityTypes.NATIONALITY_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
