import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import {
  register_models,
  establishment_categories,
  establishment_types
} from '../../../config/models';

const SiatForm = props => {
  const {
    id,
    nit,
    branch,
    owner,
    establishment_name,
    address,
    category,
    description,
    category_type,
    total_room,
    total_apartment,
    total_bungalow,
    reg_mod,
    mod_desc
  } = props.form;

  const footer = (
    <div>
      <Button
        label={id ? 'Actualizar' : 'Guardar'}
        icon="pi pi-check"
        onClick={props.handleSubmit}
      />
      <Button
        label="Cancelar"
        className="p-button-danger"
        icon="pi pi-times"
        onClick={props.closeModal}
      />
    </div>
  );
  return (
    <Dialog
      header={id ? 'Actualizar SIAT I' : 'Nuevo SIAT I'}
      footer={footer}
      visible={props.visible}
      style={{ width: '70vw' }}
      modal={true}
      onHide={props.closeModal}
    >
      <div className="p-grid p-fluid">

        <div className="p-col-12 p-md-6" >
          <span className="p-float-label">
            <InputText
              maxLength="14"
              onChange={props.handleChange}
              value={nit}
              data-type="number"
              keyfilter="pint"
              name="nit"
            />
            <label htmlFor="in">NIT del contribuyente</label>
          </span>
          {props.validator.message('nit', nit, 'required')}
        </div>
        <div className="p-col-12 p-md-6">
          <span className="p-float-label">
            <InputText
              onChange={props.handleChange}
              keyfilter="pint"
              data-type="number"
              value={branch}
              name="branch"
              maxLength="2"
              tooltip="Ingrese 0 para la casa matriz, si es sucursal indicar el número que corresponda"
            />
            <label htmlFor="in">Casa matriz o sucursal</label>
          </span>
          {props.validator.message(
            'Casa matriz o sucursal',
            branch,
            'required'
          )}
        </div>

        <div className="p-col-12 p-md-6 siatsInputStype1">
          <span className="p-float-label">
            <InputText
              maxLength="250"
              onChange={props.handleChange}
              value={owner}
              name="owner"
            />
            <label htmlFor="in">
              Nombre o razón social del propietario del establecimiento
            </label>
          </span>
          {props.validator.message(
            'Nombre o razón social del propietario del establecimiento',
            owner,
            'required'
          )}
        </div>
        <div className="p-col-12 p-md-6 siatsInputStype1">
          <span className="p-float-label">
            <InputText
              maxLength="250"
              onChange={props.handleChange}
              value={establishment_name}
              name="establishment_name"
            />
            <label htmlFor="in">Nombre comercial del establecimiento</label>
          </span>
          {props.validator.message(
            'Nombre comercial del establecimiento',
            establishment_name,
            'required'
          )}
        </div>

        <div className="p-col-12 p-md-6 siatsInputStype1">
          <span className="p-float-label">
            <InputText
              maxLength="250"
              onChange={props.handleChange}
              value={address}
              name="address"
            />
            <label htmlFor="in">Dirección del establecimiento</label>
          </span>
          {props.validator.message(
            'Dirección del establecimiento',
            address,
            'required'
          )}
        </div>
        <div className="p-col-12 p-md-6">
          <label className="dropDownStyle" htmlFor="in">Tipo de establecimiento</label>
          <Dropdown
            onChange={props.handleChangeSelect}
            filter={true}
            filterPlaceholder="Tipo de establecimiento"
            name="category_type"
            value={category_type}
            options={establishment_types}
            placeholder="Tipo de establecimiento"
          />
          {props.validator.message(
            'Tipo de establecimiento',
            category_type,
            'required'
          )}
        </div>

        {category_type === 10 ? (
          <div className="p-col-12 p-md-6 siatsInputStype1">
            <span className="p-float-label">
              <InputText
                onChange={props.handleChange}
                value={description}
                name="description"
                maxLength="100"
              />
              <label htmlFor="in">
                Descripción tipo de establecimiento (otros)
              </label>
            </span>
            {props.validator.message(
              'Descripción tipo de establecimiento (otros)',
              description,
              'required'
            )}
          </div>
        ) : (
          ''
        )}
        <div className="p-col-12 p-md-6">
          <label className="dropDownStyle" htmlFor="in">Categoría del establecimiento</label>
          <Dropdown
            onChange={props.handleChangeSelect}
            filter={true}
            name="category"
            value={category}
            options={establishment_categories}
            filterPlaceholder="Categoría del establecimiento"
            placeholder="Categoría del establecimiento"
          />
          {props.validator.message(
            'Categoría del establecimiento',
            category,
            'required'
          )}
        </div>

        <div className="p-col-12 p-md-6 siatsInputStype1">
          <span className="marginTop p-float-label">
            <InputText
              onChange={props.handleChange}
              value={total_room}
              data-type="number"
              keyfilter="pint"
              name="total_room"
              maxLength="3"
            />
            <label htmlFor="in">Número total de habitaciones</label>
          </span>
          {props.validator.message(
            'Número total de habitaciones',
            total_room,
            'required'
          )}
        </div>

        <div className="p-col-12 p-md-6 siatsInputStype1">
          <span className="p-float-label">
            <InputText
              onChange={props.handleChange}
              value={total_apartment}
              keyfilter="pint"
              data-type="number"
              name="total_apartment"
              maxLength="3"
            />
            <label htmlFor="in">Número total de departamentos</label>
          </span>
          {props.validator.message(
            'Número total de departamentos',
            total_apartment,
            'required'
          )}
        </div>

        <div className="p-col-12 p-md-6 siatsInputStype1">
          <span className="p-float-label">
            <InputText
              onChange={props.handleChange}
              value={total_bungalow}
              keyfilter="pint"
              data-type="number"
              name="total_bungalow"
              maxLength="3"
            />
            <label htmlFor="in">Número total de cabañas o bungalows</label>
          </span>
          {props.validator.message(
            'Número total de cabañas o bungalows',
            total_bungalow,
            'required'
          )}
        </div>

        <div className="p-col-12 p-md-6">
          <label className="dropDownStyle" htmlFor="in">Modalidad de registro del huésped</label>
          <Dropdown
            onChange={props.handleChangeSelect}
            filter={true}
            filterPlaceholder="Modalidad de registro del huésped"
            name="reg_mod"
            value={reg_mod}
            options={register_models}
            placeholder="Modalidad de registro del huésped"
          />
          {props.validator.message(
            'Modalidad de registro del huésped',
            reg_mod,
            'required'
          )}
        </div>

        {reg_mod === 3 ? (
          <div className="p-col-12 p-md-6 siatsInputStype1">
            <span className="p-float-label">
              <InputText
                maxLength="200"
                value={mod_desc}
                onChange={props.handleChange}
                name="mod_desc"
                data-type="number"
              />
              <label htmlFor="in">
                Descripción de la modalidad de otro sistema de registro
              </label>
            </span>
            {props.validator.message(
              'Descripción de la modalidad de otro sistema de registro',
              mod_desc,
              'required'
            )}
          </div>
          ):(
            ''
          )
        }

      </div>
    </Dialog>
  );
};

export default SiatForm;
