import { RoomTypes } from "../types";

const initialState = {
  rooms: [],
  redirect: false,
  error: null
};

export default function roomReducer(state = initialState, action) {
  switch (action.type) {
    case RoomTypes.ROOM_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case RoomTypes.ROOM_GET_SUCCESS:
      return {
        ...state,
        rooms: action.payload.rooms,
        redirect: true,
        error: null
      };

    case RoomTypes.ROOM_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        rooms: []
      };

    case RoomTypes.ROOM_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case RoomTypes.ROOM_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        rooms: state.rooms.concat(action.payload.room)
      };

    case RoomTypes.ROOM_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case RoomTypes.ROOM_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case RoomTypes.ROOM_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true,
        rooms: state.rooms.filter(room => room.id !== action.payload.room_id)
      };

    case RoomTypes.ROOM_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case RoomTypes.ROOM_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case RoomTypes.ROOM_EDIT_SUCCESS:
      let updated_room = action.payload.room;
      return {
        ...state,
        redirect: true,
        rooms: state.rooms.map(room => {
          if (room.id === updated_room.id) {
            return updated_room;
          } else {
            return room;
          }
        })
      };

    case RoomTypes.ROOM_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
