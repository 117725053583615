import React from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { es } from '../../config/translations';
import {ScrollPanel} from 'primereact/scrollpanel';
import { room_types, booking_types, agency_types, observations, payment_types, fiscal_document_types, siat4_payment_types } from '../../config/models';
import { GetFormattedDate } from '../helper_java/date_format'

const Form = props => {
  const footer = (
    <div>
      <Button
        label={
          'Actualizar'
        }
        onClick={props.handleSubmit}
        icon="pi pi-check"
      />
      <Button
        className="p-button-danger"
        label="Cancelar"
        icon="pi pi-times"
        onClick={props.closeModal}
      />
    </div>
  );
  // const {
  //   document,
  //   arrival,
  //   departure,
  //   days,
  //   room_type,
  //   room_desc,
  //   booking_type,
  //   agency_nit,
  //   agency_type,
  //   booking_engine,
  //   bill_number,
  //   bill_desc,
  //   observation
  // } = props.record;

  // const nationality = props.modal_siats_two.nationality;

  const arrival =  props.modal_siats_two.arrival != ''? GetFormattedDate(props.modal_siats_two.arrival) : ''
  const departure =  props.modal_siats_two.departure != ''? GetFormattedDate(props.modal_siats_two.departure) : ''

  return (
    <Dialog
      style={{ width: '70vw' }}
      maximizable
      footer={footer}
      header={
        "Registros SIATS"
      }
      visible={props.visible}
      modal={true}
      onHide={props.closeModal}
    >

  <ScrollPanel className="scrollMax" style={{width: '100%', height: '500px'}}>
    <div className="title-siats h3">
      <h3>Registro Siat II</h3>
    </div>

    <div className="p-grid p-fluid p-2">

    <div className="p-col-12 p-md-6 siatsInputStype1">
      <span className="p-float-label">
        <InputText
          value={props.modal_siats_two.document}
          name="document"
          disabled={true}
        />
        <label htmlFor="in">Número de documento de identidad, carnet de extranjería o pasaporte.</label>
      </span>
    </div>


      <div className="p-col-12 p-md-6">
        <label className="dropDownStyle" htmlFor="in">Nacionalidad</label>
        <InputText
        value={props.modal_siats_two.nationality_name}
        name="nationality"
        disabled={true}
        />
      </div>
      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            // onSelect={props.onSelectArrival}
            // locale={es}
            value={arrival}
            // maxDate={new Date()}
            name="arrival"
            disabled={true}
          />
          <label htmlFor="in">Fecha de Ingreso al establecimiento</label>
        </span>
      </div>

      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            // onSelect={props.onSelectDeparture}
            // locale={es}
            // minDate={arrival}
            value={departure}
            name="departure"
            disabled={true}
          />
          <label htmlFor="in">Fecha de Salida del establecimiento</label>
        </span>
      </div>

      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            disabled={true}
            value={props.modal_siats_two.days}
            name="days"
          />
          <label htmlFor="in">Días de pernocte o alojamiento temporal</label>
        </span>
      </div>

      <div className="p-col-12 p-md-6">
        <label className="dropDownStyle" htmlFor="in">Tipo de unidad habitacional utilizada por el huésped</label>
        <Dropdown
          filter={true}
          placeholder="Tipo de unidad habitacional utilizada por el huésped"
          filterPlaceholder="Tipo de unidad habitacional utilizada por el huésped"
          value={props.modal_siats_two.room_type}
          name="room_type"
          options={room_types}
          onChange={props.handleChangeTwoSelect}
        />
        {/*if you add new options room_type pleas check exel of this siat*/}
        {props.validator.message('tipo de unidad habitacional utilizada por el huésped',props.modal_siats_two.room_type,'required')}
      </div>

        {props.modal_siats_two.room_type == 4 ? (
            <div className="p-col-12 p-md-6 siatsInputStype1">
              <span className="p-float-label">
                <InputText
                  onChange={props.handleChangeTwo}
                  value={props.modal_siats_two.room_desc}
                  name="room_desc"
                  maxLength={100}
                />
                <label htmlFor="in">Descripción del tipo de habitación utilizada (otros)</label>
              </span>
              {props.validator.message(
                'Descripción del tipo de habitación utilizada (otros)',
                props.modal_siats_two.room_desc,
                'required'
              )}
            </div>
          ) : (
            ''
          )}

        <div className="p-col-12 p-md-6">
          <label className="dropDownStyle" htmlFor="in">Medio de reserva</label>
          <Dropdown
            filter={true}
            placeholder="Medio de reserva"
            value={props.modal_siats_two.booking_type}
            name="booking_type"
            options={booking_types}
            onChange={props.handleChangeTwoSelect}
          />
          {props.validator.message('Medio de reserva',props.modal_siats_two.booking_type,'required')}
        </div>

        {props.modal_siats_two.booking_type == 1 ? (
          <>
            <div className="p-col-12 p-md-6 siatsInputStype1">
                <span className="p-float-label">
                  <InputText
                    maxLength="14"
                    onChange={props.handleChangeTwo}
                    value={props.modal_siats_two.agency_nit}
                    keyfilter="pint"
                    data-type="number"
                    name="agency_nit"
                  />
                  <label htmlFor="in">NIT de la agencia de viajes con residencia en el país</label>
                </span>
                {props.validator.message(
                  'Nit de la Agencia de Viajes con Residencia en el País',
                  props.modal_siats_two.agency_nit,
                  'required'
                )}
                {props.validator.message('nit', props.modal_siats_two.agency_nit, 'invalid_nit_if_zero')}
            </div>
            <div className="p-col-12 p-md-6">
              <label className="dropDownStyle" htmlFor="in">Tipo de Agencia de Viajes</label>
              <Dropdown
                filter={true}
                placeholder="Tipo de Agencia de Viajes"
                filter="Tipo de Agencia de Viajes"
                value={props.modal_siats_two.agency_type}
                name="agency_type"
                options={agency_types}
                onChange={props.handleChangeTwoSelect}
              />
              {props.validator.message(
                'Tipo de Agencia de Viajes',
                props.modal_siats_two.agency_type,
                'required'
              )}
            </div>
          </>
        ) : (
          ''
        )}

        {props.modal_siats_two.booking_type == 3 ? (
          <div className="p-col-12 p-md-6 siatsInputStype1">
            <span className="p-float-label">
              <InputText
                maxLength="20"
                onChange={props.handleChangeTwo}
                value={props.modal_siats_two.booking_engine}
                name="booking_engine"
              />
              <label htmlFor="in">Nombre de Motor de Reserva</label>
            </span>
            {props.validator.message(
              'Nombre de Motor de Reserva',
              props.modal_siats_two.booking_engine,
              'required'
            )}
          </div>
        ) : (
          ''
        )}

        <div className="p-col-12 p-md-6 siatsInputStype1">
          <span className="p-float-label">
            <InputText
              maxLength="13"
              keyfilter="pint"
              onChange={props.handleChangeTwo}
              data-type="number"
              value={props.modal_siats_two.bill_number}
              name="bill_number"
            />
            <label htmlFor="in">Número del documento fiscal</label>
          </span>
          {props.validator.message('Número del documento fiscal',props.modal_siats_two.bill_number,'required')}
        </div>

        {props.modal_siats_two.bill_number == '0' ?
          <div className="p-col-12 p-md-6">
            <label className="dropDownStyle" htmlFor="in">Observaciones</label>
            <Dropdown
              filter={true}
              placeholder="Observaciones"
              value={props.modal_siats_two.observation}
              name="observation"
              options={observations}
              onChange={props.handleChangeTwoSelect}
            />
            {props.validator.message('Observaciones', props.modal_siats_two.observation, 'required')}
          </div>
          :
          ''
        }

    </div>


    <div className="title-siats">
      <h3>Registro Siat III</h3>
    </div>
    <div className="p-grid p-fluid p-2">
      <div className="p-col-12 p-md-6">
        <label className="dropDownStyle" htmlFor="in">Tipo de documento fiscal</label>
        <Dropdown
          filter={true}
          placeholder="Tipo de documento fiscal"
          value={props.modal_siats_three.doc_type}
          name="doc_type"
          options={fiscal_document_types}
          onChange={props.handleChangeThreeSelect}
        />
        {props.validator.message('Tipo de documento fiscal',props.modal_siats_three.doc_type,'required')}
      </div>

      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            keyfilter="pint"
            maxLength="13"
            onChange={props.handleChangeThree}
            value={props.modal_siats_three.doc_number}
            name="doc_number"
            data-type="number"
          />
          <label htmlFor="in">Número del documento fiscal</label>
        </span>
        {props.validator.message('número del documento fiscal',props.modal_siats_three.doc_number,'required')}
      </div>

      <div className="p-col-12 p-md-12 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            keyfilter={/[a-z0-9\-]/i}
            // "alphanum"
            onChange={props.handleChangeThree}
            value={props.modal_siats_three.unique_code}
            name="unique_code"
            data-type="number"
            maxLength="70"
          />
          <label htmlFor="in">
            Código único de Facturación (CUF) o Código de Autorización de
            emisión de Documentos Fiscales (CAED)
          </label>
        </span>
        <div id="message_edit">
        {
          props.validator.message(
            'Código único de Facturación (cuf) o Código de Autorización de emisión de Documentos Fiscales (caed) es obligatorio, al no tener el código debe ingresar 0 para continuar.',
            props.modal_siats_three.unique_code,
            'new_required'
          )
        }
        </div>
      </div>

      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            maxLength="70"
            keyfilter={/[a-z0-9\-]/i}
            onChange={props.handleChangeThree}
            value={props.modal_siats_three.code_daily}
            disabled={
              props.modal_siats_three.unique_code == null || props.modal_siats_three.unique_code[0] == "0" ?
                true
                :
                false
            }
            name="code_daily"
            data-type="number"
          />
          <label htmlFor="in">Código Único de Facturación Diaria.</label>
        </span>
        {props.validator.message('código único de facturación diaria', props.modal_siats_three.code_daily, 'required')}
        {props.validator.message('', props.modal_siats_three.code_daily, 'invalid_value')}
      </div>

      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            keyfilter="pnum"
            maxLength="15"
            onChange={props.handleChangeThree}
            value={props.modal_siats_three.amount_charged}
            name="amount_charged"
            data-type="number"
          />
          <label htmlFor="in">Importe cobrado al huésped</label>
        </span>
        {props.validator.message('Importe cobrado al huésped',props.modal_siats_three.amount_charged,'required')}
      </div>

      <div className="p-col-12 p-md-6">
        <label className="dropDownStyle" htmlFor="in">Medio de Pago</label>
        <Dropdown
          filter={true}
          placeholder="Medio de Pago"
          value={props.modal_siats_three.payment_type}
          name="payment_type"
          options={payment_types}
          onChange={props.handleChangeThreeSelect}
        />
        {/*if you add new options payment_types pleas check exel of this siat*/}
        {props.validator.message('Medio de Pago', props.modal_siats_three.payment_type, 'required')}
      </div>

      {props.modal_siats_three.payment_type == 7 ? (
          <div className="p-col-12 p-md-6 siatsInputStype1">
            <span className="p-float-label">
              <InputText
                maxLength="250"
                // keyfilter="alphanum"
                onChange={props.handleChangeThree}
                value={props.modal_siats_three.payment_desc}
                name="payment_desc"
                data-type="number"
              />
              <label htmlFor="in">Descripción del medio de pago (otros)</label>
            </span>
            {props.validator.message('Descripción del medio de pago (otros)',props.modal_siats_three.payment_desc,'required')}
          </div>
        ) : (
          ''
        )}

      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            maxLength="14"
            keyfilter="pint"
            onChange={props.handleChangeThree}
            value={props.modal_siats_three.nit}
            name="nit"
            data-type="number"
          />
          <label htmlFor="in">
            NIT o CI del beneficiario del Documento fiscal
          </label>
        </span>
        {props.validator.message('nit o ci del beneficiario del documento fiscal', props.modal_siats_three.nit, 'required')}
      </div>
    </div>

    <div className="title-siats">
      <h3>Registro Siat IV</h3>
    </div>
    <div className="p-grid p-fluid p-4">
      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            maxLength="14"
            onChange={props.handleChangeFour}
            value={props.modal_siats_four.agency_nit}
            keyfilter="pint"
            name="agency_nit"
            data-type="number"
          />
          <label htmlFor="in">
            NIT de la agencia de viajes con residencia en el país u otros.
          </label>
        </span>
        {props.validator.message('nit de la agencia de viajes con residencia en el país',props.modal_siats_four.agency_nit,'required')}
      </div>

      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            maxLength="250"
            onChange={props.handleChangeFour}
            value={props.modal_siats_four.agency_name}
            data-type="number"
            name="agency_name"
          />
          <label htmlFor="in">Nombre de la Agencia de Viajes u otros.</label>
        </span>
        {props.validator.message('Nombre de la Agencia de Viajes u otros',props.modal_siats_four.agency_name,'required')}
      </div>

      <div className="p-col-12 p-md-6 siatsInputStype1">
        <span className="p-float-label">
          <InputText
            keyfilter="pnum"
            maxLength="17"
            onChange={props.handleChangeFour}
            value={props.modal_siats_four.commission}
            name="commission"
            data-type="number"
          />
          <label htmlFor="in">
            Importe comisión de la agencia de viajes u otros
          </label>
        </span>
        {props.validator.message('Importe comisión de la agencia de viajes u otros',props.modal_siats_four.commission,'required')}
      </div>

      <div className="p-col-12 p-md-6">
        <label className="dropDownStyle" htmlFor="in">Forma de Pago de la comisión a la agencia de viajes u otros</label>
        <Dropdown
          filter={true}
          filterPlaceholder="Forma de pago de la comisión"
          placeholder="Forma de Pago de la comisión a la agencia de viajes u otros"
          value={props.modal_siats_four.payment_type}
          name="payment_type"
          options={siat4_payment_types}
          onChange={props.handleChangeFourSelect}
        />
        {/*if you add new options to payment_type pleas check exel of this siat*/}
        {props.validator.message('Forma de Pago de la comisión a la agencia de viajes u otros',props.modal_siats_four.payment_type,'required')}
      </div>

      {props.modal_siats_four.payment_type == 6 ? (
          <div className="p-col-12 p-md-6 siatsInputStype1">
            <span className="p-float-label">
              <InputText
                maxLength="150"
                onChange={props.handleChangeFour}
                value={props.modal_siats_four.payment_desc}
                name="payment_desc"
              />
              <label htmlFor="in">Descripción forma de pago (otros)</label>
            </span>
            {props.validator.message(
              'Descripción forma de pago (otros)',
              props.modal_siats_four.payment_desc,
              'required'
            )}
          </div>
        ) : (
          ''
        )}


        {
          ( (props.modal_siats_four.payment_type == 4) || (props.modal_siats_four.payment_type == 5) ) ?
          <div className="p-col-12 p-md-6 siatsInputStype1">
            <span className="p-float-label">
              <InputText
                maxLength="250"
                onChange={props.handleChangeFour}
                value={props.modal_siats_four.financial_entity_name}
                name="financial_entity_name"
              />
              <label htmlFor="in">
                Nombre de la Entidad de Intermediación Financiera
              </label>
            </span>
            {props.validator.message(
              'Nombre de la Entidad de Intermediación Financiera',
              props.modal_siats_four.financial_entity_name,
              'required'
            )}
          </div>
          :
          <></>

        }
      </div>
  </ScrollPanel>
  </Dialog>
  );
};

export default Form;
