import React from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { es } from "../../../../config/translations";
import { Dropdown } from "primereact/dropdown";

import PersonFormView from "../components/PersonFormView";

function GuestFormView(props) {
  const { arrival, departure, origin, room, preload, guest_id, person_id } = props.form;

  function disabled_conditions(){
    if (props.form.m_type == "day_use" || props.state_edit_finish_entry){
      return false
    }else{
      return preload_disabled() || this_person_has_guest_records()
    }
  }

  function preload_disabled(){
    return preload;
  }

  function this_person_has_guest_records(){
    var condition = props.this_person_has_guest_records
    if((props.part_id == null || guest_id == null) || (props.state == 1 && preload == false)){
      return false
    }

    if(props.form.last_guest_record_id != null){
      return true
    }

    if (props.state == 1){
      return false
    }else{
      return condition
    }
  }

  function person_method(){
    return ((props.this_person_has_guest_records && preload == false) || props.form.last_guest_record_id != null) || (props.state==0 && props.form.m_type != null)
  }

  // var mindate = new Date(new Date(new Date()).setHours(0, 0, 0, 0))
  const today1 = new Date(arrival);
  const time_tomorrow = today1.setDate(today1.getDate() + 0);
  let tomorrow = new Date(time_tomorrow);
  if (arrival === ""){
      tomorrow = null;
  }

  return (
    <div className="p-grid p-fluid">

      {
        ((props.form.m_type == "permanencia" || props.form.m_type == "egreso") && props.state==0) || (disabled_conditions() && props.state==0)?
        <p className="p-col-12 p-md-12 remove_space_of_legend">*El parte de este registro ya se encuentra finalizado, por tal motivo no es posible modificar los datos del huésped</p>
        :
        <>
        {
          ((props.form.m_type != "permanencia" || props.form.m_type != "egreso") && (!props.editable_state_person && props.editable_state_person != null))?
          <p className="p-col-12 p-md-12 remove_space_of_legend">*Los datos del huesped ya no se encuentran habilitados para su edición</p>
          :
          ""
        }
        </>
      }

      <div className="p-col-12 p-md-6 py-6">
        <span className="p-float-label">
          <Calendar
            readOnlyInput={true}
            onChange={props.handleChange}
            locale={es}
            value={arrival}
            disabled={preload_disabled() || this_person_has_guest_records() }
            maxDate={new Date()}
            dateFormat="dd/mm/yy"
            name="arrival"
          />
          <label htmlFor="in">Fecha de ingreso</label>
        </span>
        {props.validator.message("Fecha de ingreso", arrival, "required")}
      </div>
      <div className="p-col-12 p-md-6">
        <span className="p-float-label">
          <Calendar
            readOnlyInput={true}
            onChange={props.handleChange}
            locale={es}
            minDate={tomorrow}
            // minDate={ mindate }
            value={departure}
            name="departure"
            dateFormat="dd/mm/yy"
            disabled = {props.validate_edit_departure}
          />
          <label htmlFor="in">Fecha de salida</label>
        </span>
        {props.validator.message("Fecha de salida", departure, "required")}
      </div>

      <div className="p-col-12 p-md-6" style={{ marginTop: "17px" }}>
        <span className="p-float-label">
          <InputText
            onChange={props.handleChange}
            value={room}
            name="room"
            maxLength="5"
            keyfilter={/^[a-zA-Z0-9]+$/}
          />
          <label htmlFor="in">Número de habitación</label>
        </span>
        {props.validator.message("Número de habitación", room, "required")}
      </div>

      <div className="p-col-12 p-md-6">
        <p style={{ margin: "0px", color: "#898989", fontSize: "12px" }}>
          País de procedencia
        </p>
        <Dropdown
            readOnlyInput={true}
            filter={true}
          optionLabel="name"
          value={origin}
          name="origin"
          disabled={disabled_conditions()}
          options={props.countries}
          onChange={props.handleChange}
        />
        {props.validator.message("País de prodecencia", origin, "required")}
      </div>

      <PersonFormView
        error_civil_status={props.error_civil_status}
        handleChange={props.handleChange}
        handleOnBlur={props.handleOnBlur}
        form={props.form}
        countries={props.countries}
        nationalities={props.nationalities}
        validator={props.validator}
        person_method={person_method()}
        can_destroy = {props.can_destroy}
        state_edit_finish_entry= {props.state_edit_finish_entry}
        editable_person= {!props.editable_state_person}
        quantity_daily_reports_finished={props.quantity_daily_reports_finished}
      />
    </div>
  );
}

export default GuestFormView;
