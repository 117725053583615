import React from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { es } from "../../../../config/translations";
import { Dropdown } from "primereact/dropdown";

function PersonFormView(props) {
  const {
    country_id,
    nationality_id,
    profession,
    document,
    doc_type,
    birthdate,
    name,
    middle_name,
    last_name,
    preload,
    disabled,
    m_type,
    civil_status,
  } = props.form;

  function disabled_person_method(){
    if(props.quantity_daily_reports_finished != null && props.editable_person && props.form.guest_id != null){
      return true
    }
    var disabled_person = false;
    if(props.can_destroy!= null && !props.can_destroy){
      disabled_person = true
    }
    if(m_type == "day_use" || props.state_edit_finish_entry){
      return false
    }else{
      return preload || disabled_person
    }
  }

  function doc_disabled_person_method(){
    if(m_type == "day_use" || props.state_edit_finish_entry || props.form.document == ""){
      return false
    }else{
      return person_method
    }
  }

  function value_middle_name(){
    if( props.form.middle_name == "" && disabled_person_method() ){
      return " "
    }else{
      return props.form.middle_name
    }
  }
  const { person_method } = props;
  const doctTypes = [
    { label: "Pasaporte", value: 1 },
    { label: "Cédula de identidad", value: 2 }
  ];
  const civil_status_options = [
    { label: "S.E.", value: null },
    { label: "Soltero (a)", value: "Soltero (a)" },
    { label: "Casado (a)", value: "Casado (a)" },
    { label: "Viudo (a)", value: "Viudo (a)" },
    { label: "Divorciado (a)", value: "Divorciado (a)" },
    { label: "Unión libre", value: "Unión libre" }
  ]
  const labelStyle = {
    margin: "0px",
    color: "#898989",
    fontSize: "12px"
  };
  const containerStyle = {
    margin: "5px 0px"
  };

  const distanceStyle ={
    marginTop: "17px"
  };
  return (
    <div className="p-grid p-fluid" style={{ width: "100%" }}>
      <div className="p-col-12 p-md-6" style={containerStyle, distanceStyle}>
        <span className="p-float-label">
          <InputText
            value={document}
            name="document"
            keyfilter={/^[a-zA-Z0-9-.]*$/}
            disabled={doc_disabled_person_method()}
            onChange={props.handleChange}
            onBlur={props.handleOnBlur}
            maxLength="15"
          />
          <label htmlFor="in">Número de documento</label>
        </span>
        {props.validator.message("Número de documento", document, "required")}
      </div>
      <div className="p-col-12 p-md-6" style={containerStyle, distanceStyle}>
        <span className="p-float-label">
          <InputText
            value={name}
            disabled={disabled_person_method()}
            name="name"
            maxLength="30"
            onChange={props.handleChange}
          />
          <label htmlFor="in">Nombre</label>
        </span>
        {props.validator.message('Nombre', name, 'required|space_invalid')}
      </div>
      <div className="p-col-12 p-md-6" style={containerStyle, distanceStyle}>
        <span className="p-float-label">
          <InputText
            value={last_name}
            name="last_name"
            maxLength="30"
            disabled={disabled_person_method()}
            onChange={props.handleChange}
          />
          <label htmlFor="in">Apellido paterno</label>
        </span>
        {props.validator.message("Apellido paterno", last_name, "required|space_invalid")}
      </div>

      <div className="p-col-12 p-md-6 mt-5" style={containerStyle, distanceStyle}>
        <span className="p-float-label">
          <InputText
            onChange={props.handleChange}
            name="middle_name"
            maxLength="30"
            disabled={disabled_person_method()}
            value={value_middle_name()}
            // value={middle_name}
          />
          <label htmlFor="in">Apellido materno</label>
        </span>
        {props.validator.message("Apellido materno", middle_name, "space_invalid")}
      </div>

      <div className="p-col-12 p-md-6" style={containerStyle, distanceStyle}>
        <span className="p-float-label">
          <Calendar
              readOnlyInput={true}
              onChange={props.handleChange}
            name="birthdate"
            locale={es}
            value={birthdate}
            disabled={disabled_person_method()}
            dateFormat="dd/mm/yy"
            monthNavigator={true}
            yearNavigator={true}
            maxDate={new Date()}
            yearRange={`${new Date().getFullYear() -
              150}:${new Date().getFullYear()}`}
          />
          <label htmlFor="in">Fecha de nacimiento</label>
        </span>
        {props.validator.message("Fecha de nacimiento", birthdate, "required")}
      </div>
      <div className="p-col-12 p-md-6" style={containerStyle, distanceStyle}>
        <span className="p-float-label">
          <InputText
            value={profession}
            disabled={disabled_person_method()}
            name="profession"
            onChange={props.handleChange}
            maxLength="30"
          />
          <label htmlFor="in">Profesión</label>
        </span>
        {props.validator.message("Profesión", profession, "required|space_invalid_cargo")}
      </div>
      <div className="p-col-12 p-md-6" style={containerStyle}>
        <p style={labelStyle}>Tipo de documento</p>
        <Dropdown
          value={doc_type}
          name="doc_type"
          options={doctTypes}
          disabled={disabled_person_method()}
          onChange={props.handleChange}
          placeholder="Tipo de documento"
        />
        {props.validator.message("Tipo de documento", doc_type, "required")}
      </div>
      <div className="p-col-12 p-md-6" style={containerStyle}>
        <p style={labelStyle}>País de nacimiento</p>
        <Dropdown
          onChange={props.handleChange}
          filter={true}
          optionLabel="name"
          name="country_id"
          value={country_id}
          disabled={disabled_person_method()}
          options={props.countries}
          placeholder="País"
        />
        {props.validator.message("País", country_id, "required")}
      </div>
      <div className="p-col-12 p-md-6" style={containerStyle}>
        <p style={labelStyle}>Nacionalidad</p>
        <Dropdown
          onChange={props.handleChange}
          filter={true}
          optionLabel="name"
          name="nationality_id"
          value={nationality_id}
          disabled={disabled_person_method()}
          options={props.nationalities}
          placeholder="Nacionalidad"
        />
        {props.validator.message("Nacionalidad", nationality_id, "required")}
      </div>

      <div className="p-col-12 p-md-6" style={containerStyle}>
        <p style={labelStyle}>Estado Civil</p>
        <Dropdown
          value={civil_status}
          name="civil_status"
          options={civil_status_options}
          disabled={disabled_person_method()}
          onChange={props.handleChange}
          placeholder="Estado Civil"
        />
        {props.error_civil_status}
        {/*props.validator.message("Estado Civil", civil_status, "required")*/}
      </div>
    </div>
  );
}

export default PersonFormView;
