import { DashboardTypes } from "../types";
import { DashboardEndPoints, MainURL } from "../endpoints";
import axios from "axios";

const dashBoardInfoGetBegin = () => ({
  type: DashboardTypes.DASHBOARD_GET_REQUEST
});

const dashboardInfoGetSuccess = information => ({
  type: DashboardTypes.DASHBOARD_GET_SUCCESS,
  payload: { information }
});

const dashboardInfoGetFailure = error => ({
  type: DashboardTypes.DASHBOARD_GET_FAILURE,
  payload: { error }
});

export const getDashboardInformation = token => {
  return dispatch => {
    dispatch(dashBoardInfoGetBegin());
    return axios
      .get(MainURL + DashboardEndPoints.DASHBOARD_INFO_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(dashboardInfoGetSuccess(response.data));
      })
      .catch(error => { 
        dispatch(dashboardInfoGetFailure(error.response));
      });
  };
};
