import React from "react";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

const RoomForm = props => {
  const { name, quantity,quantitybeds, id } = props.form;
  const footer = (
    <div>
      <Button
        label={id ? "Actualizar" : "Guardar"}
        icon="pi pi-check"
        onClick={props.handleSubmit}
      />
      <Button
        label="Cancelar"
        className="p-button-danger"
        icon="pi pi-times"
        onClick={props.closeModal}
      />
    </div>
  );
  return (
    <Dialog
      header={id ? "Actualizar habitación" : "Nueva habitación"}
      footer={footer}
      visible={props.visible}
      style={{ width: "50vw" }}
      modal={true}
      onHide={props.closeModal}
    >
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-6">
          <span className="p-float-label">
            <InputText 
              onChange={props.handleChange} 
              value={name ? name : ''} 
              maxLength="50"
              name="name" 
            />
            <label htmlFor="in">Descripción</label>
          </span>
          {props.validator.message("Descripción", name, "required")}
       </div>

        <div className="p-col-12 p-md-6">
          <span className="p-float-label">
            <InputText
              onChange={props.handleChange}
              value={quantity ? quantity : ''}
              keyfilter="pint"
              maxLength="4"
              name="quantity"
            />
            <label htmlFor="in">Cantidad de habitaciones</label>
          </span>
          {props.validator.message("Cantidad de habitaciones", quantity, "required|zero_invalid")}
         </div>         
      </div>
      
      <div className="p-grid-new p-fluid">
         <div className="p-col-12 p-md-6">
          <span className="p-float-label">
            <InputText
              onChange={props.handleChange}
              value={quantitybeds ? quantitybeds : ''}
              keyfilter="pint"
              maxLength="4"
              name="quantitybeds"
            />
            <label htmlFor="in">Cantidad de camas</label>
          </span>
          {props.validator.message("Cantidad de camas", quantitybeds, "required|zero_invalid")}
         </div>
         
      </div>

    </Dialog>
  );
};

export default RoomForm;
