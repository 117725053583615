import React, { useRef } from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { MainURL } from "../../redux/endpoints";
import {Menu} from 'primereact/menu';

const Siat5Table = props => {
  let menu = useRef(null);
  let header = (
    <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
      SIAT 
    </div>
  );
  let footer = (props) =>{
    if(props.nextDateDailyReport != null){
      if(props.nextDateDailyReport.start_operation == true){
        // return (
        //   <div className="p-clearfix" style={{ width: "100%" }}>
        //     <Button
        //       style={{ float: "left" }}
        //       label="Agregar SIAT II"
        //       icon="pi pi-plus"
        //       onClick={props.add}
        //     />
        //   </div>
        // );
      }
    }
  }
  let button_edit =(rowData)=> {
    // if(rowData.state != 1)
    return(
      <Button
        onClick={() => {
          props.edit(rowData.id);
        }}
        type="button"
        icon="pi pi-pencil"
        style={{ marginRight: ".5em" }}
        className="p-button-warning"
      ></Button>
    );
  }

  const actionTemplate = (rowData, column) => {
    let items_mm = [
      {
        label: 'Descarga SIAT II txt',
        icon: 'pi pi-file',
        command: e => {
          var elems = document.getElementsByClassName("menu_download_2");
          if(elems.length == 1){
            elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
          }
          window.open(MainURL + "/report/siat_two_text/" + rowData.id, "_self");

        }
      },
      {
        label: 'Descarga SIAT II xlsx',
        icon: 'pi pi-file-excel',
        command: e => {
          var elems = document.getElementsByClassName("menu_download_2");
          if(elems.length == 1){
            elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
          }
          window.open(MainURL + "/report/siat_two/" + rowData.id, "_self");
        }
      },
      {
        label: 'Descarga SIAT III txt',
        icon: 'pi pi-file',
        command: e => {
          var elems = document.getElementsByClassName("menu_download_2");
          if(elems.length == 1){
            elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
          }
          window.open(MainURL + "/report/siat_three_text/" + rowData.id, "_self");
        }
      },
      {
        label: 'Descarga SIAT III xlsx',
        icon: 'pi pi-file-excel',
        command: e => {
          var elems = document.getElementsByClassName("menu_download_2");
          if(elems.length == 1){
            elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
          }
          window.open(MainURL + "/report/siat_three/" + rowData.id, "_self");

        }
      },
      {
        label: 'Descarga SIAT IV txt',
        icon: 'pi pi-file',
        command: e => {
          var elems = document.getElementsByClassName("menu_download_2");
          if(elems.length == 1){
            elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
          }
          window.open(MainURL + "/report/siat_four_text/" + rowData.id, "_self");
        }
      },
      {
        label: 'Descarga SIAT IV xlsx',
        icon: 'pi pi-file-excel',
        command: e => {
          var elems = document.getElementsByClassName("menu_download_2");
          if(elems.length == 1){
            elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
          }
          window.open(MainURL + "/report/siat_four/" + rowData.id, "_self");
        }
      }
    ]

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Button
          onClick={() =>{
              var elems = document.getElementsByClassName("p-menu-overlay-visible");
              if(elems.length == 1){
                elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
              }
              props.show(rowData)
            }
          }
          type="button"
          icon="pi pi-search"
          className="p-button-success"
          style={{ marginRight: ".5em" }}
        ></Button>

        {button_edit(rowData)}
        <Button id={rowData.id} icon="pi pi-download" onClick={(event) => {
            var elems = document.getElementsByClassName("p-menu-overlay-visible");


            if(elems.length == 1)
            {
              if( elems[0].id != "popup_menu"+event.currentTarget.id ){
                elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
                document.getElementById("popup_menu"+event.currentTarget.id).setAttribute("class", "p-menu p-component menu_download_2 p-menu-dynamic p-menu-overlay p-menu-overlay-visible");
              }
              else{
                elems[0].className = 'p-menu p-component p-menu-dynamic p-menu-overlay';
              }
            }
            else{
              document.getElementById("popup_menu"+event.currentTarget.id).setAttribute("class", "p-menu p-component menu_download_2 p-menu-dynamic p-menu-overlay p-menu-overlay-visible");
            }
          }
        } aria-controls={"popup_menu"+rowData.id} aria-haspopup={true} />
        <Menu model={items_mm} popup={true} ref={menu} id={"popup_menu"+rowData.id}/>

      </div>
    );
  };

  return (
    <DataTable
      header={header}
      footer={footer(props)}
      value={props.data}
      paginator={true}
      rows={10}
      rowsPerPageOptions={[5, 10, 20]}
      emptyMessage="No tienes siats registrados"
    >
      <Column field="date"
        header="Fecha de Creación"
        style={{textAlign:'center', width: '20%', paddingRight: '0', paddingLeft: '0'}}
      />
      <Column
        body={rowData => {
          return rowData.state === 0?
            <div className="badge_succes">No Finalizado</div>
            : <div className="badge_danger">Finalizado</div>;
        }}
        header="Estado"
        style={{textAlign:'center', width: '20%', paddingRight: '0', paddingLeft: '0'}}
      />
      <Column field="nit"
        header="NIT del contribuyente"
        style={{textAlign:'center', width: '40%', paddingRight: '0', paddingLeft: '0'}}
      />
      <Column field="branch"
        header="Casa matriz o sucursal"
        style={{textAlign:'center', width: '20%', paddingRight: '0', paddingLeft: '0'}}
      />
      <Column
        header="Acciones"
        body={actionTemplate}
        style={{ width: "14em" }}
      />
    </DataTable>

  );
};

export default Siat5Table;
