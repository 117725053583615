export const UnPleasantTypes = {
  UNPLEASANT_GET_REQUEST: "UNPLEASANT_GET_REQUEST",
  UNPLEASANT_GET_SUCCESS: "UNPLEASANT_GET_SUCCESS",
  UNPLEASANT_GET_FAILURE: "UNPLEASANT_GET_FAILURE",

  UNPLEASANT_CREATE_REQUEST: "UNPLEASANT_CREATE_REQUEST",
  UNPLEASANT_CREATE_SUCCESS: "UNPLEASANT_CREATE_SUCCESS",
  UNPLEASANT_CREATE_FAILURE: "UNPLEASANT_CREATE_FAILURE",

  UNPLEASANT_DELETE_REQUEST: "UNPLEASANT_DELETE_REQUEST",
  UNPLEASANT_DELETE_SUCCESS: "UNPLEASANT_DELETE_SUCCESS",
  UNPLEASANT_DELETE_FAILURE: "UNPLEASANT_DELETE_FAILURE",

  UNPLEASANT_EDIT_REQUEST: "UNPLEASANT_EDIT_REQUEST",
  UNPLEASANT_EDIT_SUCCESS: "UNPLEASANT_EDIT_SUCCESS",
  UNPLEASANT_EDIT_FAILURE: "UNPLEASANT_EDIT_FAILURE"
};
