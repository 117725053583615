import { SiatTypes } from "../types";
import {
  establishment_categories,
  register_models,
  establishment_types
} from "../../config/models";

const initialState = {
  siats: [],
  redirect: false,
  error: null
};

export default function siatReducer(state = initialState, action) {
  switch (action.type) {
    case SiatTypes.SIAT_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SiatTypes.SIAT_GET_SUCCESS:
      let siats_array = action.payload.siats;
      for (let i = 0; i < siats_array.length; i++) {
        const { category, reg_mod, category_type } = siats_array[i];
        siats_array[i].category_desc = getCategoryDesc(category);
        siats_array[i].reg_mod_desc = getRegisterModel(reg_mod);
        siats_array[i].establishment_type_desc = getEstablishmentTypeDesc(
          category_type
        );
      }
      return {
        ...state,
        siats: siats_array,
        redirect: true,
        error: null
      };

    case SiatTypes.SIAT_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        siats: []
      };

    case SiatTypes.SIAT_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SiatTypes.SIAT_CREATE_SUCCESS:
      let siat = action.payload.siat;
      const { category, reg_mod, category_type } = siat;
      siat.category_desc = getCategoryDesc(category);
      siat.reg_mod_desc = getRegisterModel(reg_mod);
      siat.establishment_type_desc = getEstablishmentTypeDesc(category_type);
      return {
        ...state,
        redirect: true,
        siats: [].concat(siat, state.siats)
      };

    case SiatTypes.SIAT_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case SiatTypes.SIAT_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SiatTypes.SIAT_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true,
        siats: state.siats.filter(siat => siat.id !== action.payload.siat_id)
      };

    case SiatTypes.SIAT_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case SiatTypes.SIAT_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SiatTypes.SIAT_EDIT_SUCCESS:
      let updated_siat = action.payload.siat;
      updated_siat.category_desc = getCategoryDesc(updated_siat.category);
      updated_siat.reg_mod_desc = getRegisterModel(updated_siat.reg_mod);
      updated_siat.establishment_type_desc = getEstablishmentTypeDesc(
        updated_siat.category_type
      );
      return {
        ...state,
        redirect: true,
        siats: state.siats.map(siat => {
          if (siat.id === updated_siat.id) {
            return updated_siat;
          } else {
            return siat;
          }
        })
      };
    case SiatTypes.SIAT_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      return state;
  }
}

const getRegisterModel = reg_mod => {
  let reg_mod_array = register_models.filter(
    reg_model => reg_model.value === reg_mod
  );
  if (reg_mod_array.length > 0) {
    return reg_mod_array[0].label;
  }
  return "";
};

const getCategoryDesc = category_id => {
  let category_array = establishment_categories.filter(
    category => category.value === category_id
  );
  if (category_array.length > 0) {
    return category_array[0].label;
  }
  return "";
};

const getEstablishmentTypeDesc = establishment_types_id => {
  let establishment_types_array = establishment_types.filter(
    establishment_types => establishment_types.value === establishment_types_id
  );
  if (establishment_types_array.length > 0) {
    return establishment_types_array[0].label;
  }
  return "";
};
