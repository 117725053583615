export const SIAT4Types = {
  SIAT4_GET_REQUEST: "SIAT4_GET_REQUEST",
  SIAT4_GET_SUCCESS: "SIAT4_GET_SUCCESS",
  SIAT4_GET_FAILURE: "SIAT4_GET_FAILURE",

  SIAT4_GETBYID_REQUEST: "SIAT4_GETBYID_REQUEST",
  SIAT4_GETBYID_SUCCESS: "SIAT4_GETBYID_SUCCESS",
  SIAT4_GETBYID_FAILURE: "SIAT4_GETBYID_FAILURE",

  SIAT4_CREATE_REQUEST: "SIAT4_CREATE_REQUEST",
  SIAT4_CREATE_SUCCESS: "SIAT4_CREATE_SUCCESS",
  SIAT4_CREATE_FAILURE: "SIAT4_CREATE_FAILURE",

  SIAT4_DELETE_REQUEST: "SIAT4_DELETE_REQUEST",
  SIAT4_DELETE_SUCCESS: "SIAT4_DELETE_SUCCESS",
  SIAT4_DELETE_FAILURE: "SIAT4_DELETE_FAILURE",

  SIAT4_EDIT_REQUEST: "SIAT4_EDIT_REQUEST",
  SIAT4_EDIT_SUCCESS: "SIAT4_EDIT_SUCCESS",
  SIAT4_EDIT_FAILURE: "SIAT4_EDIT_FAILURE",

  SIAT4_SETINITIAL_SUCCESS: "SIAT4_SETINITIAL_SUCCESS",

  SIAT4_LOCALCREATE_SUCCESS: "SIAT4_LOCALCREATE_SUCCESS",

  SIAT4_LOCALDELETE_SUCCESS: "SIAT4_LOCALDELETE_SUCCESS",

  SIAT4_LOCALEDIT_SUCCESS: "SIAT4_LOCALEDIT_SUCCESS",

  SIAT4_EDIT_RECORD_REQUEST: "SIAT4_EDIT_RECORD_REQUEST",
  SIAT4_EDIT_RECORD_SUCCESS: "SIAT4_EDIT_RECORD_SUCCESS",
  SIAT4_EDIT_RECORD_FAILURE: "SIAT4_EDIT_RECORD_FAILURE",
};
