import { UnPleasantTypes } from "../types";
import { UnPleasantEndpoints, MainURL } from "../endpoints";
import axios from "axios";

const unpleasantGetBegin = () => ({
  type: UnPleasantTypes.UNPLEASANT_GET_REQUEST
});

const unpleasantGetSuccess = unpleasants => ({
  type: UnPleasantTypes.UNPLEASANT_GET_SUCCESS,
  payload: { unpleasants }
});

const unpleasantGetFailure = error => ({
  type: UnPleasantTypes.UNPLEASANT_GET_FAILURE,
  payload: { error }
});

export const getUnpleasants = token => {
  return dispatch => {
    dispatch(unpleasantGetBegin());
    return axios
      .get(MainURL + UnPleasantEndpoints.UNPLEASANT_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(unpleasantGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(unpleasantGetFailure(error.response));
      });
  };
};

const unpleasantCreateBegin = () => ({
  type: UnPleasantTypes.UNPLEASANT_CREATE_REQUEST
});

const unpleasantCreateSuccess = unpleasant => ({
  type: UnPleasantTypes.UNPLEASANT_CREATE_SUCCESS,
  payload: { unpleasant, success: "Huésped no grato creado exitosamente" }
});

const unpleasantCreateFailure = error => ({
  type: UnPleasantTypes.UNPLEASANT_CREATE_FAILURE,
  payload: { error }
});

export const createUnpleasant = (token, data) => {
  return dispatch => {
    dispatch(unpleasantCreateBegin());
    return axios
      .post(MainURL + UnPleasantEndpoints.UNPLEASANT_ENDPOINT, data, {
        headers: {
          Accept: "multipart/form-data",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(unpleasantCreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(unpleasantCreateFailure(error.response));
      });
  };
};

const unpleasantDeleteBegin = () => ({
  type: UnPleasantTypes.UNPLEASANT_DELETE_REQUEST
});

const unpleasantDeleteSuccess = UNPLEASANT_id => ({
  type: UnPleasantTypes.UNPLEASANT_DELETE_SUCCESS,
  payload: { UNPLEASANT_id, success: "Huésped no grato eliminado exitosamente" }
});

const unpleasantDeleteFailure = error => ({
  type: UnPleasantTypes.UNPLEASANT_DELETE_FAILURE,
  payload: { error }
});

export const deleteUnpleasant = (token, UNPLEASANT_id) => {
  return dispatch => {
    dispatch(unpleasantDeleteBegin());
    return axios
      .delete(
        MainURL +
          UnPleasantEndpoints.UNPLEASANT_ENDPOINT_DELETE +
          `/${UNPLEASANT_id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(unpleasantDeleteSuccess(UNPLEASANT_id));
      })
      .catch(error => {
        dispatch(unpleasantDeleteFailure(error.response));
      });
  };
};

const unpleasantEditBegin = () => ({
  type: UnPleasantTypes.UNPLEASANT_EDIT_REQUEST
});

const unpleasantEditSuccess = unpleasant => ({
  type: UnPleasantTypes.UNPLEASANT_EDIT_SUCCESS,
  payload: { unpleasant, success: "Huésped no grato actualizado exitosamente" }
});

const unpleasantEditFailure = error => ({
  type: UnPleasantTypes.UNPLEASANT_EDIT_FAILURE,
  payload: { error }
});

export const editUnpleasant = (token, data, id) => {
  return dispatch => {
    dispatch(unpleasantEditBegin());
    return axios
      .put(
        MainURL +
          UnPleasantEndpoints.UNPLEASANT_ENDPOINT_DELETE +
          `/${id}.JSON`,
        data,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(unpleasantEditSuccess(response.data));
      })
      .catch(error => {
        dispatch(unpleasantEditFailure(error.response));
      });
  };
};
