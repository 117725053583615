export const EstablishmentTypes = {
  ESTABLISHMENT_GET_REQUEST: 'ESTABLISHMENT_GET_REQUEST',
  ESTABLISHMENT_GET_SUCCESS: 'ESTABLISHMENT_GET_SUCCESS',
  ESTABLISHMENT_GET_FAILURE: 'ESTABLISHMENT_GET_FAILURE',

  ESTABLISHMENT_CREATE_REQUEST: 'ESTABLISHMENT_CREATE_REQUEST',
  ESTABLISHMENT_CREATE_SUCCESS: 'ESTABLISHMENT_CREATE_SUCCESS',
  ESTABLISHMENT_CREATE_FAILURE: 'ESTABLISHMENT_CREATE_FAILURE',

  ESTABLISHMENT_DELETE_REQUEST: 'ESTABLISHMENT_DELETE_REQUEST',
  ESTABLISHMENT_DELETE_SUCCESS: 'ESTABLISHMENT_DELETE_SUCCESS',
  ESTABLISHMENT_DELETE_FAILURE: 'ESTABLISHMENT_DELETE_FAILURE',

  ESTABLISHMENT_EDIT_REQUEST: 'ESTABLISHMENT_EDIT_REQUEST',
  ESTABLISHMENT_EDIT_SUCCESS: 'ESTABLISHMENT_EDIT_SUCCESS',
  ESTABLISHMENT_EDIT_FAILURE: 'ESTABLISHMENT_EDIT_FAILURE'
};
