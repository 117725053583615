import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { PersistGate } from "redux-persist/integration/react";
import { ProgressSpinner } from "primereact/progressspinner";
import axios from 'axios';
import { cleanAuth } from './redux/actions/AuthActions';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={
        <ProgressSpinner
          style={{ width: "25%", height: "25%", marginTop: "10%" }}
        />
      }
      persistor={persistor}
    >
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();

const UNAUTHORIZED = 401;
const NOT_ALLOWED = 403;
const NOT_ACCEPTABLE = 406;
const {dispatch} = store; // direct access to redux store.
const PATH_REDIRECT = '/login'
const PATH_ROOT = '/'
const PATH_FP = '/ForgotPassword'
const PATH_UA = '/UnlockAccount'
const PATH_RP = '/RecoveryPassword'
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if ((error.response.status === UNAUTHORIZED || error.response.status === NOT_ALLOWED || error.response.status === NOT_ACCEPTABLE) && window.location.pathname !== PATH_ROOT && window.location.pathname !== PATH_REDIRECT && window.location.pathname !== PATH_FP && window.location.pathname !== PATH_RP && window.location.pathname !== PATH_UA) {
      dispatch(cleanAuth());
    } else {
        if(error.response.status === 500 && window.location.pathname === PATH_ROOT){
          dispatch(cleanAuth());
        }
        return Promise.reject(error);
    }
  }
);
