import { AuthTypes } from "../types";
import { AuthEndpoints, MainURL } from "../endpoints";
import axios from "axios";

const userLoginBegin = () => ({
  type: AuthTypes.AUTH_LOGIN_REQUEST
});

const userLoginSuccess = credentials => ({
  type: AuthTypes.AUTH_LOGIN_SUCCESS,
  payload: { credentials }
});

const userLoginFailure = error => ({
  type: AuthTypes.AUTH_LOGIN_FAILURE,
  payload: { error }
});
const headers = {
  "Content-Type": "application/json",
  Accept: "application/json"
};

export function userLogin(email, password) {
  const data = { email, password };
  return dispatch => {
    dispatch(userLoginBegin());
    return axios
      .post(MainURL + AuthEndpoints.LOGIN_ENDPOINT, data, {
        headers: headers
      })
      .then(response => {
        dispatch(userLoginSuccess(response.data));
      })
      .catch(error => {
        dispatch(userLoginFailure(error.response));
      });
  };
}
// -----------------------------------------------------------------------------------

const changePasswordBegin = () => ({
  type: AuthTypes.CHANGE_PASSWORD_REQUEST
});

const changePasswordSuccess = credentials => ({
  type: AuthTypes.CHANGE_PASSWORD_SUCCESS,
  payload: { credentials }
});

const changePasswordFailure = error => ({
  type: AuthTypes.CHANGE_PASSWORD_FAILURE,
  payload: { error }
});

export const changePassword = (token, email, current_password, password, password_confirmation) => {
  return dispatch => {
    dispatch(changePasswordBegin());
    return axios
      .post(MainURL + AuthEndpoints.CHANGE_PASSWORD, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
        data: {
          email: email,
          current_password: current_password,
          password: password,
          password_confirmation: password_confirmation
        }
      })
      .then(response => {
        dispatch(changePasswordSuccess(response.data));
      })
      .catch(error => {
        dispatch(changePasswordFailure(error.response));
      });
  };
};

// -----------------------------------------------------------------------------------
const cleanaAuthBegin = () => ({
  type: AuthTypes.AUTH_CLEAN
});

export function cleanAuth() {
  return dispatch => {
    dispatch(cleanaAuthBegin());
  };
}
