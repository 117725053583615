import React, { Component } from "react";
import * as LogActions from "../../redux/actions/LogActions";
import * as AuthActions from "../../redux/actions/AuthActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import Table from "./components/Table";
import { ProgressSpinner } from "primereact/progressspinner";

class Index extends Component {
  componentDidMount() {
    this.props.LogActions.getLogs(this.props.token);
  }

  render() {
    if (this.props.redirect === true) {
      return <Redirect to="/" />;
    } else if (this.props.error.status === 401) {
      return <Redirect to="/login" />;
    } else
      return (
        <>
          {this.props.loading === true ? (
            <div className="p-grid p-fluid p-justify-center">
              <ProgressSpinner
                style={{ width: "20%", height: "20%", marginTop: "10%" }}
                animationDuration=".5s"
              />
            </div>
          ) : (
            <div className="p-grid p-fluid p-justify-center">
              <Table logs={this.props.logs} />
            </div>
          )}
        </>
      );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  loading: state.LoadingReducer.loading,
  logs: state.LogReducer.logs,
  error: state.ErrorReducer.error
});

const mapDispatchToProps = dispatch => ({
  LogActions: bindActionCreators(LogActions, dispatch),
  AuthActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
