import React from 'react';
import { Card } from 'primereact/card';
import { Link } from 'react-router-dom';
import {
  GetFormattedDate,
  GetFormattedDateForNewDate
} from '../../helper_java/date_format';

function Home(props) {
  const next_date = date => {
    const items = [];
    var new_date = add_day(set_date(date), 2);
    var date_now = set_date(new Date());
    while (new_date <= date_now) {
      items.push(
        <p>
          No has registrado tu parte diario de la fecha:{' '}
          {GetFormattedDateForNewDate(new_date)}
        </p>
      );
      new_date = add_day(new_date, 1);
    }
    return items;
  };

  const set_date = date => {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
  };

  const add_day = (date, number) => {
    return new Date(new Date(date).setDate(new Date(date).getDate() + number));
  };

  const text_redirect = (link, date) => (
    <div>
      <p>
        No has registrado tu parte diario de la fecha:{' '}
        <Link to={{ pathname: link, query: { date: date } }}>
          {' '}
          {GetFormattedDate(date)}{' '}
        </Link>
      </p>
      {next_date(date)}
    </div>
  );

  const nothing_daily_check = () =>
    props.daily_check.start_date_of_operation !== undefined ? (
      <Card
        title="Ninguna Actividad Pendiente"
        style={{ backgroundColor: '#b7d8b7' }}
      >
        <div style={{ display: 'flex' }}>
          <i
            className="pi pi-info-circle"
            style={{ fontSize: '3em', margin: '0px 10px' }}
          ></i>
          <p>
            La fecha de inicio de operaciones para el Establecimiento, está
            programada para el día:{' '}
            {GetFormattedDate(props.daily_check.start_date_of_operation)}
          </p>
        </div>
      </Card>
    ) : (
      <Card title="Parte Diario" style={{ backgroundColor: '#b7d8b7' }}>
        <div style={{ display: 'flex' }}>
          <i
            className="pi pi-check"
            style={{ fontSize: '3em', margin: '0px 10px' }}
          ></i>
          <p>Parte diario registrado exitosamente</p>
        </div>
      </Card>
    );

  const isParteRegistered = daily_check => {
    if (daily_check != undefined) {
      if (daily_check.next_date == null) {
        return nothing_daily_check();
      } else {
        if (daily_check.next_daily_report_id == null) {
          return text_redirect('/partes/new', daily_check.next_date);
        } else {
          return text_redirect(
            `/partes/${daily_check.next_daily_report_id}/edit`,
            daily_check.next_date
          );
        }
      }
    } else {
      return <></>;
    }
  };

  return isParteRegistered(props.daily_check);
}

export default Home;
