import React, {Component} from 'react';
import * as DailyReportActions from '../../../redux/actions/DailyReportActions';
import * as AuthActions from '../../../redux/actions/AuthActions';
import * as GuestRecordActions from '../../../redux/actions/GuestRecordActions';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Redirect} from 'react-router-dom';
// import Table from '../components/Table';
import {ProgressSpinner} from 'primereact/progressspinner';
import Show from "../components/show";
import TableDailyReports from '../components/table';
import WithMessage from "../../messaheHOC";
const TableWithMessage = WithMessage(TableDailyReports);

class Index extends Component {
  state = {
    showVisible: false,
    daily_report: null
  };
  componentDidMount() {
    this.props.DailyReportActions.getDailyReports(this.props.token, 1);
  }

  show = async(daily_report_id) => {
    await this.props.DailyReportActions.getDailyReport(this.props.token, daily_report_id);
    this.setState({
      showVisible: true,
      daily_report: this.props.dailyReport
    });
  };

  closeShow = () => {
    this.setState({
      showVisible: false,
      daily_report: null
    });
  };

  edit = daily_report_id => {
    this.props.history.push(`/partes/${daily_report_id}/edit`);
  };

  render() {
    return (
      <div>
        <TableWithMessage
          loading={this.props.loading}
          success={this.props.success}
          error={this.props.error}
          data={this.props.dailyReports}
          edit={this.edit}
          show={this.show}
          nextDateDailyReport={this.props.next_date_daily_report}
          global_parameter={this.props.global_parameter}
        />
        {this.state.daily_report != null ? (
          <Show
            visible={this.state.showVisible}
            close={this.closeShow}
            data={this.state.daily_report}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.AuthReducer.user,
  token: state.AuthReducer.token,
  redirect: state.DailyReportReducer.redirect,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error,
  success: state.LoadingReducer.success,
  next_date_daily_report: state.DailyReportReducer.next_date_daily_report,

  dailyReports: state.DailyReportReducer.dailyReports,
  dailyReport: state.DailyReportReducer.dailyReport,
  global_parameter: state.DashboardReducer.information.global_parameter,
});

const mapDispatchToProps = dispatch => ({
  DailyReportActions: bindActionCreators(DailyReportActions, dispatch),
  AuthActions: bindActionCreators(AuthActions, dispatch),
  GuestRecordActions: bindActionCreators(GuestRecordActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
