export const RoomTypes = {
  ROOM_GET_REQUEST: "ROOM_GET_REQUEST",
  ROOM_GET_SUCCESS: "ROOM_GET_SUCCESS",
  ROOM_GET_FAILURE: "ROOM_GET_FAILURE",

  ROOM_CREATE_REQUEST: "ROOM_CREATE_REQUEST",
  ROOM_CREATE_SUCCESS: "ROOM_CREATE_SUCCESS",
  ROOM_CREATE_FAILURE: "ROOM_CREATE_FAILURE",

  ROOM_DELETE_REQUEST: "ROOM_DELETE_REQUEST",
  ROOM_DELETE_SUCCESS: "ROOM_DELETE_SUCCESS",
  ROOM_DELETE_FAILURE: "ROOM_DELETE_FAILURE",

  ROOM_EDIT_REQUEST: "ROOM_EDIT_REQUEST",
  ROOM_EDIT_SUCCESS: "ROOM_EDIT_SUCCESS",
  ROOM_EDIT_FAILURE: "ROOM_EDIT_FAILURE"
};
