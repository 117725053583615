import React, { Component } from 'react';
import {NavLink} from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';

class AppSubmenu extends Component {

    static defaultProps = {
        className: null,
        nextDateDailyReport: null,
        items: null,
        // dailyReports: [],
        onMenuItemClick: null,
        root: false
    }

    static propTypes = {
        className: PropTypes.string,
        items: PropTypes.array,
        // dailyReports: PropTypes.array,
        onMenuItemClick: PropTypes.func,
        root: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {activeIndex: null};
    }

    onMenuItemClick(event, item, index) {
        //avoid processing disabled items
        if(item.disabled) {
            event.preventDefault();
            return true;
        }

        //execute command
        if(item.command) {
            item.command({originalEvent: event, item: item});
        }

        if(index === this.state.activeIndex)
            this.setState({activeIndex: null});
        else
            this.setState({activeIndex: index});

        if(this.props.onMenuItemClick) {
            this.props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

	renderLinkContent(item) {
		let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
		let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;

		return (
			<React.Fragment>
				<i className={item.icon}></i>
				<span>{item.label}</span>
				{submenuIcon}
				{badge}
			</React.Fragment>
		);
	}

	renderLink(item, i) {
		let content = this.renderLinkContent(item);

    if (item.to) {
      if(document.location.origin + item.to != document.location.href){
        return (
          <NavLink
            activeClassName="active-route"
            to={item.to}
            onClick={(e) => this.onMenuItemClick(e, item, i)}
            exact
            target={item.target}>
              {content}
          </NavLink>
        )
      }else{
        return (
          <NavLink
            activeClassName="active-route"
            to={item.to}
            onClick={(e) => this.onMenuItemClick(e, item, i)}
            exact
            target={item.target}
            replace>
              {content}
          </NavLink>
        )
      }

		}
		else {
			return (
				<a href={item.url} onClick={(e) => this.onMenuItemClick(e, item, i)} target={item.target}>
					{content}
				</a>
			);

		}
	}

    show_item = () => {
      if (this.props.nextDateDailyReport != null){
        const state = this.props.nextDateDailyReport.state_last_daily_report;
        if( (state == null && this.props.nextDateDailyReport.next_date != null) || state == "finalizado"){
          return true;
        }else{
          if (this.props.nextDateDailyReport.next_date == null) return false;
          if (this.props.nextDateDailyReport.next_date != null && this.props.nextDateDailyReport.next_daily_report_id != null) return false;
        }
      }
      return true;
    };

    show_item1 = () => {
      if(this.props.nextDateDailyReport != null){
        if(this.props.nextDateDailyReport.start_operation == true){
          return true
        }
      }
      return false
    };

    render() {
        let items = this.props.items && this.props.items.map((item, i) => {
            let active = this.state.activeIndex === i;
            let styleClass = classNames(item.badgeStyleClass, {'active-menuitem': active && !item.to});
            if ((item.to === '/partes/new') && !this.show_item()){
            }else{
              if((item.to === '/siat2/new' || item.to === '/siat3/new' || item.to === '/siat4/new') && !this.show_item1()){
              }else{
                return (
                    <li className={styleClass} key={i}>
                        {item.items && this.props.root===true && <div className='arrow'></div>}
                        {this.renderLink(item, i)}
                        <AppSubmenu items={item.items}
                                    onMenuItemClick={this.props.onMenuItemClick}
                                    // dailyReports={this.props.dailyReports}
                                    nextDateDailyReport={this.props.nextDateDailyReport}
                        />
                    </li>
                );
              }
            }

        });

        return items ? <ul className={this.props.className}>{items}</ul> : null;
    }

}

export class AppMenu extends Component {

    static defaultProps = {
        model: null,
        onMenuItemClick: null,
    }

    static propTypes = {
        model: PropTypes.array,
        onMenuItemClick: PropTypes.func
    }

    render() {
        return (
            <div className="layout-menu-container">
                <AppSubmenu
                    items={this.props.model}
                    className="layout-menu"
                    onMenuItemClick={this.props.onMenuItemClick}
                    root={true}
                    nextDateDailyReport={this.props.nextDateDailyReport}
                    // dailyReports={this.props.dailyReports}
                  />
            </div>
        );
    }
}
