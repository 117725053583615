import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { SplitButton } from "primereact/splitbutton";

class AppTopbar extends Component {
  static defaultProps = {
    onToggleMenu: null
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired
  };

  render() {
    const items = [
      {
        label: this.props.user.email
      },
      {
        label: "Cerrar sesión",
        icon: "pi pi-power-off",
        command: e => {
          this.props.logout();
        }
      }
    ];
    return (
      <div className="layout-topbar clearfix">
        <button
          className="p-link layout-menu-button"
          onClick={this.props.onToggleMenu}
        >
          <span className="pi pi-bars" />
        </button>
        <div className="layout-topbar-icons">
          {/* <span className='layout-topbar-search'>
            <InputText type='text' placeholder='Search' />
            <span className='layout-topbar-search-icon pi pi-search' />
          </span>
          <button className='p-link'>
            <span className='layout-topbar-item-text'>Events</span>
            <span className='layout-topbar-icon pi pi-calendar' />
            <span className='layout-topbar-badge'>5</span>
          </button>
          <button className='p-link'>
            <span className='layout-topbar-item-text'>Settings</span>
            <span className='layout-topbar-icon pi pi-cog' />
          </button> */}
          <div className="p-link">
            <SplitButton
              menuStyle={{ width: "250px" }}
              style={{ marginRight: "15px" }}
              label="Mi cuenta"
              icon="pi pi-user"
              model={items}
              className="p-button-secondary"
            ></SplitButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.AuthReducer.user
});

export default connect(mapStateToProps)(AppTopbar);
