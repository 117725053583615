import { SIAT5Types } from "../types";

const initialState = {
  siats5: [],
  siat: null,
  records: [],
  modal_siats_two: null,
  modal_siats_three: null,
  modal_siats_four: null,
  redirect: false,
  error: null,
  guest_record: null,
};

const parseStringToDate = stringDate => {
  if (stringDate == null) return null;
  let from = stringDate.split("-");
  return new Date(from[0], from[1] - 1, from[2]);
};

export default function siat5Reducer(state = initialState, action) {
  switch (action.type) {
    case SIAT5Types.SIAT5_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT5Types.SIAT5_GET_SUCCESS:
      return {
        ...state,
        siats5: action.payload.siat5s,
        redirect: true,
        error: null
      };

    case SIAT5Types.SIAT5_GETBYID_SUCCESS:
      let siat_two_records = action.payload.siat.siat_two_records;
      for (let i = 0; i < siat_two_records.length; i++) {
        siat_two_records[i].local_id = i + 1;
        siat_two_records[i].arrival = parseStringToDate(
          siat_two_records[i].arrival
        );
        siat_two_records[i].departure = parseStringToDate(
          siat_two_records[i].departure
        );
      }
      return {
        ...state,
        siat: action.payload.siat,
        records: siat_two_records,
        redirect: true,
        error: null
      };

    case SIAT5Types.SIAT5_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        siats5: []
      };

    case SIAT5Types.SIAT5_MODAL_SHOW_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT5Types.SIAT5_MODAL_SHOW_SUCCESS:
      let updated_siat5 = action.payload.siat5;
      return {
        ...state,
        modal_siats_two: action.payload.siat5[0],
        modal_siats_three: action.payload.siat5[1],
        modal_siats_four: action.payload.siat5[2],
        guest_record: action.payload.siat5[3]
      };

    case SIAT5Types.SIAT5_MODAL_SHOW_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        modal_siats_two: null,
        modal_siats_three: null,
        modal_siats_four: null,
        guest_record: null
      };

    //local

    case SIAT5Types.SIAT5_SETINITIAL_SUCCESS:
      return {
        siats5: [],
        siat: null,
        records: [],
        modal_siats_two: null,
        modal_siats_three: null,
        modal_siats_four: null,
        redirect: false,
        error: null
      };

    case SIAT5Types.SIAT5_LOCALCREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        // records: state.records.concat(action.payload.guest_record)
        records: [].concat(action.payload.guest_record, state.records)
      };


    case SIAT5Types.UPDATE_STATE_SIAT_REQUEST:
      return {
        ...state,
        guest_record_edit_siat: null,
        error: null,
        redirect: false
      };
    case SIAT5Types.UPDATE_STATE_SIAT_SUCCESS:
      let new_guest_records = action.payload.guest_record
      let new_records = state.records
      
      for (let i = 0; i < new_records.length; i++) {
        if(new_records[i].guest_record.id == new_guest_records.id){
          new_records[i].guest_record = new_guest_records;
        }
      }

      return {
        ...state,
        records: new_records,
      };
    case SIAT5Types.UPDATE_STATE_SIAT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
