import { LogTypes } from '../types';
import { LogEndpoints, MainURL } from '../endpoints';
import axios from 'axios';

const logGetBegin = () => ({
  type: LogTypes.LOG_GET_REQUEST
});

const logGetSuccess = logs => ({
  type: LogTypes.LOG_GET_SUCCESS,
  payload: logs
});

const logGetFailure = error => ({
  type: LogTypes.LOG_GET_FAILURE,
  payload: { error }
});

export const getLogs = token => {
  return dispatch => {
    dispatch(logGetBegin());
    return axios
      .get(MainURL + LogEndpoints.LOG_ENDPOINT, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(logGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(logGetFailure(error.response));
      });
  };
};
