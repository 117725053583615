import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { es } from "../../../../config/translations";
import { GetFormattedDate } from '../../../helper_java/date_format'

const ModalImportDifferences = props => {

  // const re_paint_values = (values)=>{
  //
  // }

  const footer = (
    <div>
      <Button
        label={"Mantener datos del sistema"}
        onClick={props.insertCorrectData}
        icon="pi pi-check"
      />
      <Button
        className="p-button-danger"
        label="Cancelar importación del excel"
        icon="pi pi-times"
        onClick={props.closeModal}
      />
    </div>
  );

  return (
    <Dialog
      style={{ width: "70vw" }}
      maximizable
      footer={footer}
      header={
        "Listado de huéspedes existentes en sistema"
      }
      visible={props.visible}
      modal={true}
      onHide={props.closeModal}
    >
      <p>*Si desea mantener los datos del huésped registrado en el sistema presione "Mantener datos del sistema" y procederá con la importación del excel</p>
      {/*style={{marginTop: '0em !important'}}*/}
      <DataTable
        value= {props.values}
        paginator={true}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        // currentPageReportTemplate="Showing {currentPage} to {last} of {props.values.length} entries"
        rows={4}
        rowsPerPageOptions={[4,8]}
      >
        <Column field="data_is" header="Ubicación de Datos" />
        <Column field="name" header="Nombre" />
        <Column field="last_name" header="Apellido paterno" />
        <Column field="middle_name" header="Apellido materno" />
        <Column header="País"
          body={rowData => {
            return rowData.country.name
          }}
        />
        <Column field="profession" header="Profesión" />

        <Column header="Tipo de documento"
          body={rowData => {
            if(rowData.doc_type == 1){
                return "Pasaporte"
            }
            if(rowData.doc_type == 2){
              return "Cédula de identidad"
            }
          }}
        />

        <Column field="document" header="Número de documento" />
        <Column
          header="Fecha de nacimiento"
          body={rowData => {
            return GetFormattedDate(rowData.birthdate)
          }}
        />
        <Column header="Nacionalidad"
          body={rowData => {
            return rowData.nationality.name
          }}
        />
        <Column field="civil_status" header="Estado Civil"
          body={rowData => {
            if(rowData.civil_status == null){
              return "S.E."
            }else{
              return rowData.civil_status
            }
          }}
        />
      </DataTable>

    </Dialog>
  );
};

export default ModalImportDifferences;
