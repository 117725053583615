import { EstablishmentContactTypes } from "../types";
import { EstablishmentContactEndpoints, MainURL } from "../endpoints";
import axios from "axios";

const establishmentContactGetBegin = () => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_GET_REQUEST
});

const establishmentContactGetSuccess = establishment_contacts => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_GET_SUCCESS,
  payload: { establishment_contacts }
});

const establishmentContactGetFailure = error => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_GET_FAILURE,
  payload: { error }
});

export const getEstablishmentContacts = token => {
  return dispatch => {
    dispatch(establishmentContactGetBegin());
    return axios
      .get(
        MainURL + EstablishmentContactEndpoints.ESTABLISHMENTCONTACT_ENDPOINT,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(establishmentContactGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(establishmentContactGetFailure(error.response));
      });
  };
};

const establishmentContactCreateBegin = () => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_CREATE_REQUEST
});

const establishmentContactCreateSuccess = establishmentContact => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_CREATE_SUCCESS,
  payload: { establishmentContact, success: "Contacto creado exitosamente" }
});

const establishmentContactCreateFailure = error => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_CREATE_FAILURE,
  payload: { error }
});

export const createEstablishmentContact = (token, data) => {
  return dispatch => {
    dispatch(establishmentContactCreateBegin());
    return axios
      .post(
        MainURL + EstablishmentContactEndpoints.ESTABLISHMENTCONTACT_ENDPOINT,
        data,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(establishmentContactCreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(establishmentContactCreateFailure(error.response));
      });
  };
};

const establishmentContactDeleteBegin = () => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_DELETE_REQUEST
});

const establishmentContactDeleteSuccess = id => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_DELETE_SUCCESS,
  payload: { id, success: "Contacto eliminado exitosamente" }
});

const establishmentContactDeleteFailure = error => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_DELETE_FAILURE,
  payload: { error }
});

export const deleteEstablishmentContact = (token, id) => {
  return dispatch => {
    dispatch(establishmentContactDeleteBegin());
    return axios
      .delete(
        MainURL +
          EstablishmentContactEndpoints.ESTABLISHMENTCONTACT_ENDPOINT +
          `/${id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(establishmentContactDeleteSuccess(id));
      })
      .catch(error => {
        dispatch(establishmentContactDeleteFailure(error.response));
      });
  };
};

const establishmentContactEditBegin = () => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_EDIT_REQUEST
});

const establishmentContactEditSuccess = establishmentContact => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_EDIT_SUCCESS,
  payload: {
    establishmentContact,
    success: "Contacto actualizado exitosamente"
  }
});

const establishmentContactEditFailure = error => ({
  type: EstablishmentContactTypes.ESTABLISHMENTCONTACT_EDIT_FAILURE,
  payload: { error }
});

export const editEstablishmentContact = (token, data) => {
  return dispatch => {
    dispatch(establishmentContactEditBegin());
    return axios
      .put(
        MainURL +
          EstablishmentContactEndpoints.ESTABLISHMENTCONTACT_ENDPOINT +
          `/${data.id}.JSON`,
        data,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(establishmentContactEditSuccess(response.data));
      })
      .catch(error => {
        dispatch(establishmentContactEditFailure(error.response));
      });
  };
};
