import { EstablishmentContactTypes } from "../types";

const initialState = {
  establishment_contacts: [],
  redirect: false,
  error: null
};

export default function EstablishmentEmailReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_GET_SUCCESS:
      return {
        ...state,
        establishment_contacts: action.payload.establishment_contacts,
        redirect: true,
        error: null
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        establishment_contacts: []
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        establishment_contacts: state.establishment_contacts.concat(
          action.payload.establishmentContact
        )
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true,
        establishment_contacts: state.establishment_contacts.filter(
          establishment_contact =>
            establishment_contact.id !== action.payload.id
        )
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_EDIT_SUCCESS:
      let updated_establishment_contact = action.payload.establishmentContact;
      return {
        ...state,
        redirect: true,
        establishment_contacts: state.establishment_contacts.map(
          establishment_contact => {
            if (establishment_contact.id === updated_establishment_contact.id) {
              return updated_establishment_contact;
            } else {
              return establishment_contact;
            }
          }
        )
      };

    case EstablishmentContactTypes.ESTABLISHMENTCONTACT_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
