export const NationalityTypes = {
  NATIONALITY_GET_REQUEST: "NATIONALITY_GET_REQUEST",
  NATIONALITY_GET_SUCCESS: "NATIONALITY_GET_SUCCESS",
  NATIONALITY_GET_FAILURE: "NATIONALITY_GET_FAILURE",

  NATIONALITY_CREATE_REQUEST: "NATIONALITY_CREATE_REQUEST",
  NATIONALITY_CREATE_SUCCESS: "NATIONALITY_CREATE_SUCCESS",
  NATIONALITY_CREATE_FAILURE: "NATIONALITY_CREATE_FAILURE",

  NATIONALITY_DELETE_REQUEST: "NATIONALITY_DELETE_REQUEST",
  NATIONALITY_DELETE_SUCCESS: "NATIONALITY_DELETE_SUCCESS",
  NATIONALITY_DELETE_FAILURE: "NATIONALITY_DELETE_FAILURE",

  NATIONALITY_EDIT_REQUEST: "NATIONALITY_EDIT_REQUEST",
  NATIONALITY_EDIT_SUCCESS: "NATIONALITY_EDIT_SUCCESS",
  NATIONALITY_EDIT_FAILURE: "NATIONALITY_EDIT_FAILURE"
};
