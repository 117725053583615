import React, { Component } from "react";
import * as DailyReportActions from "../../../../redux/actions/DailyReportActions";
import * as GuestRecordActions from "../../../../redux/actions/GuestRecordActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import ParteForm from "../components/ParteForm";
import GuestForm from "../../GuestRecord/container/GuestForm";
import ParteTable from "../components/ParteTable";
import { Card } from "primereact/card";
import ParteFooter from "../components/ParteFooter";
import SimpleReactValidator from "simple-react-validator";
import { FormMessages } from "../../../../config/formTranslations";
import { ProgressSpinner } from "primereact/progressspinner";
import * as CountryActions from "../../../../redux/actions/CountryActions";
import * as NationalityActions from "../../../../redux/actions/NationalityActions";
import readXlsxFile from "read-excel-file";
import { convertExcelRowToObject } from "../components/excelFunction";
import { Messages } from "primereact/messages";
import ModalImportDifferences from "../components/ModalImportDifferences";
import ModalImportSuccess from "../components/ModalImportSuccess";
import { GetFormattedDate } from '../../../helper_java/date_format'

class New extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: "p-message p-component p-message-error",
      messages: FormMessages
    });
    this.state = {
      list_differrences: [],
      list_guest_records: [],
      guestModalVisible: false,
      guest_record: null,
      excelLoading: false,
      importState: false,
      length_import: null,
      count_import:null,
      form: {
        id: null,
        date: null,
        state: 1
      },
    };
  }

  async componentDidMount() {
    await this.props.DailyReportActions.getDailyReports(this.props.token);
    await this.props.CountryActions.getCountries(this.props.token);
    await this.props.NationalityActions.getNationalities(this.props.token);
    
    const daily_report_id = this.props.match.params.id;
    if (daily_report_id != null) {
      await this.props.GuestRecordActions.getGuestRecords(
        daily_report_id,
        this.props.token
      );
      let parte = this.props.parte;
      this.setState({
        form: {
          ...this.state.form,
          id: parte.id,
          date: this.parseStringToDate(parte.date),
          state: parte.state
        }
      });
    } else {
      this.setState({
        ...this.state,
        form: {
          id: null,
          date: this.string_to_date(this.props.next_date_daily_report.next_date),
          state: 1
        }
      });
      await this.props.GuestRecordActions.getDailyPreload(this.props.token);
    }

    if(this.props.location.query!=undefined){
      const new_date = this.set_date(
        new Date( new Date(this.props.location.query.date).setDate(new Date(this.props.location.query.date).getDate()+1))
      )
      this.setState({
        form: {
          ...this.state.form,
          date: new_date
        }
      });
    }
  }

  set_date(date){
    return new Date( new Date(date).setHours(0,0,0,0) )
  }

  parseStringToDate(stringDate) {
    let from = stringDate.split("-");
    return new Date(from[0], from[1] - 1, from[2]);
  }

  showGuestModal = () => {
    if (!this.state.form.date) {
      this.messages.show({
        severity: "error",
        summary: "Fecha no válida ",
        detail: "Debe ingresar la fecha del parte",
        closable: false,
      });
      return;
    }
    this.setState({ guestModalVisible: true });
  };
  hideGuestModal = () => {
    this.setState({
      guestModalVisible: false,
      guest_record: null
    });
  };
  searchPersonByDni = async dni => {
    await this.props.DailyReportActions.searchPersonByDNI(
        dni,
        this.props.token
    );
  };
  this_person_has_guest_records_function = async(props, dni) => {
    await props.DailyReportActions.thisPersonHasGuestRecords(
        dni,
        props.token
    );
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  handleCheckboxChanged = event => {
    this.setState({
      form: {
        ...this.state.form,
        state: event.checked ? 0 : 1
      }
    });
  };
  deleteguest_record = guest_record => {
    this.props.GuestRecordActions.deleteGuestRecord(guest_record);
  };

  editguest_record = guest_record => {
    this.setState({ guestModalVisible: true, guest_record });
    this.this_person_has_guest_records_function(this.props, guest_record.person_attributes.document, guest_record.id)
  };

  cancelParte = () => {
    const nextDailyReport = this.props.next_date_daily_report;

    if (nextDailyReport.next_daily_report_id == null && nextDailyReport.next_date){
      this.props.GuestRecordActions.cleanState();
      this.props.GuestRecordActions.getDailyPreload(this.props.token);
      this.setState({
        ...this.state,
        form: {
          id: null,
          date: this.string_to_date(nextDailyReport.next_date),
          state: 1
        }
      });
      this.props.history.push(`/partes/new`);
    }else{
      this.props.history.push(`/partes`);
    }

  };

  method2 = async(daily_report, errors)=>{
    if (errors.length > 0) {
      this.messages.show(
        errors.map(error => {
          return {
            severity: "error",
            sticky: true,
            summary: "Campos de excel vacíos o inválidos",
            detail: error
          };
        })
      );
      return;
    } else {
      this.setState({
        importState: true
      })

      await this.setState({
        length_import: daily_report.guest_records.length,
        count_import: 0
      })

      var guest_records = await daily_report.guest_records.filter(
        async guest_record_imported => {
          var person_import = guest_record_imported.person_attributes

          await this.props.DailyReportActions.searchPersonByDNI( person_import.document, this.props.token);
          var person_db = this.props.person
          await this.setState({
            count_import: this.state.count_import + 1
          })
          if( (person_db != null) && (person_db.document == person_import.document) ){
            if(
              person_db.country_id != person_import.country_id ||
              person_db.profession != person_import.profession ||
              person_db.birthdate != person_import.birthdate ||
              person_db.name != person_import.name ||
              person_db.middle_name != person_import.middle_name ||
              person_db.last_name != person_import.last_name ||
              person_db.doc_type != person_import.doc_type ||
              person_db.nationality_id != person_import.nationality_id ||
              person_db.civil_status != person_import.civil_status
            ){
              var new_guest_record_imported = guest_record_imported
              new_guest_record_imported.person_attributes = person_db

              this.setState({
                list_differrences: this.state.list_differrences.concat([[person_db, person_import]]),
                list_guest_records: this.state.list_guest_records.concat(new_guest_record_imported)
              });
            }else{
              this.setState({
                list_guest_records: this.state.list_guest_records.concat(guest_record_imported)
              });
            }
          }else{
            this.setState({
              list_guest_records: this.state.list_guest_records.concat(guest_record_imported)
            });
          }



          let exist = false;
          for (let i = 0; i < this.props.parte.guest_records.length; i++) {
            if (this.props.parte.guest_records[i].person_attributes.document === person_import.document) {
              exist = true;
              break;
            }
          }
          if(this.state.count_import == this.state.length_import){
            this.setState({
              importState: false
            });
          }
          //console.log("-------------------------------------------")
          return !exist;
        }
      );
    }
  }

  handleImport = async(event) => {
    this.setState({ excelLoading: true });
    await readXlsxFile(event.files[0]).then( async(rows) => {
      var { daily_report, errors } = await convertExcelRowToObject(
        rows,
        this.props.countries,
        this.props.nationalities,
        this.props.parte.guest_records
      );
      await this.setState({
        excelLoading: false
      });
      await this.method2(daily_report, errors)
    });
  };

  insertCorrectData = async() =>{
    var list = this.state.list_guest_records.sort((a, b) => (a.local_id > b.local_id) ? 1 : -1)
    for (let i = 0; i < list.length; i++) {
      await this.props.GuestRecordActions.loadState(list[i]);
    }
    await this.setState({
      list_differrences: [],
      list_guest_records: []
    });
  }

  detectChanges = (old_lists, delete_list, parte, form) =>{
    var a = false
    if(form.state != parte.state){
      a = true
    }

    if((delete_list != [])){
      a = true;
    }

    old_lists.map((old_list) => {
      // var count = 0;
      parte.guest_records.map((guest_record) => {
        // if( (guest_record["id"] == old_list["id"]) && (guest_record != old_list) ){
        if( (guest_record["id"] == old_list["id"]) && (guest_record["room"] != old_list["room"]) ){
          a = true;
        }
        // count+=1;
      })
    })

    return a;

  }
// ----------------------------------------------------------------------
  get_total_occupied_rooms = async ()=>{
    let date_form = this.state.form.date.toISOString().substr(0,10);
    // Create an array of objects
    let guest_records = this.props.guest_records
    // Declare a new array
    let newArray = [];
    // Declare an empty object
    let uniqueObject = {};
    // Loop for the array elements
    for (let i in guest_records) {
      if( (date_form == guest_records[i]['departure'] && date_form == guest_records[i]['arrival']) || date_form != guest_records[i]['departure']){
        // Extract the room
        let objRoom = guest_records[i]['room'];
        // Use the room as the index
        uniqueObject[objRoom] = guest_records[i];
      }
    }
    return Object.keys(uniqueObject).length
  }
// ----------------------------------------------------------------------
  handleSubmit = async () => {
    if (this.validator.allValid()) {
      let dailyReport = this.state.form;
      dailyReport.establishment_id = this.props.user.establishment_id;
      // this.props.guest_records
      dailyReport.occupied_rooms = await this.get_total_occupied_rooms();
      dailyReport.guest_records_attributes = this.props.guest_records.reverse();
      // dailyReport.guest_records_attributes = this.props.guest_records.reverse();
      dailyReport = this.changeAttributesName(dailyReport);
      if (dailyReport.id == null) {
        delete dailyReport.id;
        await this.props.DailyReportActions.createDailyReport(
          dailyReport,
          this.props.token
        );
      } else {
        if (this.props.parte.state == 0) {
          dailyReport.state = 2;
        }

        let detectChanges= await this.detectChanges(
          this.props.old_list,
          this.props.delete_list,
          this.props.parte,
          this.state.form
        )
        if( (detectChanges && this.props.parte.state == 0) || (this.props.parte.state == 1)){
          if (this.props.parte.state == 0){
            var changes  = [];

            for (var i = 0; i < dailyReport.guest_records_attributes.length; i++) {
              for (var j = 0; j < this.props.old_list.length; j++) {
                if(dailyReport.guest_records_attributes[i].id == this.props.old_list[j].id){
                  if(dailyReport.guest_records_attributes[i] != this.props.old_list[j]){
                    var guest = new Object();
                    guest.id = dailyReport.guest_records_attributes[i].id;
                    guest.new = dailyReport.guest_records_attributes[i];
                    guest.old = this.props.old_list[j]
                    changes.push(guest)
                  }
                }
              }
            }

            var obj = new Object();
            obj.id= dailyReport.id;
            obj.state= dailyReport.state;
            obj.occupied_rooms=dailyReport.occupied_rooms;
            obj.total_i_foreign= dailyReport.total_i_foreign;
            obj.total_i_national= dailyReport.total_i_national;
            obj.total_p_foreign= dailyReport.total_p_foreign;
            obj.total_p_national= dailyReport.total_p_national;
            obj.guest_records_attributes= changes

            await this.props.DailyReportActions.updateDailyReport(
              obj,
              this.props.token
            );
          }else{
            await this.props.DailyReportActions.updateDailyReport(
              dailyReport,
              this.props.token
            );
          }
        }

      }
      if (
        this.props.error.data ||
        this.props.error.status === 404 ||
        this.props.error
      ) {
        this.showError();
        return;
      }

      this.cancelParte();
      this.props.history.push("/partes");
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  showError() {
    if (typeof this.props.error === "string") {
      this.messages.show({
        severity: "error",
        summary: "",
        detail: this.props.error,
        closable: false,
      });
    } else {
      try {
        this.messages.show(
          this.props.error.data.base.map(error => {
            return {
              severity: "error",
              sticky: true,
              summary: error,
              detail: ""
            };
          })
        );
      } catch (error) {
        this.messages.show({
          severity: "error",
          summary: "Error al guardar los datos",
          detail: "Faltan datos requeridos",
          closable: false,
        });
      }
    }
  }

  changeAttributesName(dailyReport) {
    const date = dailyReport.date;
    let guest_record_array = dailyReport.guest_records_attributes;
    let converted_array = [];
    let isUpdating = dailyReport.id > 0;
    let total_i_national = 0;
    let total_i_foreign = 0;
    let total_p_national = 0;
    let total_p_foreign = 0;
    for (let guest_record of guest_record_array) {
      let country_name = null;
      try {
        let country = guest_record.person_attributes.country;
        if (country == null) {
          country = this.props.countries.filter(
            country => country.id === guest_record.person_attributes.country_id
          )[0];
        }
        country_name = country.name.toLowerCase().trim();
      } catch (err) {
        country_name = "";
      }
      const arrival = this.parseStringToDate(guest_record.arrival);
      const departure = this.parseStringToDate(guest_record.departure);
      const isIncoming = this.areDateEquals(arrival, date);
      if (country_name === "bolivia") {
        if (isIncoming) {
          total_i_national++;
        } else {
          total_p_national++;
        }
      } else {
        if (isIncoming) {
          total_i_foreign++;
        } else {
          total_p_foreign++;
        }
      }
      guest_record.origin = guest_record.origin_id;
      guest_record.destiny = guest_record.destiny_id;
      guest_record.person_attributes.nationality = guest_record.person_attributes.nationality_id;
      guest_record.person_attributes.country = guest_record.person_attributes.country_id;
      if(guest_record.person != undefined && guest_record.person_attributes.document == guest_record.person.document){
        guest_record.person_attributes.id = guest_record.person.id
      }
      if (!isUpdating) {
        delete guest_record.id;
      }
      let m_type = 1;
      if (this.areDateEquals(arrival, date)) {
        m_type = this.areDateEquals(arrival, departure) ? 3 : 0;

      } else if (this.areDateEquals(departure, date)) {
        m_type = 2;
      }
      guest_record.m_type = m_type;
      converted_array.push(guest_record);
    }
    if (isUpdating) {
      for (const delete_item of this.props.delete_list) {
        let item = { id: delete_item, _destroy: true };
        converted_array.push(item);
      }
    }
    dailyReport = {
      ...dailyReport,
      total_i_national,
      total_i_foreign,
      total_p_national,
      total_p_foreign
    };
    dailyReport.guest_records_attributes = converted_array;
    return dailyReport;
  }

  areDateEquals(d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }
  string_to_date = date_string => {
    let datetime = new Date(date_string).setHours(24,0,0,0);
    return new Date(datetime);
  };
  componentDidUpdate(prevProps) {
    if (this.state.form.date == null && this.props.next_date){
      this.setState({
        form: {
          ...this.state.form,
          date: this.string_to_date(this.props.next_date)
        }
      });
    }
  }

  showErrorsGuestRecord = () => {
    if (this.props.error_guest_record != null){
      this.messages.show({
        severity: "error",
        detail: this.props.error_guest_record,
        closable: false,
      });
    }
  }

  clear_list_differrences = async() =>{
    await this.setState({
      list_differrences: [],
      list_guest_records: []
    });
  }

  render() {
    if (this.state.excelLoading === true)
      return (
        <div className="p-grid p-fluid p-justify-center">
          <ProgressSpinner
            style={{ width: "20%", height: "20%", marginTop: "10%" }}
            animationDuration=".5s"
          />
        </div>
      );

    if (this.props.redirect === true) {
      return <Redirect to="/" />;
    } else if (this.props.error.status === 401) {
      return <Redirect to="/login" />;
    } else{
      var data_import_table = [];

      this.state.list_differrences.map((list_differrence) => {
        data_import_table.push(list_differrence[0])
        data_import_table[data_import_table.length-1] = Object.assign({ data_is: "Sistema"}, data_import_table[data_import_table.length-1])

        data_import_table.push(list_differrence[1])
        data_import_table[data_import_table.length-1] = Object.assign({ data_is: "Excel"}, data_import_table[data_import_table.length-1])
      })

      return this.props.loading || this.state.importState == true? (
        <div className="p-grid p-fluid p-justify-center">
          <ProgressSpinner
            style={{ width: "20%", height: "20%", marginTop: "10%" }}
            animationDuration=".5s"
          />
        </div>
      ) : (
        <Card>
          <Messages ref={el => (this.messages = el)} />
          {
            this.state.list_differrences.length !=0?
              <ModalImportDifferences
                values = {data_import_table}
                visible={this.state.importState==false && this.state.list_differrences.length != 0? true : false}
                closeModal={this.clear_list_differrences}
                insertCorrectData={this.insertCorrectData}
              />
              :
              <ModalImportSuccess
                visible={this.state.importState==false && this.state.list_guest_records.length != 0 && this.state.list_differrences.length == 0? true : false}
                closeModal={this.insertCorrectData}
                insertCorrectData={this.insertCorrectData}
              />
          }

          <ParteForm
            handleImport={this.handleImport}
            validator={this.validator}
            showGuestModal={this.showGuestModal}
            handleChange={this.handleChange}
            form={this.state.form}
            state = {this.props.parte.state}
            handleCheckbox={this.handleCheckboxChanged}
          />
          {this.props.countries.length > 0 ? (
            <ParteTable
              state_form={this.state.form.state}
              showErrorsGuestRecord = {this.showErrorsGuestRecord}
              state = {this.props.parte.state}
              countries={this.props.countries}
              guest_records={this.props.guest_records}
              delete={this.deleteguest_record}
              edit={this.editguest_record}
              token= {this.props.token}
              this_person_has_guest_records_function= {this.this_person_has_guest_records_function}
              state_edit_finish_entry={this.props.dailyReports[0] ? (this.props.dailyReports[0].date == JSON.stringify(this.state.form.date).slice(1,11) ? true : false) : false}
            />
          ) : (
            ""
          )}

          <ParteFooter
            state = {this.props.parte.state}
            id={this.state.form.id}
            guest_records={this.props.guest_records}
            cancel={this.cancelParte}
            handleSubmit={this.handleSubmit}
          />
          {this.state.guestModalVisible ?
              <GuestForm
                  parte_id={this.props.parte.id}
                  state={this.props.parte.state}
                  date={this.state.form.date}
                  visible={this.state.guestModalVisible}
                  guest_record={this.state.guest_record}
                  close={this.hideGuestModal}
                  searchPersonByDni={this.searchPersonByDni}
                  next_date_daily_report={this.props.next_date_daily_report}
                  state_edit_finish_entry={this.props.dailyReports[0] ? (this.props.dailyReports[0].date <= JSON.stringify(this.state.form.date).slice(1,11) ? true : false) : false}
              />
              : null
          }
        </Card>
      );
    }
  }
}

const mapStateToProps = state => ({
  user: state.AuthReducer.user,
  token: state.AuthReducer.token,
  countries: state.CountryReducer.countries,
  nationalities: state.NationalityReducer.nationalities,
  loading: state.LoadingReducer.loading,
  guest_records: state.ParteReducer.guest_records,
  old_list: state.ParteReducer.old_list,
  next_date: state.ParteReducer.next_date,
  delete_list: state.ParteReducer.delete_list,
  parte: state.ParteReducer,
  redirect: state.DailyReportReducer.redirect,
  dailyReports: state.DailyReportReducer.dailyReports,
  next_date_daily_report: state.DailyReportReducer.next_date_daily_report,
  person: state.DailyReportReducer.person,
  error: state.ErrorReducer.error,
  error_guest_record: state.ParteReducer.error
});

const mapDispatchToProps = dispatch => ({
  DailyReportActions: bindActionCreators(DailyReportActions, dispatch),
  GuestRecordActions: bindActionCreators(GuestRecordActions, dispatch),
  CountryActions: bindActionCreators(CountryActions, dispatch),
  NationalityActions: bindActionCreators(NationalityActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(New);
