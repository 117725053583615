import { SIAT2Types } from "../types";

const initialState = {
  siats2: [],
  siat: null,
  records: [],
  delete_list: [],
  redirect: false,
  error: null
};

const parseStringToDate = stringDate => {
  if (stringDate == null) return null;
  let from = stringDate.split("-");
  return new Date(from[0], from[1] - 1, from[2]);
};

export default function siat2Reducer(state = initialState, action) {
  switch (action.type) {
    case SIAT2Types.SIAT2_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT2Types.SIAT2_GET_SUCCESS:
      return {
        ...state,
        siats2: action.payload.siat2s,
        redirect: true,
        error: null
      };

    case SIAT2Types.SIAT2_GETBYID_SUCCESS:
      let siat_two_records = action.payload.siat.siat_two_records;
      for (let i = 0; i < siat_two_records.length; i++) {
        siat_two_records[i].local_id = i + 1;
        siat_two_records[i].arrival = parseStringToDate(
          siat_two_records[i].arrival
        );
        siat_two_records[i].departure = parseStringToDate(
          siat_two_records[i].departure
        );
      }
      return {
        ...state,
        siat: action.payload.siat,
        delete_list: [],
        records: siat_two_records,
        redirect: true,
        error: null
      };

    case SIAT2Types.SIAT2_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        siats2: []
      };

    case SIAT2Types.SIAT2_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT2Types.SIAT2_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        delete_list: [],
        siats2: state.siats2.concat(action.payload.siat2)
      };

    case SIAT2Types.SIAT2_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case SIAT2Types.SIAT2_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT2Types.SIAT2_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true,
        delete_list: [],
        siats2: state.siats2.filter(
          siat2 => siat2.id !== action.payload.SIAT2_id
        )
      };

    case SIAT2Types.SIAT2_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case SIAT2Types.SIAT2_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case SIAT2Types.SIAT2_EDIT_SUCCESS:
      let updated_siat2 = action.payload.siat2;
      return {
        ...state,
        redirect: true,
        delete_list: [],
        siats2: state.siats2.map(siat2 => {
          if (siat2.id === updated_siat2.id) {
            return updated_siat2;
          } else {
            return siat2;
          }
        })
      };

    case SIAT2Types.SIAT2_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    //local

    case SIAT2Types.SIAT2_SETINITIAL_SUCCESS:
      return {
        siats2: [],
        siat: null,
        records: [],
        redirect: false,
        error: null
      };

    case SIAT2Types.SIAT2_LOCALCREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        // records: state.records.concat(action.payload.guest_record)
        records: [].concat(action.payload.guest_record, state.records)
      };

    case SIAT2Types.SIAT2_LOCALEDIT_SUCCESS:
      let local_updated_siat2 = action.payload.guest_record;
      return {
        ...state,
        redirect: true,
        records: state.records.map(siat2 => {
          if (siat2.local_id === local_updated_siat2.local_id) {
            return local_updated_siat2;
          } else {
            return siat2;
          }
        })
      };

    case SIAT2Types.SIAT2_LOCALDELETE_SUCCESS:
      let new_delete_list = []
      let delete_item = { id: action.payload.record.id, _destroy: true };

      if (action.payload.record.id != undefined){
        if (state.records.length ==1){
          return {
            ...state,
            error: "No se puede eliminar el último registro del SIAT II, este no puede estar vacío"
          };
        }else{
          new_delete_list = state.delete_list.concat(delete_item)
        }
      }

      return {
        ...state,
        redirect: true,
        delete_list: new_delete_list,
        records: state.records.filter(
          siat2 => siat2.local_id !== action.payload.record.local_id
        ),
        error: null
      };

    case SIAT2Types.SIAT2_EDIT_RECORD_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };
    case SIAT2Types.SIAT2_EDIT_RECORD_SUCCESS:
      return {
        ...state
      };
    case SIAT2Types.SIAT2_EDIT_RECORD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
