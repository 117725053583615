export const GuestRecordTypes = {
  GUEST_SAVE_SUCCESS: "GUEST_SAVE_SUCCESS",
  GUEST_UPDATE_SUCCESS: "GUEST_UPDATE_SUCCESS",
  GUEST_DELETE_SUCCESS: "GUEST_DELETE_SUCCESS",
  CLEAN_GUEST_SUCCESS: "CLEAN_GUEST",
  LOAD_GUEST_SUCCESS: "LOAD_GUEST_SUCCESS",

  GUEST_EDIT_REQUEST: "GUEST_EDIT_REQUEST",
  GUEST_EDIT_SUCCESS: "GUEST_EDIT_SUCCESS",
  GUEST_EDIT_FAILURE: "GUEST_EDIT_FAILURE",

  PRELOAD_GET_REQUEST: "PRELOAD_GET_REQUEST",
  PRELOAD_GET_SUCCESS: "PRELOAD_GET_SUCCESS",
  PRELOAD_GET_FAILURE: "PRELOAD_GET_FAILURE"
};
