import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { MainURL } from "../../redux/endpoints/index";

const EstablishmentContactTable = props => {
  let header = (
    <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
      Contactos
    </div>
  );
  let footer = (
    <div className="p-clearfix" style={{ width: "100%" }}>
      <Button
        style={{ float: "left" }}
        label="Agregar contacto"
        icon="pi pi-plus"
        onClick={props.addNew}
      />
    </div>
  );
  const actionTemplate = rowData => {
    let items = [
      {
        label: "Eliminar",
        icon: "pi pi-times",
        command: e => {
          props.delete(rowData.id);
        }
      }
    ];
    return (
      <SplitButton
        label="Editar"
        icon="pi pi-pencil"
        className="p-button-info"
        model={items}
        onClick={e => {
          props.edit(rowData);
        }}
      />
    );
  };
  return (
    <div>
      <div style={{ margin: "10px" }}>{props.children}</div>
      <DataTable
        header={header}
        footer={footer}
        value={props.establishment_contacts}
        paginator={true}
        rows={10}
        rowsPerPageOptions={[5, 10, 20]}
        emptyMessage="No tienes contactos registrados"
      >
        <Column
          field="full_name"
          body={rowData => {
            const { name, last_name, middle_name } = rowData.person;
            return `${name} ${last_name} ${middle_name}`;
          }}
          header="Nombre completo"
        />
        <Column
          header="Acciones"
          body={actionTemplate}
          style={{ width: "14em" }}
        />
      </DataTable>
    </div>
  );
};

export default EstablishmentContactTable;
