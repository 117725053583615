import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

const HeaderForm = (props) => {

  const { id, nit, branch, state } = props.form;

  return (
    <div style={{ marginTop: '10px' }}>
      <h3 style={{ marginLeft: '7px' }} className="first">
        {id > 0 ? 'Editar SIAT' : 'Nuevo SIAT II'}
      </h3>

      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-4">
          <span className="p-float-label">
            <InputText
              maxLength="14"
              value={nit}
              onChange={props.handleChange}
              keyfilter="pint"
              name="nit"
              data-type="number"
              disabled={state? true : false}
            />
            <label htmlFor="in">NIT del contribuyente</label>
          </span>
          {props.validator.message('nit del contribuyente', nit, 'required')}
        </div>
        <div className="p-col-12 p-md-4">
          <span className="p-float-label">
            <InputText
              maxLength="2"
              keyfilter="pint"
              onChange={props.handleChange}
              value={branch}
              name="branch"
              data-type="number"
              tooltip="Ingrese 0 para la casa matriz, si es sucursal indicar el número que corresponda"
              disabled={state? true : false}
            />
            <label htmlFor="in">Casa matriz o sucursal</label>
          </span>
          {props.validator.message('Casa matriz o sucursal', branch, 'required')}
        </div>
      </div>
    </div>
  );
};

export default HeaderForm;
