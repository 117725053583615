import { DailyReportTypes } from "../types";
import {DailyReportEndpoints, MainURL, UnPleasantEndpoints, PersonEndpoints } from "../endpoints";
import axios from "axios";

const createDailyReportBegin = () => ({
  type: DailyReportTypes.DAILY_REPORT_CREATE_REQUEST
});

const createDailyReportSuccess = params => ({
  type: DailyReportTypes.DAILY_REPORT_CREATE_SUCCESS,
  payload: { params, success: "Parte creado exitosamente" }
});

const searchPersonBegin = () => ({
  type: DailyReportTypes.PERSON_SEARCH_REQUEST
});
const searchPersonSuccess = params => ({
  type: DailyReportTypes.PERSON_SEARCH_SUCCESS,
  payload: { params, success: "Persona Encontrada" }
});

const restartCanDestroySuccess = () => ({
  type: DailyReportTypes.RESTART_CAN_DESTROY
});

const searchPersonFailure = error => ({
  type: DailyReportTypes.PERSON_SEARCH_FAILURE,
  payload: { error }
});

const createDailyReportFailure = error => ({
  type: DailyReportTypes.DAILY_REPORT_CREATE_FAILURE,
  payload: { error }
});

const updateDailyReportBegin = () => ({
  type: DailyReportTypes.DAILY_REPORT_UPDATE_REQUEST
});

const updateDailyReportSuccess = params => ({
  type: DailyReportTypes.DAILY_REPORT_UPDATE_SUCCESS,
  payload: { params, success: "Parte actualizado exitosamente" }
});

const updateDailyReportFailure = error => ({
  type: DailyReportTypes.DAILY_REPORT_UPDATE_FAILURE,
  payload: { error }
});

const thisPersonHasGuestRecordsBegin = () => ({
  type: DailyReportTypes.THIS_PERSON_HAS_GUEST_RECORDS_REQUEST
});
const thisPersonHasGuestRecordsSuccess = params => ({
  type: DailyReportTypes.THIS_PERSON_HAS_GUEST_RECORDS_SUCCESS,
  payload: { params }
});

const thisPersonHasGuestRecordsFailure = error => ({
  type: DailyReportTypes.THIS_PERSON_HAS_GUEST_RECORDS_FAILURE,
  payload: { error }
});

export function restartCanDestroySuccessRecords() {
  return dispatch => {
    dispatch(restartCanDestroySuccess());
  };
}
// export const restartCanDestroySuccessRecords = () => {
//   return dispatch => {
//     dispatch({
//       dispatch(restartCanDestroySuccess());
//     });
//   };
// };

export function thisPersonHasGuestRecords(dni, token) {
  return dispatch => {
    dispatch(thisPersonHasGuestRecordsBegin());
    return axios
      .get(MainURL + PersonEndpoints.THIS_PERSON_HAS_GUEST_RECORDS + `/${dni}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(thisPersonHasGuestRecordsSuccess(response.data));
      })
      .catch(error => {
        dispatch(thisPersonHasGuestRecordsFailure(error.response));
      });
  };
}

export function createDailyReport(data, token) {
  return dispatch => {
    dispatch(createDailyReportBegin());
    return axios
      .post(MainURL + DailyReportEndpoints.DAILY_REPORT_ENDPOINT, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(createDailyReportSuccess(response.data));
      })
      .catch(error => {
        dispatch(createDailyReportFailure(error.response));
      });
  };
}

export function searchPersonByDNI(dni, token) {
  return dispatch => {
    return axios
        .get(MainURL + PersonEndpoints.PERSON_SEARCH_ENDPOINT + `/${dni}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          dispatch(searchPersonSuccess(response.data));
        })
        .catch(error => {
          dispatch(searchPersonFailure(error.response));
        });
  };
}

export function updateDailyReport(data, token) {
  return dispatch => {
    dispatch(updateDailyReportBegin());
    return axios
      .put(
        MainURL + DailyReportEndpoints.DAILY_REPORT_ENDPOINT + `/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(updateDailyReportSuccess(response.data));
      })
      .catch(error => {
        dispatch(updateDailyReportFailure(error.response));
      });
  };
}

const getDailyReportsBegin = () => ({
  type: DailyReportTypes.DAILY_REPORT_GET_ALL_REQUEST
});

const getDailyReportsSuccess = dailyReports => ({
  type: DailyReportTypes.DAILY_REPORT_GET_ALL_SUCCESS,
  payload: { dailyReports }
});

const getDailyReportsFailure = error => ({
  type: DailyReportTypes.DAILY_REPORT_GET_ALL_FAILURE,
  payload: { error }
});

export function getDailyReports(token, page = 1) {
  return dispatch => {
    dispatch(getDailyReportsBegin());
    return axios
      .get(
        MainURL + DailyReportEndpoints.DAILY_REPORT_ENDPOINT + "?page=" + page,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(getDailyReportsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getDailyReportsFailure(error.response));
      });
  };
}

const cleanDailyReportBegin = () => ({
  type: DailyReportTypes.DAILY_REPORT_CLEAN_REQUEST
});

export function cleanDailyReport() {
  return dispatch => {
    dispatch(cleanDailyReportBegin());
  };
}

const getGuestRecordsBegin = () => ({
  type: DailyReportTypes.GET_GUEST_RECORDS_REQUEST
});

const getGuestRecordsSuccess = dailyReports => ({
  type: DailyReportTypes.GET_GUEST_RECORDS_SUCCESS,
  payload: { dailyReports }
});

const getGuestRecordsFailure = error => ({
  type: DailyReportTypes.GET_GUEST_RECORDS_FAILURE,
  payload: { error }
});

export function getDailyReport(token, id) {
  return dispatch => {
    dispatch(getGuestRecordsBegin());
    return axios
      .get(
        MainURL + DailyReportEndpoints.DAILY_REPORT_ENDPOINT + `/${id}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(getGuestRecordsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getGuestRecordsFailure(error.response));
      });
  };
}
