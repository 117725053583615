import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from "primereact/message";
function WithMessage(Component) {
  return function WihLoadingComponent({ error, loading, success, ...props }) {
    if (loading)
      return (
        <div className="p-grid p-fluid p-justify-center">
          <ProgressSpinner
            style={{ width: "20%", height: "20%", marginTop: "10%" }}
            animationDuration=".5s"
          />
        </div>
      );
    else
      return (
        <Component {...props}>
          {success ? <Message severity="success" text={success}></Message> : ""}
          {error ? (
            <Message
              severity="error"
              text={typeof error == "string" ? error : "Error al obtener datos"}
            ></Message>
          ) : (
            ""
          )}
        </Component>
      );
  };
}
export default WithMessage;
