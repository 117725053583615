import { EmployeTypes } from "../types";
import { EmployeEndpoints, MainURL } from "../endpoints";
import axios from "axios";

const employeGetBegin = () => ({
  type: EmployeTypes.EMPLOYE_GET_REQUEST
});

const employeGetSuccess = employes => ({
  type: EmployeTypes.EMPLOYE_GET_SUCCESS,
  payload: { employes }
});

const employeGetFailure = error => ({
  type: EmployeTypes.EMPLOYE_GET_FAILURE,
  payload: { error }
});

export const getEmployes = token => {
  return dispatch => {
    dispatch(employeGetBegin());
    return axios
      .get(MainURL + EmployeEndpoints.EMPLOYE_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(employeGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(employeGetFailure(error.response));
      });
  };
};

const employeCreateBegin = () => ({
  type: EmployeTypes.EMPLOYE_CREATE_REQUEST
});

const employeCreateSuccess = employe => ({
  type: EmployeTypes.EMPLOYE_CREATE_SUCCESS,
  payload: { employe, success: "Empleado creado exitosamente" }
});

const employeCreateFailure = error => ({
  type: EmployeTypes.EMPLOYE_CREATE_FAILURE,
  payload: { error }
});

export const createEmploye = (token, data) => {
  return dispatch => {
    dispatch(employeCreateBegin());
    return axios
      .post(MainURL + EmployeEndpoints.EMPLOYE_ENDPOINT, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(employeCreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(employeCreateFailure(error.response));
      });
  };
};

const employeDeleteBegin = () => ({
  type: EmployeTypes.EMPLOYE_DELETE_REQUEST
});

const employeDeleteSuccess = employe_id => ({
  type: EmployeTypes.EMPLOYE_DELETE_SUCCESS,
  payload: { employe_id, success: "Empleado eliminado exitosamente" }
});

const employeDeleteFailure = error => ({
  type: EmployeTypes.EMPLOYE_DELETE_FAILURE,
  payload: { error }
});

export const deleteEmploye = (token, employe_id) => {
  return dispatch => {
    dispatch(employeDeleteBegin());
    return axios
      .delete(MainURL + EmployeEndpoints.EMPLOYE_ENDPOINT + `/${employe_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(employeDeleteSuccess(employe_id));
      })
      .catch(error => {
        dispatch(employeDeleteFailure(error.response));
      });
  };
};

const employeEditBegin = () => ({
  type: EmployeTypes.EMPLOYE_EDIT_REQUEST
});

const employeEditSuccess = employe => ({
  type: EmployeTypes.EMPLOYE_EDIT_SUCCESS,
  payload: { employe, success: "Empleado actualizado exitosamente" }
});

const employeEditFailure = error => ({
  type: EmployeTypes.EMPLOYE_EDIT_FAILURE,
  payload: { error }
});

export const editEmploye = (token, data) => {
  return dispatch => {
    dispatch(employeEditBegin());
    return axios
      .put(MainURL + EmployeEndpoints.EMPLOYE_ENDPOINT + `/${data.id}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(employeEditSuccess(response.data));
      })
      .catch(error => {
        dispatch(employeEditFailure(error.response));
      });
  };
};
