import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SplitButton } from "primereact/splitbutton";
import {Button} from 'primereact/button';
import { GetFormattedDate } from '../../../helper_java/date_format'
import { GetFullNameValidated } from '../../../helper_java/person_helper'

function guest_recordsTable(props) {
  const actionTemplate = rowData => {
    let items = [
      {
        label: "Eliminar",
        disabled: rowData.preload,
        icon: "pi pi-times",
        command: async e => {
          if (!rowData.preload){
            await props.delete(rowData);
            await props.showErrorsGuestRecord();
          }
        }
      }
    ];
    return (
      ((props.state === 1) && (rowData.last_guest_record_id == null)) ?

      <SplitButton
        label="Editar"
        icon="pi pi-pencil"
        className="p-button-info"
        menuStyle={{ width: '100px'}}
        style={{textAlign:'left'}}
        model={
          props.state !== 2 ? items : ""
        }
        onClick={e => {
          props.edit(rowData);
        }}
      />
      :
      (
        rowData.m_type == "day_use" || (props.state_edit_finish_entry == true && rowData.m_type == "ingreso") ?
          <SplitButton
            label="Editar"
            icon="pi pi-pencil"
            className="p-button-info"
            menuStyle={{ width: '100px'}}
            style={{textAlign:'left'}}
            model={items}
            onClick={e => {
              props.edit(rowData);
            }}
          />
          :
          <Button
            label="Editar"
            icon="pi pi-pencil"
            className="p-button-info"
            onClick={e => {
              props.edit(rowData);
            }}
          />
      )

    );
  };

  const renderHeader = (data)=> {
    if (data != undefined){
      return (
        <div>
          Registros
          <div className="p-datatable-globalfilter-container">
            Total registros: {data.length}
          </div>
        </div>
      );
    }
  }

  if (props.guest_records.length === 0) {
    return (

      <div className="p-col-12 p-md-12">
        <div
          aria-live="polite"
          className="p-message p-component p-message-warn"
        >
          {
            props.state_form == 0 ?
            <span className="p-message-text">No tienes un registro agregado, si finaliza el parte no podrá agregar e importar nuevos registros</span>
            :
            <span className="p-message-text">No tienes un registro agregado</span>
          }
        </div>

      </div>
    );
  } else {
    return (
      <div>
        <DataTable
          value={props.guest_records}
          responsive={true}
          header= {renderHeader(props.guest_records)}
          style={{ marginTop: "20px" }}
          paginator={true}
          rows={10}
          rowsPerPageOptions={[5, 10, 20]}
        >
          <Column
            header="Nro."
            body={(rowData, value) => {
              return value.rowIndex+1
            }}
            style={{textAlign:'center', width: '4em', paddingRight: '0', paddingLeft: '0'}}
          />
          <Column header="Fecha de ingreso"
            body={rowData => {
              return GetFormattedDate(rowData.arrival);
            }}
            style={{textAlign:'center', width: '10em', paddingRight: '0', paddingLeft: '0'}}
          />
          <Column header="Fecha de salida"
            body={rowData => {
              return GetFormattedDate(rowData.departure);
            }}
            style={{textAlign:'center', width: '10em', paddingRight: '0', paddingLeft: '0'}}
          />
          <Column field="room"
            header="Número de habitación"
            style={{textAlign:'center', width: '14em'}}
          />
          <Column
            body={rowData => {
              return GetFullNameValidated(rowData.person_attributes.name, rowData.person_attributes.last_name, rowData.person_attributes.middle_name);
            }}
            header="Nombre completo"
          />
          <Column
            body={rowData => {
              return rowData.person_attributes.document;
            }}
            header="Número de documento"
            style={{textAlign:'center', width: '14em'}}
          />
          { props.state !=2 ?
              <Column body={actionTemplate}
                header="Acciones"
                style={{textAlign:'center', width: '10em'}}
              />:false
          }
        </DataTable>
      </div>
    );
  }
}

export default guest_recordsTable;
