import { UnPleasantTypes } from "../types";

const initialState = {
  unpleasants: [],
  redirect: false,
  error: null
};

export default function unpleasantReducer(state = initialState, action) {
  switch (action.type) {
    case UnPleasantTypes.UNPLEASANT_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case UnPleasantTypes.UNPLEASANT_GET_SUCCESS:
      return {
        ...state,
        unpleasants: action.payload.unpleasants,
        redirect: true,
        error: null
      };

    case UnPleasantTypes.UNPLEASANT_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        unpleasants: []
      };

    case UnPleasantTypes.UNPLEASANT_CREATE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case UnPleasantTypes.UNPLEASANT_CREATE_SUCCESS:
      return {
        ...state,
        redirect: true,
        unpleasants: state.unpleasants.concat(action.payload.unpleasant)
      };

    case UnPleasantTypes.UNPLEASANT_CREATE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case UnPleasantTypes.UNPLEASANT_DELETE_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case UnPleasantTypes.UNPLEASANT_DELETE_SUCCESS:
      return {
        ...state,
        redirect: true,
        unpleasants: state.unpleasants.filter(
          unpleasant => unpleasant.id !== action.payload.UNPLEASANT_id
        )
      };

    case UnPleasantTypes.UNPLEASANT_DELETE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    case UnPleasantTypes.UNPLEASANT_EDIT_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case UnPleasantTypes.UNPLEASANT_EDIT_SUCCESS:
      let updated_unpleasant = action.payload.unpleasant;
      return {
        ...state,
        redirect: true,
        unpleasants: state.unpleasants.map(unpleasant => {
          if (unpleasant.id === updated_unpleasant.id) {
            return updated_unpleasant;
          } else {
            return unpleasant;
          }
        })
      };

    case UnPleasantTypes.UNPLEASANT_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false
      };

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
