import React, { Component, useEffect } from "react";
import * as AuthActions from "../../redux/actions/AuthActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
// import { connect } from "react-redux";
import Logo from "../../config/sidebar/logo.svg";


import { ProgressSpinner } from "primereact/progressspinner";

import SimpleReactValidator from "simple-react-validator";
import { FormMessages } from "../../config/formTranslations";

class PasswordExpired extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: "p-message p-component p-message-error",
      messages: FormMessages
    });

    this.state = {
      current_password: "",
      password: "",
      password_confirmation: "",
      email: this.props.user.email
    }
  }

  showError = () => {
    if (this.props.auth_error) {
      let error = this.props.auth_error.data
        ? this.props.auth_error.data.message
        : this.props.auth_error;
      this.messages.show({
        severity: "error",
        summary: "",
        detail: error,
        closable: false,
      });
    }else{
      document.location.href = '/'
    }
  };

  handleChange = (field_name,event) => {
    const { name, value } = event.target;
    this.setState({
      [field_name]: value
    });
  };

  handleSubmit = async() => {
    this.messages.clear();
    if ( this.validator.allValid() ) {
      await this.props.AuthActions.changePassword(this.props.token, this.state.email, this.state.current_password, this.state.password, this.state.password_confirmation);
      this.showError();
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  handleKeyPress = async(event) => {
    if (event.key === "Enter" && this.validator.allValid()) {
      await this.props.AuthActions.changePassword(this.props.token, this.state.email, this.state.current_password, this.state.password, this.state.password_confirmation);
      this.showError();
    }else{
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  render() {
    /*if (this.props.redirect === true) {
      return <Redirect to="/" />;
    }*/
    return (
      <div>
        {this.props.loading === true ? (
          <div className="p-grid p-fluid p-justify-center">
            <ProgressSpinner
              style={{ width: "20%", height: "20%", marginTop: "10%" }}
              animationDuration=".5s"
            />
          </div>
        ) : (
          <div className="p-grid p-fluid p-justify-center">

            <div>
              <div className="backgorund-login">
                <div className="layout-logo">
                  <img
                    alt="Logo"
                    src={Logo}
                    style={{ width: "100%", height: "30vh" }}
                  />
                </div>

                <div className="p-inputgroup inputs-login">
                  <span className="p-inputgroup-addon icon-login">
                    <i className="pi pi-lock" />
                  </span>
                  <Password
                    onKeyPress={event => this.handleKeyPress(event)}
                    feedback={false}
                    onChange={event => this.handleChange('current_password',event)}
                    value={this.state.current_password}
                    maxLength="50"
                    placeholder="Contraseña actual"
                    name="current_password"
                  />
                </div>
                {this.validator.message("contraseña actual", this.state.current_password, "required")}

                <div className="p-inputgroup inputs-login">
                  <span className="p-inputgroup-addon icon-login">
                    <i className="pi pi-lock" />
                  </span>
                  <Password
                    onKeyPress={event => this.handleKeyPress(event)}
                    feedback={false}
                    onChange={event => this.handleChange('password',event)}
                    value={this.state.password}
                    maxLength="50"
                    placeholder="Nueva contraseña"
                    name="password"
                  />
                </div>
                {this.validator.message("contraseña", this.state.password, "required")}

                <div className="p-inputgroup inputs-login">
                  <span className="p-inputgroup-addon icon-login">
                    <i className="pi pi-lock" />
                  </span>
                  <Password
                    onKeyPress={event => this.handleKeyPress(event)}
                    feedback={false}
                    onChange={event => this.handleChange('password_confirmation',event)}
                    value={this.state.password_confirmation}
                    maxLength="50"
                    placeholder="Confirmar contraseña"
                    name="password_confirmation"
                  />
                </div>
                {this.validator.message("confirmar contraseña", this.state.password_confirmation, "required")}

                <Messages ref={(el) => this.messages = el} />
                <div className="">
                  <Button
                    className="button-login "
                    onClick={() => this.handleSubmit()}
                    label="Ingresar"
                  />
                </div>
              </div>
            </div>

          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.AuthReducer.user,
  token: state.AuthReducer.token,
  loading: state.LoadingReducer.loading,
  redirect: state.AuthReducer.redirect,

  error: state.ErrorReducer.error,
  auth_error: state.AuthReducer.error
});

const mapDispatchToProps = dispatch => ({
  AuthActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpired);
