export * from './AuthEndpoints';
export * from './DailyReportEndpoints';
export * from './CategoryEndpoints';
export * from './EstablishmentEndpoints';
export * from './CountryEndpoints';
export * from './LogEndpoints';
export * from './NationalityEndpoints';
export * from './DashboardEndpoints';
export * from './RoomEndpoints';
export * from './EmployeEndpoints';
export * from './SiatEndpoints';
export * from './Siat2EndPoints';
export * from './Siat3EndPoints';
export * from './Siat4EndPoints';
export * from './Siat5EndPoints';
export * from './UnPleasantEndPoints';
export * from './EstablishmentEmailEndPoints';
export * from './EstablishmentContactEndPoints';
export * from './PersonEndpoints';
export * from './CommunicationEndPoints';

const MainURL = process.env.REACT_APP_URL;
// const MainURL = 'http://localhost:3000';
export { MainURL };
