import { SIAT3Types } from "../types";
import { Siat3Endpoints, MainURL } from "../endpoints";
import axios from "axios";

const siat3GetBegin = () => ({
  type: SIAT3Types.SIAT3_GET_REQUEST
});

const siat3GetSuccess = siat3s => ({
  type: SIAT3Types.SIAT3_GET_SUCCESS,
  payload: { siat3s }
});

const siat3GetFailure = error => ({
  type: SIAT3Types.SIAT3_GET_FAILURE,
  payload: { error }
});

export const getsiats = token => {
  return dispatch => {
    dispatch(siat3GetBegin());
    return axios
      .get(MainURL + Siat3Endpoints.SIAT3_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat3GetSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat3GetFailure(error.response));
      });
  };
};

const siat3GetByIdBegin = () => ({
  type: SIAT3Types.SIAT3_GETBYID_REQUEST
});

const siat3GetByIdSuccess = siat => ({
  type: SIAT3Types.SIAT3_GETBYID_SUCCESS,
  payload: { siat }
});

const siat3GetByIdFailure = error => ({
  type: SIAT3Types.SIAT3_GETBYID_FAILURE,
  payload: { error }
});

export const getsiatById = (token, siat_id) => {
  return dispatch => {
    dispatch(siat3GetByIdBegin());
    return axios
      .get(`${MainURL}${Siat3Endpoints.SIAT3_ENDPOINT}/${siat_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat3GetByIdSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat3GetByIdFailure(error.response));
      });
  };
};

const siat3CreateBegin = () => ({
  type: SIAT3Types.SIAT3_CREATE_REQUEST
});

const siat3CreateSuccess = siat3 => ({
  type: SIAT3Types.SIAT3_CREATE_SUCCESS,
  payload: { siat3, success: "SIAT III creado exitosamente" }
});

const siat3CreateFailure = error => ({
  type: SIAT3Types.SIAT3_CREATE_FAILURE,
  payload: { error }
});

export const createsiat3 = (token, data) => {
  return dispatch => {
    dispatch(siat3CreateBegin());
    return axios
      .post(MainURL + Siat3Endpoints.SIAT3_ENDPOINT, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat3CreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat3CreateFailure(error.response));
      });
  };
};

const siat3DeleteBegin = () => ({
  type: SIAT3Types.SIAT3_DELETE_REQUEST
});

const siat3DeleteSuccess = SIAT3_id => ({
  type: SIAT3Types.SIAT3_DELETE_SUCCESS,
  payload: { SIAT3_id, success: "SIAT III eliminado exitosamente" }
});

const siat3DeleteFailure = error => ({
  type: SIAT3Types.SIAT3_DELETE_FAILURE,
  payload: { error }
});

export const deletesiat3 = (token, SIAT3_id) => {
  return dispatch => {
    dispatch(siat3DeleteBegin());
    return axios
      .delete(MainURL + Siat3Endpoints.SIAT3_ENDPOINT + `/${SIAT3_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat3DeleteSuccess(SIAT3_id));
      })
      .catch(error => {
        dispatch(siat3DeleteFailure(error.response));
      });
  };
};

const siat3EditBegin = () => ({
  type: SIAT3Types.SIAT3_EDIT_REQUEST
});

const siat3EditSuccess = siat3 => ({
  type: SIAT3Types.SIAT3_EDIT_SUCCESS,
  payload: { siat3, success: "SIAT III actualizado exitosamente" }
});

const siat3EditFailure = error => ({
  type: SIAT3Types.SIAT3_EDIT_FAILURE,
  payload: { error }
});

export const editsiat3 = (token, data) => {
  return dispatch => {
    dispatch(siat3EditBegin());
    return axios
      .put(MainURL + Siat3Endpoints.SIAT3_ENDPOINT + `/${data.id}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat3EditSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat3EditFailure(error.response));
      });
  };
};

export const setInitialState = () => {
  return dispatch => {
    dispatch({
      type: SIAT3Types.SIAT3_SETINITIAL_SUCCESS,
      payload: null
    });
  };
};

export const createRecord = guest_record => {
  return dispatch => {
    dispatch({
      type: SIAT3Types.SIAT3_LOCALCREATE_SUCCESS,
      payload: { guest_record }
    });
  };
};

const siat3editRecortBegin = () => ({
  type: SIAT3Types.SIAT3_EDIT_RECORD_REQUEST
});

const siat3editRecortSuccess = siat => ({
  type: SIAT3Types.SIAT3_EDIT_RECORD_SUCCESS,
  payload: { siat }
});

const siat3editRecortFailure = error => ({
  type: SIAT3Types.SIAT3_EDIT_RECORD_FAILURE,
  payload: { error }
});

export const editRecord = (token, data) => {
  return dispatch => {
    dispatch(siat3editRecortSuccess());
    return axios
    .put(MainURL + Siat3Endpoints.SIAT3RECORD_ENDPOINT + `/${data.id}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(siat3editRecortSuccess(response.data));
      })
      .catch(error => {
        dispatch(siat3editRecortFailure(error.response));
      });
  };
};

export const deleteRecord = record => {
  return dispatch => {
    dispatch({
      type: SIAT3Types.SIAT3_LOCALDELETE_SUCCESS,
      payload: { record }
    });
  };
};
