import { RoomTypes } from "../types";
import { RoomEndpoints, MainURL } from "../endpoints";
import axios from "axios";

const roomGetBegin = () => ({
  type: RoomTypes.ROOM_GET_REQUEST
});

const roomGetSuccess = rooms => ({
  type: RoomTypes.ROOM_GET_SUCCESS,
  payload: { rooms }
});

const roomGetFailure = error => ({
  type: RoomTypes.ROOM_GET_FAILURE,
  payload: { error }
});

export const getRooms = token => {
  return dispatch => {
    dispatch(roomGetBegin());
    return axios
      .get(MainURL + RoomEndpoints.ROOM_ENDPOINT, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(roomGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(roomGetFailure(error.response));
      });
  };
};

const roomCreateBegin = () => ({
  type: RoomTypes.ROOM_CREATE_REQUEST
});

const roomCreateSuccess = room => ({
  type: RoomTypes.ROOM_CREATE_SUCCESS,
  payload: { room, success: "Habitación creada exitosamente" }
});

const roomCreateFailure = error => ({
  type: RoomTypes.ROOM_CREATE_FAILURE,
  payload: { error }
});

export const createRoom = (token, data) => {
  return dispatch => {
    dispatch(roomCreateBegin());
    return axios
      .post(MainURL + RoomEndpoints.ROOM_ENDPOINT, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(roomCreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(roomCreateFailure(error.response));
      });
  };
};

const roomDeleteBegin = () => ({
  type: RoomTypes.ROOM_DELETE_REQUEST
});

const roomDeleteSuccess = room_id => ({
  type: RoomTypes.ROOM_DELETE_SUCCESS,
  payload: { room_id, success: "Habitación eliminada exitosamente" }
});

const roomDeleteFailure = error => ({
  type: RoomTypes.ROOM_DELETE_FAILURE,
  payload: { error }
});

export const deleteRoom = (token, room_id) => {
  return dispatch => {
    dispatch(roomDeleteBegin());
    return axios
      .delete(MainURL + RoomEndpoints.ROOM_ENDPOINT + `/${room_id}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(roomDeleteSuccess(room_id));
      })
      .catch(error => {
        dispatch(roomDeleteFailure(error.response));
      });
  };
};

const roomEditBegin = () => ({
  type: RoomTypes.ROOM_EDIT_REQUEST
});

const roomEditSuccess = room => ({
  type: RoomTypes.ROOM_EDIT_SUCCESS,
  payload: { room, success: "Habitación actualizada exitosamente" }
});

const roomEditFailure = error => ({
  type: RoomTypes.ROOM_EDIT_FAILURE,
  payload: { error }
});

export const editRoom = (token, data) => {
  return dispatch => {
    dispatch(roomEditBegin());
    return axios
      .put(MainURL + RoomEndpoints.ROOM_ENDPOINT + `/${data.id}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(roomEditSuccess(response.data));
      })
      .catch(error => {
        dispatch(roomEditFailure(error.response));
      });
  };
};
