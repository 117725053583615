export const SIAT5Types = {
  SIAT5_GET_REQUEST: "SIAT5_GET_REQUEST",
  SIAT5_GET_SUCCESS: "SIAT5_GET_SUCCESS",
  SIAT5_GET_FAILURE: "SIAT5_GET_FAILURE",

  SIAT5_GETBYID_REQUEST: "SIAT5_GETBYID_REQUEST",
  SIAT5_GETBYID_SUCCESS: "SIAT5_GETBYID_SUCCESS",
  SIAT5_GETBYID_FAILURE: "SIAT5_GETBYID_FAILURE",

  SIAT5_MODAL_SHOW_REQUEST: "SIAT5_MODAL_SHOW_REQUEST",
  SIAT5_MODAL_SHOW_SUCCESS: "SIAT5_MODAL_SHOW_SUCCESS",
  SIAT5_MODAL_SHOW_FAILURE: "SIAT5_MODAL_SHOW_FAILURE",

  SIAT5_SETINITIAL_SUCCESS: "SIAT5_SETINITIAL_SUCCESS",

  UPDATE_STATE_SIAT_REQUEST: "UPDATE_STATE_SIAT_REQUEST",
  UPDATE_STATE_SIAT_SUCCESS: "UPDATE_STATE_SIAT_SUCCESS",
  UPDATE_STATE_SIAT_FAILURE: "UPDATE_STATE_SIAT_FAILURE"
};
