import React, { Component, useEffect } from "react";
import * as AuthActions from "../../redux/actions/AuthActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect} from "react-router-dom";
import * as url from "../../redux/endpoints/index";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
// import { connect } from "react-redux";
import Logo from "../../config/sidebar/logo.svg";
import axios from "axios";

import { ProgressSpinner } from "primereact/progressspinner";

import SimpleReactValidator from "simple-react-validator";
import { FormMessages } from "../../config/formTranslations";

class UnlockAccount extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: "p-message p-component p-message-error",
      messages: FormMessages
    });

    this.state = {
      username: "",
      password: ""
    }
  }
  
  componentDidMount(){
    if(this.props.location.search==="?unlock=true"){
      alert("Cuenta desbloqueada exitosamente.");
      window.location='/';
    }
    else{
      if(this.props.location.search==="?unlock=false"){
        alert("Token de desbloqueo no válido.");
        window.location='/UnlockAccount';
      }
    }
  }

  showError = () => {
    if (this.props.auth_error) {
      let error = this.props.auth_error.data
        ? this.props.auth_error.data.message
        : this.props.auth_error;
      this.messages.show({
        severity: "error",
        summary: "",
        detail: error,
        closable: false,
      });
    }else{
      document.location.href = '/'
    }
  };

  handleChange = (field_name,event) => {
    const { name, value } = event.target;
    this.setState({
      [field_name]: value
    });
  };

  handleSubmit = async() => {
    axios
    .post(url.MainURL + "/users/unlock", {
      headers: {
        Accept: "application/json",
      },
      data: {
        email: this.state.username
      }
    })
    .then(response => {
      if(response.request.response!=="" &&!JSON.parse(response.request.response).success){
        this.messages.show({
          severity: "error",
          summary: "",
          detail: JSON.parse(response.request.response).message,
          closable: false,
        });
      }
      else{
        alert("Las instrucciones para desbloquear su cuenta se enviarán en breve.");
        window.location="/";
      }
    })
    .catch(error => {
    });
  };

  handleKeyPress = async(event) => {
    if (event.key === "Enter") {
      this.handleSubmit();  
    }
  };

  render() {
    return (
      <div>
        {this.props.loading === true ? (
          <div className="p-grid p-fluid p-justify-center">
            <ProgressSpinner
              style={{ width: "20%", height: "20%", marginTop: "10%" }}
              animationDuration=".5s"
            />
          </div>
        ) : (
          <div className="p-grid p-fluid p-justify-center">

            <div>
              <div className="backgorund-login">
                <div className="layout-logo">
                  <img
                    alt="Logo"
                    src={Logo}
                    style={{ width: "100%", height: "30vh" }}
                  />
                </div>
                <div className="p-inputgroup inputs-login-top">
                  <span className="p-inputgroup-addon icon-login">
                    <i className="pi pi-user" />
                  </span>
                  <InputText
                    onKeyPress={event => this.handleKeyPress(event)}
                    onChange={event => this.handleChange('username',event)}
                    value={this.state.username}
                    maxLength="50"
                    placeholder="Ingrese su Email"
                    name="email"
                  />
                </div>
                {this.validator.message("Email", this.state.username, "required")}
                <Messages ref={(el) => this.messages = el} />
                <div className="">
                  <Button
                    className="button-login "
                    onClick={() => this.handleSubmit()}
                    label="Reenviar instrucciones de desbloqueo"
                  />
                </div>
                <div style={{marginTop:'20px', fontSize:'15px'}}>
                  <a href="/" >Iniciar sesión</a>      
                </div>
                <div style={{marginTop:'20px', fontSize:'15px'}}>
                  <a href="/ForgotPassword" >¿Olvidó su contraseña?</a>       
                </div>
              </div>
            </div>



          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(UnlockAccount);