import React, { Component } from 'react';

export class AppFooter extends Component {
  render() {
    return (
      <div className='layout-footer'>
        <div className='container layaut-text'>
          <span className='footer-text' style={{ marginLeft: '5px' }}>
            Cámara Hotelera de Santa Cruz
          </span>
        </div>
        {/* <a
          style={{ float: 'right' }}
          href='https://gitlab.com/camara-hotelera/camara-hotelera-ui/commits/develop'>
          <img
            alt='pipeline status'
            src='https://gitlab.com/camara-hotelera/camara-hotelera-ui/badges/develop/pipeline.svg'
          />
        </a> */}
      </div>
    );
  }
}
