export const SIAT3Types = {
  SIAT3_GET_REQUEST: "SIAT3_GET_REQUEST",
  SIAT3_GET_SUCCESS: "SIAT3_GET_SUCCESS",
  SIAT3_GET_FAILURE: "SIAT3_GET_FAILURE",

  SIAT3_GETBYID_REQUEST: "SIAT3_GETBYID_REQUEST",
  SIAT3_GETBYID_SUCCESS: "SIAT3_GETBYID_SUCCESS",
  SIAT3_GETBYID_FAILURE: "SIAT3_GETBYID_FAILURE",

  SIAT3_CREATE_REQUEST: "SIAT3_CREATE_REQUEST",
  SIAT3_CREATE_SUCCESS: "SIAT3_CREATE_SUCCESS",
  SIAT3_CREATE_FAILURE: "SIAT3_CREATE_FAILURE",

  SIAT3_DELETE_REQUEST: "SIAT3_DELETE_REQUEST",
  SIAT3_DELETE_SUCCESS: "SIAT3_DELETE_SUCCESS",
  SIAT3_DELETE_FAILURE: "SIAT3_DELETE_FAILURE",

  SIAT3_EDIT_REQUEST: "SIAT3_EDIT_REQUEST",
  SIAT3_EDIT_SUCCESS: "SIAT3_EDIT_SUCCESS",
  SIAT3_EDIT_FAILURE: "SIAT3_EDIT_FAILURE",

  SIAT3_SETINITIAL_SUCCESS: "SIAT3_SETINITIAL_SUCCESS",

  SIAT3_LOCALCREATE_SUCCESS: "SIAT3_LOCALCREATE_SUCCESS",

  SIAT3_LOCALDELETE_SUCCESS: "SIAT3_LOCALDELETE_SUCCESS",

  SIAT3_LOCALEDIT_SUCCESS: "SIAT3_LOCALEDIT_SUCCESS",

  SIAT3_EDIT_RECORD_REQUEST: "SIAT3_EDIT_RECORD_REQUEST",
  SIAT3_EDIT_RECORD_SUCCESS: "SIAT3_EDIT_RECORD_SUCCESS",
  SIAT3_EDIT_RECORD_FAILURE: "SIAT3_EDIT_RECORD_FAILURE",
};
