import React, { Component, useEffect } from "react";
import * as AuthActions from "../../redux/actions/AuthActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect} from "react-router-dom";
import * as url from "../../redux/endpoints/index";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
// import { connect } from "react-redux";
import Logo from "../../config/sidebar/logo.svg";
import axios from "axios";


import { ProgressSpinner } from "primereact/progressspinner";

import SimpleReactValidator from "simple-react-validator";
import { FormMessages } from "../../config/formTranslations";

class RecoveryPassword extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: "p-message p-component p-message-error",
      messages: FormMessages
    });

    this.state = {
      confirm: "",
      password: ""
    }
  }

  showError = () => {
    if (this.props.auth_error) {
      let error = this.props.auth_error.data
        ? this.props.auth_error.data.message
        : this.props.auth_error;
      this.messages.show({
        severity: "error",
        summary: "",
        detail: error,
        closable: false,
      });
    }else{
      document.location.href = '/'
    }
  };

  handleChange = (field_name,event) => {
    const { name, value } = event.target;
    this.setState({
      [field_name]: value
    });
  };

  handleSubmit = async() => {
    axios
    .post(url.MainURL + "/users/changepassword", {
        headers: {
        Accept: "application/json",
        },
        data: {
        password: this.state.password,
        confirm: this.state.confirm,
        token: this.props.location.search.substring(7)
        }
    })
    .then(response => {
      if(response.request.response!=="" &&!JSON.parse(response.request.response).success){
        this.messages.show({
          severity: "error",
          summary: "",
          detail: JSON.parse(response.request.response).message,
          closable: false,
        });
      }
      else{
        alert("Contraseña modificada exitosamente.");
        window.location="/";
      }
    })
    .catch(error => {
    });
  };

  handleKeyPress = async(event) => {
    if (event.key === "Enter") {
      this.handleSubmit();  
    }
  };

  render() {
    if(this.props.location.search!="")
    {
        return (
        <div>
            {this.props.loading === true ? (
            <div className="p-grid p-fluid p-justify-center">
                <ProgressSpinner
                style={{ width: "20%", height: "20%", marginTop: "10%" }}
                animationDuration=".5s"
                />
            </div>
            ) : (
            <div className="p-grid p-fluid p-justify-center">

                <div>
                <div className="backgorund-login">
                    <div className="layout-logo">
                    <img
                        alt="Logo"
                        src={Logo}
                        style={{ width: "100%", height: "30vh" }}
                    />
                    </div>
                    <div className="p-inputgroup inputs-login-top">
                    <span className="p-inputgroup-addon icon-login">
                        <i className="pi pi-lock" />
                    </span>
                    <Password
                    onKeyPress={event => this.handleKeyPress(event)}
                    feedback={false}
                    onChange={event => this.handleChange('password',event)}
                    value={this.state.password}
                    maxLength="50"
                    placeholder="Nueva contraseña"
                    name="contraseña"
                    />
                    </div>
                    <div className="p-inputgroup inputs-login-top">
                    <span className="p-inputgroup-addon icon-login">
                        <i className="pi pi-lock" />
                    </span>
                    <Password
                    onKeyPress={event => this.handleKeyPress(event)}
                    feedback={false}
                    onChange={event => this.handleChange('confirm',event)}
                    value={this.state.confirm}
                    maxLength="50"
                    placeholder="Confirmar contraseña"
                    name="confirmar"
                    />
                    </div>
                    {this.validator.message("Email", this.state.username, "required")}
                    <Messages ref={(el) => this.messages = el} />
                    <div className="" >
                    <Button
                        className="button-login "
                        onClick={() => this.handleSubmit()}
                        label="Recuperar contraseña"
                    />
                    </div>
                </div>
                </div>



            </div>
            )}
        </div>
        );
    }
    else
    {
        window.location="/";
    }
  }
}

const mapStateToProps = state => ({

});




const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPassword);