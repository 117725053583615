import { CountryTypes } from '../types';
import { CountryEndpoints, MainURL } from '../endpoints';
import axios from 'axios';

const countryGetBegin = () => ({
  type: CountryTypes.COUNTRY_GET_REQUEST
});

const countryGetSuccess = countries => ({
  type: CountryTypes.COUNTRY_GET_SUCCESS,
  payload: { countries }
});

const countryGetFailure = error => ({
  type: CountryTypes.COUNTRY_GET_FAILURE,
  payload: { error }
});

export const getCountries = token => {
  return dispatch => {
    dispatch(countryGetBegin());
    return axios
      .get(MainURL + CountryEndpoints.COUNTRY_ENDPOINT, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(countryGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(countryGetFailure(error.response));
      });
  };
};

const countryCreateBegin = () => ({
  type: CountryTypes.COUNTRY_CREATE_REQUEST
});

const countryCreateSuccess = countries => ({
  type: CountryTypes.COUNTRY_CREATE_SUCCESS,
  payload: { countries }
});

const countryCreateFailure = error => ({
  type: CountryTypes.COUNTRY_CREATE_FAILURE,
  payload: { error }
});

export const createCountry = (token, country) => {
  return dispatch => {
    dispatch(countryCreateBegin());
    return axios
      .post(MainURL + CountryEndpoints.COUNTRY_ENDPOINT, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
        data: {
          country: country
        }
      })
      .then(response => {
        dispatch(countryCreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(countryCreateFailure(error.response));
      });
  };
};

const countryDeleteBegin = () => ({
  type: CountryTypes.COUNTRY_DELETE_REQUEST
});

const countryDeleteSuccess = country => ({
  type: CountryTypes.COUNTRY_DELETE_SUCCESS,
  payload: { country }
});

const countryDeleteFailure = error => ({
  type: CountryTypes.COUNTRY_DELETE_FAILURE,
  payload: { error }
});

export const deleteCountry = (token, country_id) => {
  return dispatch => {
    dispatch(countryDeleteBegin());
    return axios
      .delete(MainURL + CountryEndpoints.COUNTRY_ENDPOINT + `/${country_id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(countryDeleteSuccess(response.data));
      })
      .catch(error => {
        dispatch(countryDeleteFailure(error.response));
      });
  };
};

const countryEditBegin = () => ({
  type: CountryTypes.COUNTRY_EDIT_REQUEST
});

const countryEditSuccess = country => ({
  type: CountryTypes.COUNTRY_EDIT_SUCCESS,
  payload: { country }
});

const countryEditFailure = error => ({
  type: CountryTypes.COUNTRY_EDIT_FAILURE,
  payload: { error }
});

export const editCountry = (token, country) => {
  return dispatch => {
    dispatch(countryEditBegin());
    return axios
      .put(MainURL + CountryEndpoints.COUNTRY_ENDPOINT + `/${country.id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        },
        data: {
          country: country
        }
      })
      .then(response => {
        dispatch(countryEditSuccess(response.data));
      })
      .catch(error => {
        dispatch(countryEditFailure(error.response));
      });
  };
};
