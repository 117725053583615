export const EstablishmentEmailTypes = {
  ESTABLISHMENTEMAIL_GET_REQUEST: "ESTABLISHMENTEMAIL_GET_REQUEST",
  ESTABLISHMENTEMAIL_GET_SUCCESS: "ESTABLISHMENTEMAIL_GET_SUCCESS",
  ESTABLISHMENTEMAIL_GET_FAILURE: "ESTABLISHMENTEMAIL_GET_FAILURE",

  ESTABLISHMENTEMAIL_CREATE_REQUEST: "ESTABLISHMENTEMAIL_CREATE_REQUEST",
  ESTABLISHMENTEMAIL_CREATE_SUCCESS: "ESTABLISHMENTEMAIL_CREATE_SUCCESS",
  ESTABLISHMENTEMAIL_CREATE_FAILURE: "ESTABLISHMENTEMAIL_CREATE_FAILURE",

  ESTABLISHMENTEMAIL_DELETE_REQUEST: "ESTABLISHMENTEMAIL_DELETE_REQUEST",
  ESTABLISHMENTEMAIL_DELETE_SUCCESS: "ESTABLISHMENTEMAIL_DELETE_SUCCESS",
  ESTABLISHMENTEMAIL_DELETE_FAILURE: "ESTABLISHMENTEMAIL_DELETE_FAILURE",

  ESTABLISHMENTEMAIL_EDIT_REQUEST: "ESTABLISHMENTEMAIL_EDIT_REQUEST",
  ESTABLISHMENTEMAIL_EDIT_SUCCESS: "ESTABLISHMENTEMAIL_EDIT_SUCCESS",
  ESTABLISHMENTEMAIL_EDIT_FAILURE: "ESTABLISHMENTEMAIL_EDIT_FAILURE"
};
