export const CountryTypes = {
  COUNTRY_GET_REQUEST: 'COUNTRY_GET_REQUEST',
  COUNTRY_GET_SUCCESS: 'COUNTRY_GET_SUCCESS',
  COUNTRY_GET_FAILURE: 'COUNTRY_GET_FAILURE',

  COUNTRY_CREATE_REQUEST: 'COUNTRY_CREATE_REQUEST',
  COUNTRY_CREATE_SUCCESS: 'COUNTRY_CREATE_SUCCESS',
  COUNTRY_CREATE_FAILURE: 'COUNTRY_CREATE_FAILURE',

  COUNTRY_DELETE_REQUEST: 'COUNTRY_DELETE_REQUEST',
  COUNTRY_DELETE_SUCCESS: 'COUNTRY_DELETE_SUCCESS',
  COUNTRY_DELETE_FAILURE: 'COUNTRY_DELETE_FAILURE',

  COUNTRY_EDIT_REQUEST: 'COUNTRY_EDIT_REQUEST',
  COUNTRY_EDIT_SUCCESS: 'COUNTRY_EDIT_SUCCESS',
  COUNTRY_EDIT_FAILURE: 'COUNTRY_EDIT_FAILURE'
};
