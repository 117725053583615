import { GuestRecordTypes } from '../types';

const initialState = {
  id: null,
  date: '',
  state: 1,
  guest_records: [],
  delete_list: [],
  old_list: [],
  redirect: false,
  error: null
};

export default function guestRecordReducer(state = initialState, action) {
  switch (action.type) {
    case GuestRecordTypes.GUEST_EDIT_REQUEST:
      return {
        // ...state,
        // error: null,
        // redirect: false
        ...state,
        id: null,
        date: '',
        state: 1,
        guest_records: [],
        delete_list: [],
        old_list: [],
        redirect: false,
        error: null
      };

    case GuestRecordTypes.GUEST_EDIT_SUCCESS:
      let { date, id } = action.payload.guest_records;
      let status_string = action.payload.guest_records.state;
      let int_status = null;
      if (status_string === 'finalizado') {
        int_status = 0;
      } else if (status_string === 'no_finalizado') {
        int_status = 1;
      } else if (status_string === 'finalizado_en_espera_aprobacion') {
        int_status = 2;
      }
      let guest_records_array = action.payload.guest_records.guest_records;
      for (var i = 0; i < guest_records_array.length; i++) {
        guest_records_array[i].person_attributes =
          guest_records_array[i].person;
        guest_records_array[i].origin_id = guest_records_array[i].origin;
        guest_records_array[i].destiny_id = guest_records_array[i].destiny;
        guest_records_array[i].nationality_id =
          guest_records_array[i].nationality;
        guest_records_array[i].country_id = guest_records_array[i].country;
        guest_records_array[i].local_id = guest_records_array[i].id;
      }
      return {
        ...state,
        date,
        id,
        state: int_status,
        guest_records: guest_records_array,
        old_list: guest_records_array,
        redirect: true,
        error: null,
        delete_list: []
      };

    case GuestRecordTypes.GUEST_EDIT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        guest_records: []
      };

    case GuestRecordTypes.GUEST_SAVE_SUCCESS:
      return {
        ...state,
        guest_records: state.guest_records.concat(action.payload.guest_record)
        // guest_records: [].concat(action.payload.guest_record, state.guest_records)
      };

    case GuestRecordTypes.GUEST_DELETE_SUCCESS:
      let new_guest_records = state.guest_records.filter(guest_record => guest_record.local_id !== action.payload.guest_record.local_id);
      let new_delete_list = [];
      if(action.payload.guest_record.id !== undefined){
        new_delete_list = state.delete_list.concat([action.payload.guest_record.id]);
      } else {
        new_delete_list = state.delete_list;
      }
      if(action.payload.guest_record.id !== undefined){
        if (new_guest_records.length === 0){
          return {
            ...state,
            error: "No se puede eliminar el último registro del PARTE DIARIO, este no puede estar vacío"
          };
        }
      }
      return {
        ...state,
        guest_records: new_guest_records,
        delete_list: new_delete_list,
        error: null
      };

    case GuestRecordTypes.CLEAN_GUEST_SUCCESS:
      return {
        id: null,
        date: '',
        guest_records: [],
        delete_list: [],
        redirect: false,
        state: 1,
        error: null
      };
    case GuestRecordTypes.LOAD_GUEST_SUCCESS:
      return {
        ...state,
        guest_records: state.guest_records.concat(action.payload.guestRecords)
      };
    case GuestRecordTypes.PRELOAD_GET_REQUEST:
      return {
        ...state,
        id: null,
        date: '',
        state: 1,
        guest_records: [],
        delete_list: [],
        old_list: [],
        redirect: false,
        error: null
      };
    case GuestRecordTypes.PRELOAD_GET_SUCCESS:
      const preload = JSON.parse(action.payload.guest_records.preload);
      const next_date = action.payload.guest_records.next_date;
      let preload_guest_records = [];
      for (let guest_record of preload) {
        guest_record.preload = true;
        guest_record.person_attributes = guest_record.person;
        delete guest_record.person;
        guest_record.origin_id = guest_record.origin;
        guest_record.local_id = guest_record.id;
        guest_record.last_guest_record_id = guest_record.id;
        preload_guest_records.push(guest_record);
      }
      return {
        ...state,
        guest_records: preload_guest_records,
        next_date: next_date
      };

    case GuestRecordTypes.GUEST_UPDATE_SUCCESS:
      return {
        ...state,
        guest_records: state.guest_records.map(guest_record => {
          if (guest_record.local_id === action.payload.guest_record.local_id) {
            return { ...guest_record, ...action.payload.guest_record };
          } else {
            return guest_record;
          }
        })
      };
    default:
      return state;
  }
}
