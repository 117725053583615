import React, { Component } from 'react';
import EstablishmentEmailForm from './form';
import SimpleReactValidator from 'simple-react-validator';
import { FormMessages } from '../../config/formTranslations';
import EstablishmentEmailTable from './table';
import { Messages } from 'primereact/messages';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as EstablishmentEmailActions from '../../redux/actions/EstablishmentEmailActions';
import WithMessage from '../messaheHOC';
const TableWithMessage = WithMessage(EstablishmentEmailTable);

class EstablishmentEmail extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      className: 'p-message p-component p-message-error',
      messages: FormMessages,
      validators: {
        zero_invalid: {  // name the rule
          message: 'El número no es válido.',
          rule: (val, params, validator) => {
              return validator.helpers.testRegex(parseInt(val),/^[1-9][0-9]*$/)
          }
        },
        space_invalid: {
          message: 'El nombre no es válido.',
          rule: (val, params, validator) => {
            var re = /^([a-zA-ZÀ-ÿ\u00f1\u00d1])+(\s{0,1}[a-zA-ZÀ-ÿ\u00f1\u00d1])*$/
            return validator.helpers.testRegex(String(val).toLowerCase().trim(),re)
          }
        },
        space_invalid_Change: {
          message: 'El cargo no es válido.',
          rule: (val, params, validator) => {
            var re = /^([a-zA-ZÀ-ÿ\u00f1\u00d1])+(\s{0,1}[a-zA-ZÀ-ÿ\u00f1\u00d1])*$/
            return validator.helpers.testRegex(String(val).toLowerCase().trim(),re)
          }
        },
        email_invalid: {
          message: 'El e-mail no es válido.',
          rule: (val, params, validator) => {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return validator.helpers.testRegex(String(val).toLowerCase(),re)
          }
        }

      }
    });
    this.state = this.setIinitalState();
  }

  setIinitalState = () => {
    return {
      formVisible: false,
      form: {
        id: '',
        email: '',
        name: '',
        position: '',
        state: 1,
        send_email: false,
        cell_phone: '',
      },
    };
  };

  componentDidMount() {
    this.props.EstablishmentEmailActions.getEstablishmentEmails(
      this.props.token
    );
  }

  handleChangeCheck = (event) => {
    const { name, checked } = event.target;
    var change_value;

    if (name == 'state') {
      change_value = 1;
      if (checked == true) {
        change_value = 0;
      }
    } else {
      change_value = false;
      if (checked == true) {
        change_value = true;
      }
    }

    this.setState({
      form: {
        ...this.state.form,
        [name]: change_value,
      },
    });
  };

  handleChangeName = event => {
    const { name, value } = event.target;
    let regex = new RegExp("^[ñíóáéú a-zA-Z ]+$");
    for(let i = 0; i <= value.length -1; i++){
      let letra = value[i]
        if(!regex.test(letra ) || !letra === " "){
          return;
        }
      }
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  closeModal = () => {
    this.validator.hideMessages();
    this.setState({
      formVisible: false,
      form: this.setIinitalState().form,
    });
  };

  handleSubmit = () => {
    if (this.validator.allValid()) {
      let establishmentEmail = this.state.form;
      establishmentEmail.establishment_id = this.props.user.establishment_id;
      if (establishmentEmail.id > 0) {
        this.props.EstablishmentEmailActions.editEstablishmentEmail(
          this.props.token,
          establishmentEmail
        );
      } else {
        this.props.EstablishmentEmailActions.createEstablishmentEmail(
          this.props.token,
          establishmentEmail
        );
      }
      this.setState({
        ...this.state,
        formVisible: false,
        form: this.setIinitalState().form,
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  addNew = () => {
    this.setState({
      formVisible: true,
    });
  };

  deleteEstablishmentEmail = (establishmentEmail_id) => {
    if (window.confirm('¿Está seguro de eliminar este e-mail de contacto?'))
      this.props.EstablishmentEmailActions.deleteEstablishmentEmail(
        this.props.token,
        establishmentEmail_id
      );
  };

  text_to_number = state => {
    if (state == 'activa') {
      this.setState({
        form: {
          ...this.state.form,
          state: 0,
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          state: 1,
        },
      });
    }
  };

  editEstablishmentEmail = async (establishmentEmail) => {
    await this.setState({
      form: establishmentEmail,
      formVisible: true,
    });

    this.text_to_number(this.state.form.state);
  };

  render() {
    return (
      <div>
        <Messages ref={(el) => (this.messages = el)} />
        <EstablishmentEmailForm
          handleSubmit={this.handleSubmit}
          validator={this.validator}
          visible={this.state.formVisible}
          handleChange={this.handleChange}
          handleChangeName={this.handleChangeName}
          handleChangeCheck={this.handleChangeCheck}
          form={this.state.form}
          closeModal={this.closeModal}
        />
        <TableWithMessage
          loading={this.props.loading}
          success={this.props.success}
          error={this.props.error}
          edit={this.editEstablishmentEmail}
          delete={this.deleteEstablishmentEmail}
          addNew={this.addNew}
          establishment_emails={this.props.establishment_emails}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.AuthReducer.token,
  user: state.AuthReducer.user,
  establishment_emails: state.EstablishmentEmailReducer.establishment_emails,
  dailyReports: state.DailyReportReducer.dailyReports,
  loading: state.LoadingReducer.loading,
  error: state.ErrorReducer.error,
  success: state.LoadingReducer.success,
});

const mapDispatchToProps = (dispatch) => ({
  EstablishmentEmailActions: bindActionCreators(
    EstablishmentEmailActions,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentEmail);
