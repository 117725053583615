import { CommunicationTypes } from "../types";

const initialState = {
  communications: [],
  communication: null,
  redirect: false,
  error: null
};

export default function communicationReducer(state = initialState, action) {
  switch (action.type) {
    case CommunicationTypes.COMMUNICATIONS_GET_REQUEST:
      return {
        ...state,
        error: null,
        redirect: false
      };

    case CommunicationTypes.COMMUNICATIONS_GET_SUCCESS:
      return {
        ...state,
        redirect: true,
        error: null,
        communication: null,
        communications: action.payload.communications
      };

    case CommunicationTypes.COMMUNICATIONS_GET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        redirect: false,
        communication: null,
        communications: []
      };
    default:
      return state;
  }
}
