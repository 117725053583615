const initialState = {
  error: ""
};

export default function errorReducer(state = initialState, action) {
  let type = action.type.split("_").pop();
  switch (type) {
    case "REQUEST":
      return {
        error: ""
      };

    case "SUCCESS":
      return {
        error: ""
      };

    case "FAILURE":
      let error = "";
      if (action.payload.error) {
        if (action.payload.error.data) {
          if (action.payload.error.data.error instanceof String) {
            error = action.payload.error.data.error;
          } else if (action.payload.error.data.base instanceof Array) {
            error = action.payload.error.data.base[0];
          }
        }
      }
      return {
        ...state,
        error: error
      };

    default:
      return state;
  }
}
