import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

const Form = props => {
  const { id, person } = props.form;
  const footer = (
    <div>
      <Button
        label={id ? "Actualizar" : "Guardar"}
        icon="pi pi-check"
        onClick={props.handleSubmit}
      />
      <Button
        label="Cancelar"
        className="p-button-danger"
        icon="pi pi-times"
        onClick={props.closeModal}
      />
    </div>
  );
  return (
    <Dialog
      header={id ? "Actualizar contacto" : "Nuevo contacto"}
      visible={props.visible}
      style={{ width: "50vw" }}
      modal={true}
      footer={footer}
      onHide={props.closeModal}
    >
      <form>
        <div className="p-grid p-fluid">
          <div className="p-col-12 p-md-6">
            <Dropdown
              onChange={props.handleChange}
              name="person"
              filter={true}
              options={props.people}
              optionLabel="full_name"
              placeholder="Selecciona una persona"
              value={person}
            />

            {props.validator.message("persona", person, "required")}
          </div>
        </div>
      </form>
    </Dialog>
  );
};

export default Form;
