import { EstablishmentEmailTypes } from '../types';
import { EstablishmentEmailEndpoints, MainURL } from '../endpoints';
import axios from 'axios';

const establishmentEmailGetBegin = () => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_GET_REQUEST
});

const establishmentEmailGetSuccess = establishment_emails => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_GET_SUCCESS,
  payload: { establishment_emails }
});

const establishmentEmailGetFailure = error => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_GET_FAILURE,
  payload: { error }
});

export const getEstablishmentEmails = token => {
  return dispatch => {
    dispatch(establishmentEmailGetBegin());
    return axios
      .get(MainURL + EstablishmentEmailEndpoints.ESTABLISHMENTEMAIL_ENDPOINT, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        dispatch(establishmentEmailGetSuccess(response.data));
      })
      .catch(error => {
        dispatch(establishmentEmailGetFailure(error.response));
      });
  };
};

const establishmentEmailCreateBegin = () => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_CREATE_REQUEST
});

const establishmentEmailCreateSuccess = establishmentEmail => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_CREATE_SUCCESS,
  payload: {
    establishmentEmail,
    success: 'E-mail de contacto creado exitosamente'
  }
});

const establishmentEmailCreateFailure = error => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_CREATE_FAILURE,
  payload: { error }
});

export const createEstablishmentEmail = (token, data) => {
  return dispatch => {
    dispatch(establishmentEmailCreateBegin());
    return axios
      .post(
        MainURL + EstablishmentEmailEndpoints.ESTABLISHMENTEMAIL_ENDPOINT,
        data,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(establishmentEmailCreateSuccess(response.data));
      })
      .catch(error => {
        dispatch(establishmentEmailCreateFailure(error.response));
      });
  };
};

const establishmentEmailDeleteBegin = () => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_DELETE_REQUEST
});

const establishmentEmailDeleteSuccess = id => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_DELETE_SUCCESS,
  payload: { id, success: 'E-mail de contacto eliminado exitosamente' }
});

const establishmentEmailDeleteFailure = error => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_DELETE_FAILURE,
  payload: { error }
});

export const deleteEstablishmentEmail = (token, id) => {
  return dispatch => {
    dispatch(establishmentEmailDeleteBegin());
    return axios
      .delete(
        MainURL +
          EstablishmentEmailEndpoints.ESTABLISHMENTEMAIL_ENDPOINT +
          `/${id}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(establishmentEmailDeleteSuccess(id));
      })
      .catch(error => {
        dispatch(establishmentEmailDeleteFailure(error.response));
      });
  };
};

const establishmentEmailEditBegin = () => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_EDIT_REQUEST
});

const establishmentEmailEditSuccess = establishmentEmail => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_EDIT_SUCCESS,
  payload: {
    establishmentEmail,
    success: 'E-mail de contacto actualizado exitosamente'
  }
});

const establishmentEmailEditFailure = error => ({
  type: EstablishmentEmailTypes.ESTABLISHMENTEMAIL_EDIT_FAILURE,
  payload: { error }
});

export const editEstablishmentEmail = (token, data) => {
  return dispatch => {
    dispatch(establishmentEmailEditBegin());
    return axios
      .put(
        MainURL +
          EstablishmentEmailEndpoints.ESTABLISHMENTEMAIL_ENDPOINT +
          `/${data.id}`,
        data,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then(response => {
        dispatch(establishmentEmailEditSuccess(response.data));
      })
      .catch(error => {
        dispatch(establishmentEmailEditFailure(error.response));
      });
  };
};
