export const SIAT2Types = {
  SIAT2_GET_REQUEST: "SIAT2_GET_REQUEST",
  SIAT2_GET_SUCCESS: "SIAT2_GET_SUCCESS",
  SIAT2_GET_FAILURE: "SIAT2_GET_FAILURE",

  SIAT2_GETBYID_REQUEST: "SIAT2_GETBYID_REQUEST",
  SIAT2_GETBYID_SUCCESS: "SIAT2_GETBYID_SUCCESS",
  SIAT2_GETBYID_FAILURE: "SIAT2_GETBYID_FAILURE",

  SIAT2_CREATE_REQUEST: "SIAT2_CREATE_REQUEST",
  SIAT2_CREATE_SUCCESS: "SIAT2_CREATE_SUCCESS",
  SIAT2_CREATE_FAILURE: "SIAT2_CREATE_FAILURE",

  SIAT2_DELETE_REQUEST: "SIAT2_DELETE_REQUEST",
  SIAT2_DELETE_SUCCESS: "SIAT2_DELETE_SUCCESS",
  SIAT2_DELETE_FAILURE: "SIAT2_DELETE_FAILURE",

  SIAT2_EDIT_REQUEST: "SIAT2_EDIT_REQUEST",
  SIAT2_EDIT_SUCCESS: "SIAT2_EDIT_SUCCESS",
  SIAT2_EDIT_FAILURE: "SIAT2_EDIT_FAILURE",

  SIAT2_SETINITIAL_SUCCESS: "SIAT2_SETINITIAL_SUCCESS",

  SIAT2_LOCALCREATE_SUCCESS: "SIAT2_LOCALCREATE_SUCCESS",

  SIAT2_LOCALDELETE_SUCCESS: "SIAT2_LOCALDELETE_SUCCESS",

  SIAT2_LOCALEDIT_SUCCESS: "SIAT2_LOCALEDIT_SUCCESS",

  SIAT2_EDIT_RECORD_REQUEST: "SIAT2_EDIT_RECORD_REQUEST",
  SIAT2_EDIT_RECORD_SUCCESS: "SIAT2_EDIT_RECORD_SUCCESS",
  SIAT2_EDIT_RECORD_FAILURE: "SIAT2_EDIT_RECORD_FAILURE",
};
