import React from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { es } from "../../config/translations";

const Form = props => {
  const footer = (
    <div>
      {
        props.blocked_button?
          ""
          :
          <Button
            label={"Finalizar SIATS"}
            onClick={props.handleFinish}
            icon="pi pi-check"
          />
      }
      <Button
        className="p-button-danger"
        label="Cancelar"
        icon="pi pi-times"
        onClick={props.closeModal}
      />
    </div>
  );

  return (
    <Dialog
      style={{ width: "40vw" }}
      //maximizable
      footer={footer}
      header={
        "Finalizar SIAT"
      }
      visible={props.visible}
      modal={true}
      onHide={props.closeModal}
    >
      {
        props.blocked_button?
          <div align="center">
            <p>Para finalizar el SIAT todos los huéspedes deben estar con <b>estado completado,</b> es decir deben tener registrado los SIAT II, III y IV y además debe ser <b>fin de mes</b> para contemplar todos los datos de los partes.</p>
          </div>
          :
          <div align="center">
            <p>¿Está seguro de <b>Finalizar sus SIATS</b>?</p>
            <p>Una vez <b>FINALIZADO</b> no se podrá volver a <b>EDITAR REGISTROS</b>.</p>
          </div>
      }
    </Dialog>
  );
};

export default Form;
